import React from "react";
import { ContactListModal, ListWithAvatarAndIcon, CreateGroupBox } from "@components/molecules";
import {AddContactModal, AddContactToGroup } from "@components/atoms";
import { Box } from "@mui/material";
import { useAppSelector } from "@hooks";
export const MyContactOrganism = () => {
    const [open, setOpen] = React.useState(false);
    const [addToGroupModal,setAddToGroupModal] = React.useState(false);
    const [openAddContact,setOpenAddContact] = React.useState(false);
    const [availableContacts, setAvailableContacts] = React.useState([]);

    const { contacts } = useAppSelector(state => state.myContacts);
    React.useEffect(() => {
        const structGroup = contacts.map((contact: any) => {
            return {
                id: contact.id,
                primary: contact.fullname,
                secondary: contact.username,
                image: contact.image,
                invitationCode: contact.invitationCode,
                action: contact.action
            }
        })
        setAvailableContacts(structGroup);
    }, [contacts]);
    
    const handleClickOpen = () => {
        setOpen(true);
      };
    const handleClose = () => {
        setOpen(false);
    }
    const handleGroupModalOpen = () => {
        setAddToGroupModal(true)
    }

    const handleAddContact = () => {
          console.log("handle add contact")
    }
    return (
        <>
         <Box  sx={{
            padding:"21px 0px 16px 0px"
         }}>
            <CreateGroupBox buttonId="myContacts_add_contact_button" btnText="Add contacts" handleClick={() => setOpenAddContact(true)}/>
            </Box>
         <ListWithAvatarAndIcon handleClickOpen={handleClickOpen} data={availableContacts}/>
         <ContactListModal open={open} handleClose={handleClose} onAddToGroup={handleGroupModalOpen}/>
         <AddContactToGroup open={addToGroupModal} onclose={() => setAddToGroupModal(false)}/>
         <AddContactModal open={openAddContact} onclose={() => setOpenAddContact(false)}/>
        </>
    )
}