import React, { FC, } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  Card,
  Dialog,
  ListItemIcon,
  Grid,
} from "@mui/material";
import {
  CrossIcon,
  AccessIcon,
  ConnectedIcon,
  StoredIcon,
  ButtonContained,
  ButtonOutlined,
} from "@components/atoms";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    //  background:"rgba(52, 64, 84, 1)", //70%
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    ["@media (max-width:700px)"]: {
      borderRadius: "10px",
      width: "343px !important",
      height: "417px",
      maxHeight: "417px",
      margin: "0px",
    },
    ["@media (min-width:700px)"]: {
      borderRadius: "10px",
      width: "343px",
      height: "417px"
    },
  },

  scrollbarColor: "#6b6b6b #2b2b2b",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#eaecf0",
    width: 8,
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#eaecf0",
  },
}));

export const ModalMakeitPersonalComponent: FC<any> = ({ open, onclose }) => {
  const handleClose = () => {
    onclose();
  };

  return (
    <div>
      <BootstrapDialog
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
      >
        <>
          <Card sx={{ boxShadow: "none" }}>
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <nav aria-label="main mailbox folders">
                <List sx={{ alignItem: "center", padding: 0 }}>
                  <ListItemIcon
                    sx={{
                      textAlign: "start",
                      margin: "25px 25px 0 25px",
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  >
                    <CrossIcon />
                  </ListItemIcon>
                  <Box
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Inter",
                        fontSize: 24,
                        fontWeight: 500,
                        color: "#23262f",
                      }}
                      color="text.secondary"
                      gutterBottom
                    >
                      23:59 h
                    </Typography>

                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#667085",
                        marginBottom: "0px",
                      }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Make it personal to enjoy free <br /> advantages...
                    </Typography>
                  </Box>
                  <List>
                    <ListItem>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          width: "100%",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "30px",
                            textAlign: "flex-end",
                            marginLeft: "2rem",
                          }}
                        >
                          <StoredIcon />
                        </ListItemIcon>
                        <Typography sx={{ fontSize: "14px" }}>
                          Stored memory forever
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "30px",
                            textAlign: "end",
                            marginLeft: "2rem",
                          }}
                        >
                          <AccessIcon />
                        </ListItemIcon>
                        <Typography sx={{ fontSize: "14px" }}>
                          Access Control
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "30px",
                            textAlign: "flex-end",
                            marginLeft: "2rem",
                          }}
                        >
                          <ConnectedIcon />
                        </ListItemIcon>
                        <Typography sx={{ fontSize: "14px" }}>
                          Connected with your friends
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  </List>

                  <Grid item md={12} xs={12}>
                    <Box
                      sx={{
                        width: "100%",
                        margin: "0.7rem 0 2rem 0",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonOutlined
                       id="modalMakeitPersonal_login"
                        // clickHandler={signupRedirect}
                        width="83px"
                        height="44px"
                        border="1px solid #eaecf0"
                      >
                        Log in
                      </ButtonOutlined> &nbsp;&nbsp;
                      <ButtonContained
                       id="modalMakeitPersonal_signup"
                        width="110px"
                        height="44px"
                        // clickHandler={signupRedirect}
                        sizeVariant="large"
                      >
                        Sign up
                      </ButtonContained>
                    </Box>
                  </Grid>
                </List>
              </nav>
            </Box>
          </Card>
        </>
      </BootstrapDialog>
    </div>
  );
};
