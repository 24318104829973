"use client";

import React, { useState } from "react";
import { useTranslation } from "i18n/client";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { IOSSwitch, SettingsPrivacyHeader } from "@components/molecules";

export const AccountPrivacy = () => {
  const { t } = useTranslation("settings");
  const [privacyItems, setPrivacyItems] = useState([
    {
      label: t("notifications.account_privacy.memories.label"),
      subtitle: t("notifications.account_privacy.memories.subtitle"),
      open: false,
    },
    {
      label: t("notifications.account_privacy.contacts.label"),
      subtitle: t("notifications.account_privacy.contacts.subtitle"),
      open: false,
    },
    {
      label: t("notifications.account_privacy.reviews.label"),
      subtitle: t("notifications.account_privacy.reviews.subtitle"),
      open: false,
    },
    {
      label: t("notifications.account_privacy.invited.label"),
      subtitle: t("notifications.account_privacy.invited.subtitle"),
      open: false,
    },
  ]);
  const theme = useTheme();

  return (
    <Stack
      rowGap="20px"
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        pb: "24px",
      }}
    >
      <SettingsPrivacyHeader
        heading={t("notifications.account_privacy.heading")}
        subtitle={t("notifications.account_privacy.subtitle")}
      />
      {privacyItems.map(({ label, subtitle }, i) => {
        return (
          <Stack key={i} flexDirection="row">
            <Box>
              <IOSSwitch />
            </Box>
            <Box>
              <Typography
                component="h3"
                variant="Text_smMedium"
                sx={{
                  color: theme.palette.grey[700],
                }}
              >
                {label}
              </Typography>
              <Typography
                component="p"
                variant="Text_smNormal"
                sx={{
                  color: theme.palette.grey[500],
                }}
              >
                {subtitle}
              </Typography>
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};
