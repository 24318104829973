import React from "react";

export const LogoWhite = () => {
  // <defs><style>.cls-1{"fill":"#fff"}</style></defs>
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1127.05 162.27"
      fill="#fff"
      width="152px"
      height="22px"
    >
      <path
        className="cls-1"
        d="M174,96v66.13H144.39V96c0-19.68-5.56-27.17-19.39-27.17-15.94,0-23.23,9.89-23.23,27.17v66.13H72.2V96c0-19.68-5.57-27.17-19.4-27.17-15.93,0-23.23,9.89-23.23,27.17v66.13H0v-119H27.65l1.06,10.56c8.16-9,19.68-14.4,34-14.4,13.83,0,26.21,5,33.22,21,8.16-13,21.51-21,39-21C156,39.28,174,51.09,174,96Z"
      />
      <path
        className="cls-1"
        d="M286.21,48.7A63,63,0,0,0,241,39.87a52.6,52.6,0,0,0-23.28,9.41l-.17.11c-15.27,11-24.84,30-24.84,51.42a67.29,67.29,0,0,0,11.55,37.79c9.52,13.84,24.69,23.57,44.52,23.57A52.53,52.53,0,0,0,263,160.3a44.53,44.53,0,0,0,22.56-14.64l.57,5.73.68,10.79h27.65V97.25A60.92,60.92,0,0,0,286.21,48.7Zm-32.36,83.9c-19.3,0-31.6-16-31.6-32.16a31.62,31.62,0,0,1,31.6-31.59c16.22,0,31,12,31,31.67C284.85,119.34,271.42,132.6,253.85,132.6Z"
      />
      <path
        className="cls-1"
        d="M517,39.27l-55.42,123h-1.16l-55-123h32.93L461,92.84l22.66-53.57Z"
      />
      <path
        className="cls-1"
        d="M626.86,112.52H546.6c4.41,12.48,15.45,20.93,29.18,20.93,6.34,0,15.75-1.83,25.83-10.85l19.87,19.78a63.06,63.06,0,0,1-46.37,19.87c-33.22,0-59.14-26.11-59.14-61.44,0-35.62,26.31-61.45,59-61.45,34.56,0,54.62,28.8,54.62,56.84A45.6,45.6,0,0,1,626.86,112.52Zm-80.74-23h54.24c-3.07-12.58-11.71-21.32-25.44-21.32A29.64,29.64,0,0,0,546.12,89.48Z"
      />
      <path className="cls-1" d="M648.37,0h29.57V162.26H648.37Z" />
      <path
        className="cls-1"
        d="M701,.17l29.53,0,0,43h23.63V72H730.54l.18,49.72c0,7.68,2.44,11.46,9.79,12.19a76.93,76.93,0,0,0,13.47.72l-.06,27.65c-7.36-.12-17-.05-23.39-.05-19.3,0-29.38-13.92-29.38-40.51L701,72"
      />
      <path
        className="cls-1"
        d="M770.4,16.83a16.71,16.71,0,1,1,16.7,16.7A16.72,16.72,0,0,1,770.4,16.83Zm1.92,26.37h29.57l0,119.06H772.3Z"
      />
      <path
        className="cls-1"
        d="M994.21,96.33v65.94H964.64V96.33c0-19.68-5.57-27.17-19.39-27.17-15.94,0-23.24,9.89-23.24,27.17v65.94H892.44V96.33c0-19.68-5.57-27.17-19.39-27.17-15.94,0-23.23,9.89-23.23,27.17v65.94H820.25V43.43H847.9L849,54c8.16-9,19.68-14.4,34-14.4,13.82,0,26.21,5,33.22,21,8.16-13,21.5-21,39-21C976.26,39.59,994.21,51.4,994.21,96.33Z"
      />
      <path
        className="cls-1"
        d="M1124.27,112.52H1044c4.42,12.48,15.46,20.93,29.19,20.93,6.33,0,15.74-1.83,25.82-10.85l19.87,19.78a63.06,63.06,0,0,1-46.37,19.87c-33.22,0-59.14-26.11-59.14-61.44,0-35.62,26.31-61.45,59.05-61.45,34.56,0,54.62,28.8,54.62,56.84A45.6,45.6,0,0,1,1124.27,112.52Zm-80.74-23h54.24c-3.07-12.58-11.71-21.32-25.44-21.32A29.64,29.64,0,0,0,1043.53,89.48Z"
      />
      <path
        className="cls-1"
        d="M398.73,70.85c-1.16-.09-2.49-.09-3.84-.09-13,0-32,5-32,39.74v51.76H333.34V93.17a61,61,0,0,1,27-45.74l.17-.11c.28-.19.55-.37.83-.54,6.69-4.31,15.59-6.12,23.74-7.61"
      />
    </svg>
  );
};
