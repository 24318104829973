import { OutlinedInput, OutlinedInputProps, useTheme } from '@mui/material'
import React from 'react';
import { alpha } from '@mui/material/styles';
interface ITextarea extends OutlinedInputProps {
    row?: number,
    borderRadius?:string
}
export const Textarea = ({row=6,borderRadius="20px",...rest}: ITextarea) => {
    const theme = useTheme()
  return (
    
    <OutlinedInput multiline rows={row} sx={{
        width:"100%",
        fieldset : {
            border:"none !important"
       },
        '& .MuiInputBase-input': {
            borderRadius: borderRadius,
            position: 'relative',
            backgroundColor: '#ffffff',
            border: '1px solid #D0D5DD',
            width: '100%',
            padding: '10px 14px',
            fontStyle:"normal",
            fontWeight:"400",
            fontSize:"16px",
            lineHeight:"24px",
            color: "#101828",
            // Use the system font instead of the default Roboto font.
            fontFamily: [
              'Inter',
            ].join(','),
            '&:focus': {
              boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
              borderColor: theme.palette.primary.main,
            },
            
            '&::placeholder':{
               color:theme.palette.grey[500],
               fontSize:"16px",
               fontWeight:"normal"
            }
          },
    }}
    {...rest}
    />
  )
}
