"use client";
import React, { FC, useState } from "react";

// Swiper React Imports
import { Swiper, SwiperSlide } from "swiper/react";

// npm imports
import { Pagination, Navigation } from "swiper";

// TS imports
import { CarouselGridProps } from "./carousel-default.types";

//mui imports
import { Box } from "@mui/material";
import { useResponsiveLayout } from "@hooks";
import Image from "next/image";
export const CarouselDefault: FC<CarouselGridProps> = ({
  images,
  pagination = true,
  navigation = true,
  height = "100%",
  borderRadius = "0px",
  disableSwiping = false,
}) => {
  const { isMobile } = useResponsiveLayout();
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <Box
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      sx={{
        ".default__carousel": {
          height: height,
        },
        ".swiper-button-disabled": {
          visibility: "hidden",
          opacity: 0,
          transition: "0.5s ease-in-out",
        },
      }}
    >
      <Swiper
        pagination={pagination}
        navigation={!isMobile && isMouseOver && navigation}
        modules={[Pagination, Navigation]}
        preventInteractionOnTransition={true}
        className={`mySwiper default__carousel ${
          disableSwiping ? "swiper-no-swiping" : ""
        }`}
      >
        {images?.map((image: any, i: any) => {
          return (
            <SwiperSlide key={crypto.randomUUID()}>
             
              <Image
                src={image.file}
                alt={image?.alt ?? `image ${i}`}
                title={image?.title}
                width={640}
                height={640}
                style={{
                  borderRadius: borderRadius,
                }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};
