import React from 'react'

export const TelegramIcon = () => {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5365 29.1742C22.5244 29.1742 29 22.6987 29 14.7107C29 6.72275 22.5244 0.247192 14.5365 0.247192C6.54853 0.247192 0.0729675 6.72275 0.0729675 14.7107C0.0729675 22.6987 6.54853 29.1742 14.5365 29.1742Z" fill="#F858A5"/>
<path d="M5.45955 15.4182C7.15032 14.4869 9.03769 13.7096 10.8012 12.9283C13.835 11.6487 16.8809 10.3912 19.9575 9.22048C20.5561 9.02101 21.6317 8.82593 21.7371 9.71303C21.6794 10.9687 21.4418 12.217 21.2789 13.4653C20.8653 16.2105 20.3873 18.9463 19.9211 21.6824C19.7605 22.5938 18.6188 23.0656 17.8883 22.4824C16.1326 21.2965 14.3635 20.1222 12.6303 18.9088C12.0626 18.3319 12.589 17.5034 13.0961 17.0915C14.5421 15.6665 16.0756 14.4557 17.446 12.9571C17.8157 12.0644 16.7234 12.8167 16.3632 13.0472C14.3836 14.4114 12.4525 15.8588 10.3654 17.0577C9.2993 17.6446 8.05676 17.1431 6.99116 16.8156C6.03572 16.42 4.63563 16.0215 5.45945 15.4183L5.45955 15.4182Z" fill="#FCFCFD"/>
</svg>
  )
}
