import React from "react";
import { useTranslation } from "i18n/client";

import { ExploreDetailDataItem } from "@components/molecules";
import { Box, Typography } from "@mui/material";

export const ExploreDetailData = ({
  ageMax,
  ageMin,
  activeParticipation,
  hashtags,
}: {
  ageMax: string;
  ageMin: string;
  activeParticipation: string;
  hashtags:
    | {
        master: string;
        other: string[];
      }
    | undefined;
}) => {
  const { t } = useTranslation("experiences");
  return (
    <Box>
      <ExploreDetailDataItem
        title={t("age")}
        value={`${ageMin} - ${ageMax ? ageMax : "∞"} ${t("years")}`}
      />
      <ExploreDetailDataItem
        title={t("type")}
        value={
          activeParticipation
            ? t("active_participation")
            : t("passive_participation")
        }
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          margin: "8px 0px 0px 0px",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: 16,
            fontWeight: 500,
            color: "#101828",
            alignSelf: "flex-start",
          }}
        >
          {t("tags")}{" "}
        </Typography>{" "}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            component="span"
            sx={{
              fontFamily: "Inter",
              fontSize: 12,
              fontWeight: 400,
              color: "#d12779",
              margin: "0px 0px 0px 8px",
              backgroundColor: "#fff6fb",
              padding: "2px 8px",
              borderRadius: "16px",
            }}
          >
            {hashtags?.master}
          </Typography>
          {hashtags &&
            hashtags?.other.map((hashtag) => {
              return (
                <Typography
                  key={hashtag}
                  component="span"
                  sx={{
                    fontFamily: "Inter",
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#d12779",
                    margin: "0px 0px 0px 8px",
                    backgroundColor: "#fff6fb",
                    padding: "2px 8px",
                    borderRadius: "16px",
                  }}
                >
                  {hashtag}
                </Typography>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};
