import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, useMediaQuery } from "@mui/material";

export const MemoryCarousel = ({
  images,
  bottomNavigation = true,
  height = "300px",
}: any) => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Carousel
      indicatorContainerProps={{
        style: {
          display: bottomNavigation ? "block" : "none",
        },
      }}
      navButtonsAlwaysVisible={matches ? true : false}
      autoPlay={false}
      className="memoryCarousel__container"
    >
      {images.map((item: any, i: any) => (
        <Item
          key={i}
          item={item.file}
          alt={item.alt}
          title={item.title}
          height={height}
        />
      ))}
    </Carousel>
  );
};

function Item({ item, alt, title, height }: any) {
  return (
    <Paper>
      <img src={item} alt={alt} title={title} height={height} width="100%" />
    </Paper>
  );
}
