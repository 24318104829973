import { Typography, styled } from '@mui/material'
import React from 'react'
const StyledHelperText = styled(({children, ...rest}:{children:any}) => (
    <Typography component="p" variant="Body_smallRegular" sx={{mt:"6px"}} {...rest}>{children}</Typography>
  ))(({theme}) => ({color:theme.palette.grey[500]}))
export const HelperText = ({
    children,
    ...rest
}:{
    children:any
}) => {
  return (
    <StyledHelperText {...rest}>{children}</StyledHelperText>
  )
}
