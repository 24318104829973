import {
  ButtonContained,
  CustomTypography,
  CustomInputField,
} from "@components/atoms";
import { SignupTermsVerify } from "@components/molecules";
import { Box } from "@mui/material";
import React from "react";
import useTranslation from 'next-translate/useTranslation';

export const SignupForm = (): JSX.Element => {
  const {t} = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <form>
        <Box
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
        >
          
          <CustomInputField name="fullName" placeholder={t('common:Full_Name')} />
        </Box>
        <Box
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <CustomInputField name="Username" placeholder={t('common:Username')} />
        </Box>
        <Box
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
        >
         
          <CustomInputField type="email" name="email" placeholder={t('common:Email.Placeholder')}  />
        </Box>
        <Box
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <CustomInputField type="password" name="password" placeholder={t('common:Password.Placeholder')}  />
          <Box
            sx={{
              marginTop: "6px",
            }}
          >
            <CustomTypography
              textalign="left"
              fontstyle="normal"
              fontsize="14px"
              fontweight="400"
              lineheight="20px"
              color="#667085"
            >
              Password.Min_Character
            </CustomTypography>
          </Box>
        </Box>
      <SignupTermsVerify/>
        <Box
          sx={{
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <ButtonContained id="signupForm_btn" sizeVariant="large">{t('common:Register.Create_Account')}</ButtonContained>
        </Box>
      </form>
    </Box>
  );
};
