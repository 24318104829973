import React from 'react'

export const WatchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#3l4jllogya)">
        <path d="M12.5 9.5v3L14 14m3.01 3.85-.35 3.83a2 2 0 0 1-2 1.82h-4.33a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7.35-3.83a2 2 0 0 1 1.99-1.82h4.35a2 2 0 0 1 2 1.82l.35 3.83m2.47 5.35a7 7 0 1 1-14 0 7 7 0 0 1 14 0z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
        <clipPath id="3l4jllogya">
            <path fill="#fff" transform="translate(.5 .5)" d="M0 0h24v24H0z"/>
        </clipPath>
    </defs>
</svg>
  )
}
