import React from 'react'
import { Checkbox, CheckboxProps, Icon } from '@mui/material'
import { TickIcon } from '../svgs'
export const CutomCheckbox = (props:CheckboxProps) : JSX.Element => {
  return (
    <Checkbox icon={<Icon sx={{
      border:`1px solid #f62e8e`,
      width:"16px",
      height:"16px",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      borderRadius:"4px",
      marginTop:"-7px"
  }}></Icon>} checkedIcon={<Icon sx={{
        border:`1px solid #f62e8e`,
        width:"16px",
        height:"16px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        borderRadius:"4px",
        marginTop:"-7px"
    }}><TickIcon/></Icon>} sx={{
        margin:"0px",
        padding:"0px"
    }}
     {...props}
    />
  )
}
