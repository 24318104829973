import React from 'react'

export default function GoogleIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#hfizvtp7ia)">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.842 6.549a6.52 6.52 0 0 0-4.604-1.8c-3.13 0-5.79 2.113-6.737 4.957a7.188 7.188 0 0 0 0 4.594h.004c.953 2.84 3.607 4.952 6.738 4.952 1.616 0 3.003-.413 4.078-1.143v-.003a5.555 5.555 0 0 0 2.399-3.647h-6.482V9.84h11.32c.14.802.206 1.622.206 2.437 0 3.65-1.304 6.736-3.574 8.826l.003.002C18.204 22.938 15.475 24 12.238 24a12.002 12.002 0 0 1-10.723-6.61 12.01 12.01 0 0 1 0-10.776A11.998 11.998 0 0 1 12.238 0a11.533 11.533 0 0 1 8.03 3.122l-3.426 3.426z" fill="#98A2B3"/>
    </g>
    <defs>
        <clipPath id="hfizvtp7ia">
            <path fill="#fff" d="M0 0h24v24H0z"/>
        </clipPath>
    </defs>
</svg>
  )
}
