import { InputFieldWithLabel } from "@components/molecules";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { Dialog } from "@next/components/molecules/dialog";
import React, { FC } from "react";
interface IEditProfileModal {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  name: string;
  label: string;
  placeholder?: string;
}
export const EditProfileModal: FC<IEditProfileModal> = ({
  open,
  onClose,
  title,
  subtitle,
  name,
  label,
  placeholder
}) => {
    const theme = useTheme()
  return (
    <Dialog open={open} onClose={onClose} paddingLeft="24px">
      <Grid container px="24px">
        <Grid item xs={12}>
          <Typography component="h2" variant="Body_largeMedium" sx={{textAlign:"center", color:theme.palette.grey[900]}}>{title}</Typography>
          <Typography component="p" variant="Body_smallRegular" sx={{textAlign:"center", color:theme.palette.grey[500], mt:"8px"}}>{subtitle}</Typography>
          <Box mt="20px">
            <InputFieldWithLabel
              type="text"
              name={name}
              label={label}
              placeholder={placeholder}
            />
          </Box>
          <Button id="setting_editProfile_modal_save_btn" variant="contained" sx={{width:"100%",mt:"32px",textTransform:"unset"}}>Save Changes</Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
