// MUI import
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers';
import { PickersDayProps } from '@mui/x-date-pickers';
import CircleIcon from '@mui/icons-material/Circle';
import { useResponsiveLayout } from '@hooks';
import React from 'react';

// import { momentWithTz } from 'src/utils/momentWithTz';
export const StaticCalendar = () => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-17'));
    // const [open, setOpen] = useState(false);
    // Used this function for displaying badge on dates
   
    const hanldeMonthChange = (day: string) => {
        //handle month change
        console.log('day',day)
    }
    const handleDateClick = (day: string) => {
      //handle date
      console.log('day',day)
  }
    function ServerDay(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) {
     
        const { day, outsideCurrentMonth, ...other } = props;
        const availabilityDay = day.toDate().toDateString();
        const isUserAvailability = true;
        const isSharedAvailability = true;
        const firstBadge = true; // if true will show badge on date
        const { isMobile } = useResponsiveLayout();
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* first badge  */}
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    sx={{
                        '& .MuiBadge-badge': {
                            // m: isSharedAvailability ? '0px 28px' : '0px 20px',
                            m: isSharedAvailability ? (isMobile ? '0px 22px' : '0px 28px') : isMobile ? '0px 15px' : '0px 20px',
                            display: firstBadge ? '' : 'none',
                        },
                    }}
                    overlap="circular"
                    badgeContent={isUserAvailability ? <CircleIcon color="secondary" sx={{ fontSize: '12px' }} /> : undefined}
                >
                    <PickersDay
                        {...other}
                        outsideCurrentMonth={outsideCurrentMonth}
                        day={day}
                        disabled={outsideCurrentMonth}
                        onClick={() => {
                            handleDateClick(props.day.toString());
                        }}
                    />
                </Badge>

                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    sx={{
                        '& .MuiBadge-badge': {
                            // m: !firstBadge ? '0px 28px' : '0px 18px',
                            m: !firstBadge ? '0px 28px' : isMobile ? '0px 13px' : '0px 18px',
                        },
                    }}
                    key={props.day.toString()}
                    overlap="circular"
                    badgeContent={isSharedAvailability ? <CircleIcon color="primary" sx={{ fontSize: '12px' }} /> : undefined}
                />
            </div>
        );
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                    slots={{
                        day: ServerDay,
                    }}
                    showDaysOutsideCurrentMonth
                    displayStaticWrapperAs="desktop"
                    views={['day']}
                    defaultValue={dayjs(new Date())}
                    value={value} onChange={(newValue) => setValue(newValue)}
                    onMonthChange={(month) => {
                        console.log('onMonth change', month.toDate());
                        hanldeMonthChange(month.toDate().toDateString());
                    }}
                    sx={{
                        '&. MuiButtonBase-root': { display: 'none' },
                        '& .MuiPickersCalendarHeader-label': { fontSize: '16px', fontWeight: '600', color: 'dark.dark' },
                        '& .MuiDateCalendar-root': { width: { xs: '310px', sm: '400px' } },
                        '& .MuiDayCalendar-weekDayLabel': { m: '0px 10px' },
                        '& .MuiPickersDay-today': {
                            borderRadius: '12px',
                            border: '1px solid #87BE56',
                            color: 'primary.main',
                            background: 'transparent',
                        },
                        '& .MuiDayCalendar-slideTransition': { overflowX: 'visible' },
                    }}
                    slotProps={{
                        actionBar: { sx: { display: 'none' } },
                        layout: {
                            sx: {
                                border: '2px solid #F7F7F7',
                                minHeight: { sm: '410', xs: '340px' },
                                minWidth: { sm: '416px', xs: '316px' },
                                borderRadius: '12px',
                            },
                        },
                        day: {
                            sx: {
                                m: { sm: '1px 10px 1px 10px', xs: '1px 4px 1px 4px' },
                                '&.MuiPickersDay-today': {
                                    borderRadius: '12px',
                                    border: '1px solid #87BE56',
                                    color: 'primary.main',
                                    background: 'transparent',
                                },
                                color: 'dark.dark',
                                '&.Mui-selected': {
                                    backgroundColor: 'red',
                                    borderColor: '#87BE56',
                                    borderRadius: '12px',
                                    color: 'red !important',
                                    '&:hover': { backgroundColor: 'primary.main', borderColor: '#87BE56' },
                                    '&:focus': { backgroundColor: 'primary.main', borderColor: '#87BE56' },
                                },
                            },
                        },
                    }}
                />
            </LocalizationProvider>
            {/* <SetAvailabilityModal open={open} setOpen={setOpen} /> */}
        </>
    );
}
