import { environment, experiences } from "@config";
import axios from "axios";
export const subscribeForNewsletter = async ({ email }: { email: string }) => {
  let baseUrl = environment.apiKey;
  baseUrl = `${baseUrl}/explore/api/v1/newsletters/subscribe`;
  return await axios.post(baseUrl, {
    email,
  });
};
export const unSubscribeFromNewsletter = async ({ email }: { email: string }) => {
  let baseUrl = environment.apiKey;
  baseUrl = `${baseUrl}/explore/api/v1/newsletters/unsubscribe`;
  return await axios.post(baseUrl, {
    email,
  });
};