import { Box, Typography } from "@mui/material"
import React, { FC } from "react";
import { ExploreInviteInputLayoutProps } from "./explore-invite-partner.types";
export const ExploreInviteInputLayout: FC<ExploreInviteInputLayoutProps> = ({
    children,
    title
}) => {
    return (
        
            <Box sx={{
                flex: "1 1 100%"
            }}>
                <Typography component="label" variant="Text_smMedium" color="#ffffff"  >{title}</Typography>
               {children}
            </Box>
          
    )
}