import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
const CustomImage = styled(Box)`
  background-position: center;
  background-size: cover;
  height: ${(props: any) => props.height};
  max-width: 100%;
`;
const DeleteImage = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 10px;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.7);
`;
const GridViewComponent = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 12px;
  right: 10px;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.7);
  @media (max-width: 768px) {
    bottom: 12px;
  }
`;

// import required modules
import { Pagination, Navigation } from "swiper";
import { CrossIcon, GridViewIcon,WhiteCrossIcon } from "@components/atoms";
// {
//   dynamicBullets: true,
// }
export const CarouselGrid = ({ images, removeImage,gridView }: any) => {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Swiper
        pagination={true}
        navigation={matches ? true : false}
        modules={[Pagination, Navigation]}
        className="mySwiper createMemory__slider"
      >
        {images.map((image: any, i: any) => {
          return (
            <SwiperSlide key={i} style={{ position: "relative" }}>
              <img src={image.file} alt="img"/>
              <DeleteImage>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => removeImage(image.id)}
                >
                  <WhiteCrossIcon />
                </Box>
              </DeleteImage>
              <GridViewComponent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => gridView()}
                >
                  <GridViewIcon />
                </Box>
              </GridViewComponent>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};
