import React, { FC } from "react";
import { useTranslation } from "i18n/client";

import { Dialog } from "../dialog";
import Grid from "@mui/material/Grid";
import { Typography, useTheme } from "@mui/material";
import { ButtonContained } from "@components/atoms";
import { InputFieldWithLabel } from "../input-field-with-label";
import { SignupTermsVerify } from "../signup-terms-verify";
import useSuggestExperienceModal from "./use-suggest-experience-modal";
import { Controller } from "react-hook-form";
import { ErrorField } from "../error-field";
interface ISuggestExperienceModal {
  open: boolean;
  onClose: () => void;
  id?: string;
}
export const SuggestExperienceModal: FC<ISuggestExperienceModal> = ({
  open,
  onClose,
  id,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const { control, errors, handleSuggestExp } = useSuggestExperienceModal({
    id,
    onClose,
  });
  return (
    <Dialog open={open} onClose={onClose}>
      <Grid
        container
        sx={{
          padding: "10px 16px",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="Display_xsMedium"
            component="h2"
            sx={{
              color: theme.palette.grey[900],
            }}
          >
            {t("suggest_experience_modal.heading")}
          </Typography>
          <Typography
            variant="Text_mdNormal"
            component="p"
            sx={{
              color: theme.palette.grey[500],
              marginTop: "8px",
            }}
          >
            {t("suggest_experience_modal.subtitle")}
          </Typography>
        </Grid>
        <Grid item container xs={12} mt="32px" rowGap="24px">
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value } }) => (
                <InputFieldWithLabel
                  value={value}
                  onChange={onChange}
                  type="text"
                  name="name"
                  label={t("suggest_experience_modal.name")}
                  placeholder={t("suggest_experience_modal.name_placeholder")}
                />
              )}
            />
            <ErrorField error={errors?.name?.message ?? ""} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="website"
              render={({ field: { onChange, value } }) => (
                <InputFieldWithLabel
                  value={value}
                  onChange={onChange}
                  type="text"
                  name="website"
                  label={t("suggest_experience_modal.website")}
                  placeholder={t(
                    "suggest_experience_modal.website_placeholder"
                  )}
                />
              )}
            />
            <ErrorField error={errors?.website?.message ?? ""} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <InputFieldWithLabel
                  value={value}
                  onChange={onChange}
                  type="email"
                  name="email"
                  label={t("suggest_experience_modal.email")}
                  placeholder={t("suggest_experience_modal.email_placeholder")}
                />
              )}
            />
            <ErrorField error={errors?.email?.message ?? ""} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="termOfServices"
              render={({ field: { onChange, value } }) => (
                <SignupTermsVerify checked={value} onChange={onChange} />
              )}
            />
            <ErrorField error={errors?.termOfServices?.message ?? ""} />
          </Grid>
          <Grid item xs={12}>
            <ButtonContained id="suggest_experience_modal_btn" clickHandler={handleSuggestExp}>
              {t("suggest_experience_modal.btn")}
            </ButtonContained>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
