import React, { FC } from "react";
import { useTranslation } from "i18n/client";

import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import NothingFound from "@next/components/molecules/nothing-found/nothing-found";
import RecommendationsSkeletonLoader from "@next/components/molecules/recommendations-skeleton-loader/recommendations-skeleton-loader";
import { RecomendationCard } from "@components/molecules";

import { RecomendationsProps } from "./recomendations.types";

export const Recomendations: FC<RecomendationsProps> = ({
  recomendations,
  handleMore,
  hasMoreRecommendations,
  isLoading,
  noContentFound,
  loadMoreButtonId,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  return (
    <Box
      id="recommendations"
      sx={{
        padding: "0px 0px",
        mb: { xs: "0px", sm: "20px" },
      }}
    >
      {noContentFound ? (
        <Box
          sx={{
            minHeight: "400px",
          }}
        >
          <NothingFound />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              mt: "0px",
              mb: {
                xs: "22px",
                md: "32px",
              },
            }}
          >
            <Typography
              component="h2"
              variant="Display_smMedium"
              color={theme.palette.grey[900]}
            >
              {t("recommendations.title")}
            </Typography>
          </Box>
          <Box>
            <Grid container spacing="32px">
              {recomendations?.map((recomendation: any, i: any) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                    <RecomendationCard
                      activityId={recomendation.activityId}
                      heading={recomendation.heading}
                      subtitle={recomendation.subtitle}
                      url={recomendation?.url}
                      images={recomendation.images ?? []}
                    />
                  </Grid>
                );
              })}
              {isLoading && <RecommendationsSkeletonLoader />}
            </Grid>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: "32px" }}>
            {hasMoreRecommendations() && (
              <Button
                id={loadMoreButtonId || "recommendations_load_more"}
                variant="contained"
                onClick={handleMore}
                sx={{
                  color: "#fff",
                  textTransform: "unset",
                  height: "48px",
                }}
              >
                <Typography variant="Text_mdMedium">
                  {t("recommendations.load_more")}
                </Typography>
              </Button>
            )}
          </Box>{" "}
        </>
      )}
    </Box>
  );
};
