import React from "react";
import {
    TelegramShareButton
} from "react-share";
export const TelegramShare = ({
    children,
    url
}: any) => {
    return (
        <TelegramShareButton
            url={url}
        >
            {children}
        </TelegramShareButton>
    )
}