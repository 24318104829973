import * as React from "react";
import { Grid, Box, Typography } from "@mui/material";

export const ExploreDetailDataItem = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        margin: "8px 0px 0px 0px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: 16,
          fontWeight: 500,
          color: "#101828",
          minWidth:"40px"
        }}
      >
        {title}
      </Typography>{" "}
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: 16,
          fontWeight: 400,
          color: "#667085",
          margin: "0px 0px 0px 8px",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};
