import React from "react";
import {
    FacebookShareButton
} from "react-share";
export const FacebookShare = ({
    children,
    url
}: any) => {
    return (
        <FacebookShareButton
            url={url}
        >
            {children}
        </FacebookShareButton>
    )
}