"use client";

import React from "react";
import { useParams, usePathname } from "next/navigation";
import { Box } from "@mui/material";
import { HomeIcon, ExploreIcon } from "@components/atoms";
import { NavItemLink, NavItemLinkStyle } from "../private-navbar-items/private-navbar-items.styles";
import { NavItemName } from "../navbar-item-name";
import { CustomLink } from "../custom-link";

export const NavbarItems = () => {
  const pathname = usePathname();
  const params = useParams<{ lng: string }>();
  const currentPath = pathname.substring(pathname.indexOf("/") + 1);

  const isActivePath = (val: string) => {
    if (currentPath.includes(val) || (currentPath ?? "") === val) {
      return true;
    } else {
      return false;
    }
  };
  const isExploreActive = () => {
    const paths = ["explore", "experiences", "activities", "home"];
    let isActive = false;
    paths.forEach((path) => {
      if (currentPath.includes(path)) {
        isActive = true;
      }
    });
    return isActive;
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <CustomLink
          href="/" 
          locale={params.lng}
          style={{
            ...NavItemLinkStyle
          }}
        >
          <HomeIcon isActive={isActivePath("")} />
          <NavItemName name="Home" isActive={isActivePath("")} />
        </CustomLink>
        <CustomLink
          href="/explore" 
          locale={params.lng}
          style={{
            ...NavItemLinkStyle
          }}
        >
          <ExploreIcon fill={isExploreActive()} />
          <NavItemName name="Explore" isActive={isExploreActive()} />
        </CustomLink>
        {/* <HomeIcon/>
    <PeaceIcon/>
    <ImagesIcon/> */}
      </Box>
    </>
  );
};
