import React from "react";
import { styled } from "@mui/system";
import { Box, Grid, Avatar, Typography, Hidden } from "@mui/material";
import { CustomTypography, ButtonOutlined, ButtonContained } from "@components/atoms";
const ProfileBanner = styled(Box)({
    background: "pink",
    height: "240px"
})
const StyledTypography = styled(Typography)({
    display: "inline",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "20px",
    color: "#667085",
    marginRight: "27px"
})
const StyledTypographySpan = styled(Typography)({
    display: "inline",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "20px",
    color: "#667085",
})
export const ProfileInfoBox = () => {
    return (
        <Box>
            <ProfileBanner />
            <Grid container sx={{
                background: "#fcfcfd"
            }}>
                <Hidden smDown={true}>
                    <Grid item sm={4} sx={{
                        position: "relative"
                    }}>
                        <Avatar sx={{
                            width: "160px",
                            height: "160px",
                            border: "4px solid #ffffff",
                            boxShadow: "0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08)",
                            position: "absolute",
                            right: "40px",
                            top: "-40px"
                        }} />
                    </Grid>
                    <Grid item sm={8} sx={{ padding: "26px 0px  36px 0px" }}>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <CustomTypography fontsize="30px" fontweight="500">Sofia Gabrielle</CustomTypography>
                            <Box sx={{
                                marginLeft: "40px"
                            }}>
                                <ButtonOutlined id="profileInfo_edit_profile" height="44px" border="1px solid rgba(16, 24, 40, 0.05)" color="#344054">Edit profile</ButtonOutlined>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <StyledTypography>
                                <StyledTypographySpan>79</StyledTypographySpan> memories
                            </StyledTypography>
                            <StyledTypography>
                                <StyledTypographySpan>379</StyledTypographySpan> followers
                            </StyledTypography>
                        </Box>
                        <Box>
                            <CustomTypography fontsize="16px" fontweight="500" color="#262626" textalign="left">Manila, Philippines</CustomTypography>
                        </Box>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <CustomTypography fontsize="16px" fontweight="500" color="#667085" textalign="left">Creative director</CustomTypography>
                            <Box sx={{
                                marginLeft: "5px"
                            }}>
                                <Box sx={{
                                    marginRight: "5px"
                                }}>
                                    <CustomTypography fontsize="16px" fontweight="500" color="#667085" textalign="left">@Ascent Studio</CustomTypography>
                                </Box>
                            </Box>

                        </Box>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <CustomTypography fontsize="16px" fontweight="500" color="#667085" textalign="left">Creative designer</CustomTypography>
                            <Box sx={{
                                marginLeft: "5px"
                            }}>
                                <Box sx={{
                                    marginRight: "5px"
                                }}>
                                    <CustomTypography fontsize="16px" fontweight="500" color="#667085" textalign="left">@GK Creatives</CustomTypography>
                                </Box>
                            </Box>

                        </Box>
                        <Box>
                            <CustomTypography fontsize="16px" fontweight="500" color="#67133c" textalign="left">ascentstudio.com</CustomTypography>
                        </Box>
                    </Grid>
                </Hidden>
                {/*------------------ For Mobile------------------------ */}
                <Hidden smUp={true}>
                    <Grid item xs={4} sx={{
                        position: "relative"
                    }}>
                        <Avatar sx={{
                            width: "96px",
                            height: "96px",
                            border: "4px solid #ffffff",
                            boxShadow: "0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08)",
                            position: "absolute",
                            left: "16px",
                            top: "-21px"
                        }} />
                    </Grid>
                    <Grid item xs={8} sx={{ padding: "4px 0px  24px 0px" }}>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <CustomTypography fontsize="24px" fontweight="500">Sofia Gabrielle</CustomTypography>
                           
                        </Box>
                        <Box sx={{
                                maxWidth:"215px",
                                marginTop:"8px"
                            }}>
                                <ButtonContained id="profileInfo_edit_profile" height="44px" fontsize="16px">Edit profile</ButtonContained>
                            </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: "8px 0px  36px 16px" }}>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <StyledTypography>
                                <StyledTypographySpan>79</StyledTypographySpan> memories
                            </StyledTypography>
                            <StyledTypography>
                                <StyledTypographySpan>379</StyledTypographySpan> followers
                            </StyledTypography>
                        </Box>
                        <Box>
                            <CustomTypography fontsize="16px" fontweight="500" color="#262626" textalign="left">Manila, Philippines</CustomTypography>
                        </Box>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <CustomTypography fontsize="16px" fontweight="500" color="#667085" textalign="left">Creative director</CustomTypography>
                            <Box sx={{
                                marginLeft: "5px"
                            }}>
                                <Box sx={{
                                    marginRight: "5px"
                                }}>
                                    <CustomTypography fontsize="16px" fontweight="500" color="#667085" textalign="left">@Ascent Studio</CustomTypography>
                                </Box>
                            </Box>

                        </Box>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <CustomTypography fontsize="16px" fontweight="500" color="#667085" textalign="left">Creative designer</CustomTypography>
                            <Box sx={{
                                marginLeft: "5px"
                            }}>
                                <Box sx={{
                                    marginRight: "5px"
                                }}>
                                    <CustomTypography fontsize="16px" fontweight="500" color="#667085" textalign="left">@GK Creatives</CustomTypography>
                                </Box>
                            </Box>

                        </Box>
                        <Box>
                            <CustomTypography fontsize="16px" fontweight="500" color="#67133c" textalign="left">ascentstudio.com</CustomTypography>
                        </Box>
                    </Grid>
                </Hidden>
            </Grid>
        </Box>

    )
}