"use client";

import React from "react";
import { useRouter } from "next/navigation";

import { ButtonContained, ControledInputField } from "@components/atoms";
import { Box } from "@mui/material";

export const ForgotPasswordForm = (): JSX.Element => {
  const router = useRouter();
  const handleRedirect = () => {
    router.push("/reset-password");
  };
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <Box
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <ControledInputField type="email" placeholder="Enter your email" />
        </Box>
        <Box
          sx={{
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <ButtonContained id="forgortPasswordForm_btn" clickHandler={handleRedirect} sizeVariant="large">
            Reset password
          </ButtonContained>
        </Box>
      </form>
    </Box>
  );
};
