import { styled } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const StyledToastContainer = styled(ToastContainer)`
    .Toastify__toast--error {
        background: #ffe5e7;
        color: #ea3a3d;
    }
    ,
    .Toastify__toast--success {
        background: #e4eddb;
        color: #87be56;
    }
    ,
    .Toastify__toast--info {
        background: #dcedff;
        color: #0072ef;
    }
    ,
    .Toastify__toast--warning {
        background: #fff7ea;
        color: #ff9432;
    }
    ,
    .Toastify__close-button {
        margin: 30px;
    }
`;
const ToastifyContainer = ({ closeButton }: ToastContainerProps) => {
    return (
        <StyledToastContainer
            position="top-right"
            pauseOnFocusLoss
            draggable
            pauseOnHover
            hideProgressBar={true}
            closeButton={closeButton}
        />
    );
};
export default ToastifyContainer;
