import { addRating } from "@ApiReq";
import {
  deleteInterest,
  getUserInterestByExperienceId,
  updateInterest,
} from "@next/ApiReq/interests/interests-api";
import { useParams } from "next/navigation";
import React, { useEffect, useState } from "react";

interface IUserInterest {
  experience?: {
    id: string;
    name?: string;
  };
  id: string;
  interestLevel: number;
  isOnBucketList: boolean;
  isPersonalPassion: boolean;
}
const useExperienceRateInterest = ({
  experienceId,
}: {
  experienceId: string;
}) => {
  const [userInterest, setUserInterest] = useState<IUserInterest | null>(null);
  const [currentGif, setCurrentGif] = useState(0);
  const urlParams = useParams<{ lng: string }>();
  const accountId = sessionStorage?.getItem("AccountId") || "";
  useEffect(() => {
    if (accountId) {
      getUserInterestByExperienceId({
        experienceId: experienceId,
        accountId: accountId,
        locale: urlParams?.lng,
      }).then((res) => {
        console.log("user interest:", res);
        const resData = res?.data;
        if (resData) {
          setUserInterest(resData);
        } else {
          setUserInterest(null);
        }
      });
    }
  }, [experienceId, urlParams?.lng, accountId]);
  const handleAddRating = (interestLevel: number) => {
    addRating({
      accountId: accountId,
      experienceId: experienceId,
      interestLevel: interestLevel,
      isOnBucketList: false,
      isPersonalPassion: false,
    })
      .then((res) => {
        console.log("user interest added:", res);
        const data = res?.data;
        if (data) {
          setUserInterest(data);
        }
      })
      .catch(() => ({}));
  };
  const handleDeleteInterest = () => {
    deleteInterest({
      interestId: userInterest?.id as string,
      locale: urlParams?.lng,
    }).then((res) => {
      console.log("user interest deleted:", res);
    });
  };

  const handleUpdateInterest = ({
    interestLevel,
  }: {
    interestLevel: number;
  }) => {
    const data = {
      interestLevel: interestLevel,
      isOnBucketList: false,
      isPersonalPassion: false,
    };
    updateInterest({
      interestId: userInterest?.id as string,
      locale: urlParams?.lng,
      data,
    }).then((res) => {
      console.log("user interest update:", res);
      if (userInterest) {
        const updateInterest = {
          ...userInterest,
          interestLevel: interestLevel === userInterest.interestLevel ? 0 : interestLevel,
        };
        console.log("user interest updated:", updateInterest);
        setUserInterest(updateInterest);
      }
    });
  };
  return {
    userInterest,
    handleDeleteInterest,
    handleUpdateInterest,
    handleAddRating,
    currentGif,
    setCurrentGif
  };
};

export default useExperienceRateInterest;
