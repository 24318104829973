import {  addNewSuggestionForExperience } from '@ApiReq';
import { urlValidator } from '@constants';
import { yupResolver } from '@hookform/resolvers/yup';
import toastify from '@next/utils/toastify';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
interface IFormValuesType {
    name:string;
    email: string;
    website: string;
    termOfServices: boolean
}
const schema = Yup.object({
    name: Yup.string().trim('').required("Name is required").min(2,"Name is required with minimum 2 characters"),
    email:  Yup.string().trim('').required("Name is required").email('Email must be valid'),
    website: Yup.string()
    .trim('').matches(urlValidator, 'Enter a valid URL').required('URL is required'),
    termOfServices: Yup.boolean().oneOf([true], "Terms and condition are required"),
}).required();
export default function useSuggestExperienceModal({id,onClose}:{id?:string, onClose: () => void;}) {
    
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<IFormValuesType>({
        resolver: yupResolver(schema),
        defaultValues: {
            name:'',
            email: '',
            website: '',
            termOfServices:false
        },
        mode: 'onChange',
    });
    const handleSuggestExp = handleSubmit((data) => {
          
        addNewSuggestionForExperience({id,data}).then((res) => {
              
               toastify("success","Thank you! We'll review your proposal and inform you about the result.");
               onClose()
           
          }).catch(err => {
            toastify("error",err?.response?.data?.title)
              console.log("err", err?.response)
          })
    })
  return {control,errors,isValid,handleSuggestExp}
}
