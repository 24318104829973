"use client";
import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import { MoreIcon } from "@components/atoms";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledImageListItem = styled(ImageListItem)`
  width: 100%;
`;
export const MasonryImageList = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box sx={{ width: "100%" }}>
      <ImageList variant="masonry" cols={matches ? 4 : 2} gap={20}>
        {itemData.map((item) => (
          <StyledImageListItem key={item.img}>
            <picture>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="eager"
              />
            </picture>
            <ImageListItemBar
              position="below"
              title={item.author}
              subtitle={item.author}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)", marginTop: "10px" }}
                  aria-label={`info about ${item.title}`}
                >
                  <MoreIcon />
                </IconButton>
              }
            />
          </StyledImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

const itemData = [
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Bed",
    author: "swabdesign",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Books",
    author: "Pavel Nekoranec",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Sink",
    author: "Charles Deluvio",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Kitchen",
    author: "Christian Mackie",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Blinds",
    author: "Darren Richardson",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Chairs",
    author: "Taylor Simpson",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Laptop",
    author: "Ben Kolde",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Doors",
    author: "Philipp Berndt",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Coffee",
    author: "Jen P.",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Storage",
    author: "Douglas Sheppard",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Candle",
    author: "Fi Bell",
  },
  {
    img: "/assets/images/explore/placeholder-memory.png",
    title: "Coffee table",
    author: "Hutomo Abrianto",
  },
];
