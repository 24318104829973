import Link from 'next/link'
import React from 'react'

interface ICustomLink  {
    href: string;
    locale: string;
    children?: string | React.ReactNode;
    title?: string;
    style?:any
}
export const CustomLink = ({href, locale, ...rest}: ICustomLink) => {
    // const isDefaultLang = locale === "en-us";
    let path =locale ? `/${locale}${href}` : href; 
    //check if path include en-us/... or /en-us/... then remove it
    if (path.startsWith('en-us/')) {
      path = path.replace(/^en-us\//, '');
  } else if (path.startsWith('/en-us/')) {
      path = path.replace(/^\/en-us\//, '/');
  } else if (path.includes('/en-us/')) {
      path = path.replace('/en-us/', '/');
  }
   
  return (
    <Link href={path} {...rest} locale={locale}/>
  )
}

