import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import styled from '@emotion/styled';

const emails = [''];
const CustomListItemText = styled(ListItemText)`
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    text-align:center;
  }
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }
`;
const CustomListItemTextDelete = styled(CustomListItemText)`
span{
  color:#fa0000
}
`
export interface SimpleDialogProps {
  open: boolean;
  onAddToGroup:any
  onClose: () => void;
}

function ModalContent(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = () => {
    onClose();
  };
  const handleAddToGroupClick = () => {
    props.onAddToGroup();
    onClose();
  }
  return (
    <Dialog  fullWidth={true} maxWidth="xs" onClose={handleClose} open={open} >
      {/* <DialogTitle>Set backup account</DialogTitle> */}
      <List sx={{ pt: 0 }}>
        <ListItem autoFocus button onClick={() => handleAddToGroupClick()}>
          <CustomListItemText primary="Add to group" />
        </ListItem>
        <ListItem autoFocus button onClick={() => handleListItemClick()}>
          <CustomListItemTextDelete  primary="Delete" />
        </ListItem>
        <ListItem autoFocus button onClick={() => handleListItemClick()}>
          <CustomListItemText primary="Cancel" />
        </ListItem>
      </List>
    </Dialog>
  );
}

export const ContactListModal = ({
    open,
    handleClose,
    onAddToGroup
}:any) => {
 
 


  const handleModalClose = () => {
    handleClose(false);
    
  };

  return (
    <div>
      <ModalContent
        open={open}
        onClose={handleModalClose}
        onAddToGroup={onAddToGroup}
      />
    </div>
  );
}
