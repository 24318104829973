"use client";

import React, { useEffect, useState } from "react";


import {
  FacebookGreyIcon,
  TwitterGreyIcon,
  TelegramGreyIcon,
  LinkIcon,
} from "@components/atoms";
import Box from "@mui/material/Box";
import GoogleIcon from "@next/components/atoms/svgs/google/google";

import {
  FacebookShare,
  TwitterShare,
  TelegramShare,
  EmailShare,
  WhatsappShare,
} from "../";
import CopyToClipboard from "react-copy-to-clipboard";
import key from "../../../../../config/key";
import { WhatsAppGreyIcon } from "@next/components/atoms/svgs/whatsapp-grey";

export const SocialShareListing = () => {
  const [open, setOpen] = useState(false);
  const [copyValue, setCopyValue] = useState({
    copied: false,
    socialCopy: false,
  });
 const [currentUrl,setCurrentUrl] = useState("")

  const handleCopy = () => {
    setCopyValue({ ...copyValue, copied: true });
    setTimeout(() => {
      setCopyValue({ ...copyValue, copied: false });
    }, 500);
    setOpen(true);
  };
 
  useEffect(() => {
      if(window && window !== undefined){
        setCurrentUrl(window?.location?.href)
      }
  },[])
   
  return (
    <div>
      <Box
        sx={
          {
            // display: 'flex',
            // alignContent: 'space-between',
          }
        }
      >
        <ul className="socialicons">
          <li>
            <FacebookShare url={currentUrl}>
              <FacebookGreyIcon />
            </FacebookShare>
          </li>
          <li>
            <WhatsappShare url={currentUrl}>
              <WhatsAppGreyIcon />
            </WhatsappShare>
          </li>
          <li>
            <TelegramShare url={currentUrl}>
              <TelegramGreyIcon />
            </TelegramShare>
          </li>
          <li>
            <TwitterShare url={currentUrl}>
              <TwitterGreyIcon />
            </TwitterShare>
          </li>
          <li>
            <EmailShare url={currentUrl}>
              <GoogleIcon />
            </EmailShare>
          </li>
          <li>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <CopyToClipboard text={currentUrl} onCopy={() => handleCopy()}>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <LinkIcon />
                </span>
              </CopyToClipboard>
              {copyValue.copied ? (
                <span
                  style={{
                    color: "#E02A81",
                    position: "absolute",
                    top: "-14px",
                    right: "-5px",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontWeight: "600",
                  }}
                >
                  Copied.
                </span>
              ) : null}
            </Box>
          </li>
        </ul>
      </Box>
    </div>
  );
};
