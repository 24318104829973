import key from "config/key";
const getCanonicalUrl = (pathname: string) => {
  let path = pathname;
  if (path.startsWith('en-us/')) {
    path = path.replace(/^en-us\//, '');
} else if (path.startsWith('/en-us/')) {
    path = path.replace(/^\/en-us\//, '/');
} else if (path.includes('/en-us/')) {
    path = path.replace('/en-us/', '/');
}else if (path.includes('/en-us')) {
  path = path.replace('/en-us', '/');
}
  const cleanPath = path.split("#")[0].split("?")[0];
  const canonicalUrl =
    `${key.NEXT_PUBLIC_DOMAIN}` + (path === "/" ? "" : cleanPath);

  return canonicalUrl;
};
export default getCanonicalUrl;
