import React from "react";
import {
    EmailShareButton
} from "react-share";
export const EmailShare = ({
    children,
    url
}: any) => {
    return (
        <EmailShareButton
            url={url}
        >
            {children}
        </EmailShareButton>
    )
}