import React from "react";
import {
    TwitterShareButton
} from "react-share";
export const TwitterShare = ({
    children,
    url
}: any) => {
    return (
        <TwitterShareButton
            url={url}
        >
            {children}
        </TwitterShareButton>
    )
}