import * as React from "react";
import { useTranslation } from "i18n/client";
import { useGooglePlaceApi } from "@hooks";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { InputAdornment, useTheme } from "@mui/material";
import { LocationIcon } from "@components/atoms";

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export const LocationSearch = ({
  handleLocation,
  currentLocation,
  border = "none !important",
}: any) => {
  useGooglePlaceApi();
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const { t } = useTranslation("common");
  const theme = useTheme();
  React.useEffect(() => {
    setValue(currentLocation?.value);
  }, [currentLocation?.value]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  const handleSelect = (description: string | undefined) => {
    // Get latitude and longitude via utility functions
    if (description) {
      getGeocode({ address: description }).then((results) => {
        
        const { place_id } = results[0];
        const { lat, lng } = getLatLng(results[0]);
        handleLocation({
          value: description ?? "",
          lat: lat ?? "",
          lng: lng ?? "",
          placeId: place_id,
        });
      });
    } else {
      handleLocation({
        value: "",
        lat: "",
        lng: "",
        placeId: "",
      });
    }
  };
  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={t("location_partner_search.no_locations")}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        handleSelect(newValue?.description);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("location_partner_search.select_a_location")}
          fullWidth
          sx={{
            fieldset: {
              border: "none !important",
            },
            "& .MuiInputBase-root": {
              border: border,
              background: "#ffffff",
              paddingTop: "0px",
              paddingBottom: "0px",
              height: "44px",
              borderRadius: "8px",
            },
            "& .MuiAutocomplete-popupIndicator": {
              display: "none",
            },
            input: {
              "&::placeholder": {
                color: theme.palette.grey[500],
                fontSize: "16px",
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <LocationIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings || [];

        const parts = parse(
          option?.structured_formatting?.main_text,
          matches.map((match: any) => [
            match?.offset,
            match?.offset + match?.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
