"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CreateMemoryStepOne } from "../create-memory-step-one";
import { CreateMemoryStepTwo } from "../create-memory-step-two";
import { CreateMemoryStepThree } from "../create-memory-step-three";
import {
  CrossIcon,
  ButtonTexted,
  CustomTypography,
  BackIcon,
} from "@components/atoms";
import { updateMoment, updateMemoryTitle } from "@ApiReq";
import { useAppSelector } from "@hooks";
import { useRouter } from "next/navigation";
const steps = ["Select campaign settings", "Create an ad group"];

export const CreateMemory = ({
  images,
  removeImage,
  onclose,
  onUpload,
  isInvalidFile,
}: any) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [isStepOneNext, setIsStepOneNext] = React.useState(false);
  const [isStepTwoNext, setIsStepTwoNext] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [stepOneState, setStepOneState] = React.useState<any>({
    currentLocation: "",
    location: "",
    date: new Date(),
    hashTags: [],
    caption: "",
  });
  const memories: any = useAppSelector((state) => state.memories);
  const router = useRouter();
  const auth = useAppSelector((state) => state.auth);
  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0) {
      updateMoment({
        loginToken: localStorage.getItem("token") || "",
        momentId: memories.memory?.momentId,
        memoryId: memories.memory?.id,
        location: stepOneState.location,
        date: stepOneState.date,
        hashtags: stepOneState.hashTags,
        caption: stepOneState.caption,
      })
        .then((res: any) => {
          console.log("res update Moment ", res.data);
        })
        .catch((err: any) => {
          console.log("err update moment", err);
        });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleCloseModal = () => {
    onclose();
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = (e: any) => {
    e.preventDefault();
    router.push(`/m/${memories.memory?.code}`);
    setActiveStep(0);
    onclose();
  };
  const handleLocation = (val: string, description: any) => {
    setStepOneState({
      ...stepOneState,
      location: val,
      currentLocation: description,
    });
  };
  const handleDate = (val: string) => {
    setStepOneState({
      ...stepOneState,
      date: val,
    });
  };
  const handleHashTag = (val: string) => {
    setStepOneState({
      ...stepOneState,
      hashTags: val,
    });
  };
  const handleCaption = (val: string) => {
    setStepOneState({
      ...stepOneState,
      caption: val,
    });
  };
  const handleTitle = (val: string) => {
    updateMemoryTitle({
      loginToken: localStorage.getItem("token") || "",
      memoryId: memories.memory?.id,
      title: val,
    })
      .then((res: any) => {})
      .catch((err: any) => console.log("error", err));
  };
  return (
    <Box sx={{ width: "100%" }} className="createMemory">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              pt: "10px",
              pb: "10px",
              borderBottom: "1px solid rgba(16, 24, 40, 0.05)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button id="createMemory_cross" color="inherit" onClick={handleCloseModal} sx={{ mr: 1 }}>
                <CrossIcon />
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomTypography
                color="#101828"
                fontsize="14px"
                lineheight="20px"
                fontweight="500"
                textalign="center"
              >
                Create Moment
              </CustomTypography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ButtonTexted
                id="createMemory_view"
                clickHandler={handleReset}
                color="#f62e8e"
                fontsize="14px"
                lineheight="20px"
                fontweight="500"
              >
                View
              </ButtonTexted>
            </Box>
          </Box>
          <CreateMemoryStepThree />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              pt: 2,
              pb: 2,
              borderBottom: "1px solid rgba(16, 24, 40, 0.05)",
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                },
                alignItems: "center",
              }}
            >
              {activeStep == 0 ? (
                <Button
                  id="createMemory_cross_two"
                  color="inherit"
                  onClick={handleCloseModal}
                  sx={{ mr: 1 }}
                >
                  <CrossIcon />
                </Button>
              ) : (
                <Button id="createMemory_step_back" color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                  <BackIcon />
                </Button>
              )}
            </Box>
            {/* for mobile */}
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "none",
                },
                alignItems: "center",
              }}
            >
              {activeStep == 0 ? (
                !isStepOneNext ? (
                  <Button
                    id="createMemory_cross_three"
                    color="inherit"
                    onClick={handleCloseModal}
                    sx={{ mr: 1 }}
                  >
                    <CrossIcon />
                  </Button>
                ) : (
                  <Button
                    id="createMemory_step_back_two"
                    color="inherit"
                    onClick={() => setIsStepOneNext(false)}
                    sx={{ mr: 1 }}
                  >
                    <BackIcon />
                  </Button>
                )
              ) : (
                ""
              )}
              {activeStep == 1 ? (
                <Button id="createMemory_back" color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                  <BackIcon />
                </Button>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomTypography
                color="#101828"
                fontsize="14px"
                lineheight="20px"
                fontweight="500"
                textalign="center"
              >
                Create Moment
              </CustomTypography>
            </Box>
            <Box
              sx={{
                display: {
                  sx: "flex",
                  sm: "none",
                },
                alignItems: "center",
              }}
            >
              {activeStep == 0 ? (
                !isStepOneNext ? (
                  <ButtonTexted
                    id="createMemory_step_next"
                    clickHandler={() => setIsStepOneNext(true)}
                    color="#f62e8e"
                    fontsize="14px"
                    lineheight="20px"
                    fontweight="500"
                  >
                    {" "}
                    Next
                  </ButtonTexted>
                ) : (
                  <ButtonTexted
                    id="createMemory_next"
                    clickHandler={handleNext}
                    color="#f62e8e"
                    fontsize="14px"
                    lineheight="20px"
                    fontweight="500"
                  >
                    {" "}
                    Next
                  </ButtonTexted>
                )
              ) : (
                ""
              )}
              {activeStep == 1 ? (
                <ButtonTexted
                  id="createMemory_finish"
                  clickHandler={handleNext}
                  color="#f62e8e"
                  fontsize="14px"
                  lineheight="20px"
                  fontweight="500"
                >
                  {" "}
                  Finish
                </ButtonTexted>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                },
                alignItems: "center",
              }}
            >
              <ButtonTexted
              id="createMemory_finish_next"
                clickHandler={handleNext}
                color="#f62e8e"
                fontsize="14px"
                lineheight="20px"
                fontweight="500"
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </ButtonTexted>
            </Box>
          </Box>
          {activeStep === 0 && (
            <CreateMemoryStepOne
              isInvalidFile={isInvalidFile}
              isStepOneNext={isStepOneNext}
              setIsStepOneNext={() => setIsStepOneNext(true)}
              images={images}
              removeImage={removeImage}
              onUpload={onUpload}
              title={title}
              setTitle={setTitle}
              handleTitle={handleTitle}
              stepOneState={stepOneState}
              handleLocation={handleLocation}
              handleDate={handleDate}
              handleHashTag={handleHashTag}
              handleCaption={handleCaption}
            />
          )}
          {activeStep === 1 && (
            <CreateMemoryStepTwo
              isInvalidFile={isInvalidFile}
              isStepTwoNext={isStepTwoNext}
              setIsStepTwoNext={() => setIsStepTwoNext(true)}
              images={images}
              removeImage={removeImage}
              onUpload={onUpload}
            />
          )}
        </React.Fragment>
      )}
    </Box>
  );
};
