"use client";
import React, { useState, FC, useEffect } from "react";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import { SmallCard } from "@components/organisms";
import {
  CrossIcon,
  AccessDeniedIcon,
  CustomTypography,
  ButtonContained,
  ButtonOutlined,
} from "@components/atoms";
import { SignupForm } from "@components/organisms";
import { validateFile } from "@utils";
import { useAppDispatch, useAppSelector } from "@hooks";
import { addMomentAssets } from "@ApiReq";
import {
  CardContent,
  Typography,
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  IconButton,
  CardHeader,
  Avatar,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { AuthDivider, AuthSocialButtons } from "@components/molecules";
import systemConfig from "@next/config/system.config";

const BootstrapDialog = styled(Dialog)(({}) => ({
  "& .MuiDialog-container": {
    //  background:"rgba(52, 64, 84, 1)", //70%
  },
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    // padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    ["@media (max-width:700px)"]: {
      // borderRadius: "10px",
      // width: "100% !important",
      // height: "100%",
      // maxHeight: "100%",
      // margin: "0px"
      borderRadius: "10px",
      width: "400px",
    },
    ["@media (min-width:700px)"]: {
      borderRadius: "10px",
      width: "400px",
    },

    // height:"100%",
    // maxHeight:"70%"
  },

  scrollbarColor: "#6b6b6b #2b2b2b",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#eaecf0",
    width: 8,
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#eaecf0",
  },
}));

export const MemoryDetailAccessModal: FC<any> = ({ open, onclose }) => {
  const [accessDenied, setAccessDenied] = useState("default");
  const handleClose = () => {
    setAccessDenied("default");
    onclose();
  };

  const AccessDeniedCard = () => {
    return (
      <>
        <Box
          sx={{
            marginBottom: "8px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "100%",
              backgroundColor: "#fef0c7",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AccessDeniedIcon />
          </Box>
        </Box>
        <Box
          sx={{
            marginBottom: "8px",
          }}
        >
          <CustomTypography
            fontsize="18px"
            color="#101828"
            fontweight="500"
            lineheight="20px"
          >
            You are not allowed to access
          </CustomTypography>
        </Box>
        <Box
          sx={{
            marginBottom: "8px",
          }}
        >
          <CustomTypography
            fontsize="18px"
            color="#101828"
            fontweight="500"
            lineheight="20px"
          >
            this memory directly.
          </CustomTypography>
        </Box>
        <Box
          sx={{
            marginBottom: "32px",
          }}
        >
          <CustomTypography
            fontsize="14px"
            color="#667085"
            fontweight="normal"
            lineheight="18px"
          >
            Authenticate your self and request access.
          </CustomTypography>
        </Box>
        <Box
          sx={{
            marginBottom: "8px",
          }}
        >
          <ButtonContained id="accessDeniedCard_login">Log in</ButtonContained>
        </Box>
        <Box
          sx={{
            marginBottom: "8px",
          }}
        >
          <ButtonOutlined
            id="accessDeniedCard_register"
            border="1px solid #d0d5dd"
            clickHandler={() => setAccessDenied("register")}
          >
            Register
          </ButtonOutlined>
        </Box>
      </>
    );
  };
  return (
    <div>
      <BootstrapDialog
        // fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
      >
        <>
          <Card
            sx={{
              border: "1px solid #eaecf0",
              borderRadius: 0,
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{ padding: "90px 24px 0px", position: "relative" }}
            >
              <Box sx={{ position: "absolute", top: "42px", left: "42px" }}>
                <IconButton onClick={handleClose} sx={{ padding: "0px 0px" }}>
                  {<CrossIcon />}
                </IconButton>
              </Box>
              <Box>
                {accessDenied === "register" ? (
                  <Box>
                    <Box
                      sx={{
                        marginBottom: "24px",
                      }}
                    >
                      <CustomTypography
                        textalign="center"
                        fontstyle="normal"
                        fontsize="30px"
                        fontweight="600"
                        lineheight="38px"
                        color="#101828"
                      >
                        Register.Name
                      </CustomTypography>
                    </Box>
                    <SignupForm />
                    {systemConfig.AUTHENTICATION.SHOW_SOCIAL_LOGIN_SECTION && (
                      <AuthDivider />
                    )}
                    <AuthSocialButtons />
                  </Box>
                ) : accessDenied === "login" ? (
                  <></>
                ) : (
                  <AccessDeniedCard />
                )}
              </Box>
            </CardContent>
          </Card>
        </>
      </BootstrapDialog>
    </div>
  );
};
