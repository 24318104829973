import { useTranslation } from "i18n/client";

import { ButtonContained } from "@components/atoms";
import { Box } from "@mui/material";
import React, { FC, useState } from "react";
import { ExploreInvitePartnerProps } from "./explore-invite-partner.types";
import { LocationSearchAutoComplete } from "../location-search-auto-complete";
import { ExploreInviteInputLayout } from "./explore-invite-input-layout";
import { SearchUser } from "../search-user";
import systemConfig from "@next/config/system.config";
import { LocationSearch } from "../location-search";

export const ExploreInvitePartner: FC<ExploreInvitePartnerProps> = ({
  borderRadius = "16px",
  location,
  setLocation,
  users,
  setUsers,
  handleSearch,
  searchButtonId
}) => {
  const { t } = useTranslation("common");
  const handleUser = (val: string) => {
    setUsers(val);
  };
  return (
    <Box
      sx={{
        background: "#1d2939",

        padding: {
          xs: "20px",
          md: "64px",
        },
        borderRadius: borderRadius,
        display: "flex",
        alignItems: {
          md: "flex-end",
        },
        gap: "10px",
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <ExploreInviteInputLayout title={t("location_partner_search.location")}>
        <LocationSearch
          handleLocation={setLocation}
          currentLocation={location}
        />
        {/* <LocationSearchAutoComplete/> */}
      </ExploreInviteInputLayout>
      {systemConfig.SEARCH_BY_LOCATION_PARTNER.HAS_PARTNER_SEARCH && (
        <ExploreInviteInputLayout title={t("location_partner_search.search")}>
          <SearchUser users={users} handleUser={handleUser} />
        </ExploreInviteInputLayout>
      )}

      <Box
        sx={{
          flex: "0 1 auto",
          height: "48px",
        }}
      >
        <ButtonContained id={searchButtonId} clickHandler={handleSearch}>
          {t("location_partner_search.search")}
        </ButtonContained>
      </Box>
    </Box>
  );
};
