import * as React from "react";
import { Box,Typography } from "@mui/material";


export const ListWithIcon = ({ list }: any) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="15px"
      sx={{
        marginTop: {
          md: "32px",
        },
      }}
    >
      {list.map(({ Component, item, onClick }: any, index: any) => (
        <Box display="flex" flexDirection="row" key={index}>
          <Box>
            <Component />
          </Box>
          <Box sx={{ marginLeft: "12px" }} >
            <Typography
              variant="Text_smNormal"
              color="#667085"
              onClick={() => onClick && onClick()}
            >
              {item}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
