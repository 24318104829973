import { ControledInputField } from '@components/atoms'
import { InputLabel,FormControl } from '@mui/material'
import React, {FC} from 'react'
import { InputFieldWithLabelProps } from './input-field-with-label.types'
export const InputFieldWithLabel : FC<InputFieldWithLabelProps> = ({
  label,
  placeholder,
  type,
  name,
  readonly,
  onClick,
  value,
  onChange
}) => {
  return (
    <FormControl sx={{
       width:"100%"
    }}   variant="standard">
    <InputLabel  shrink  sx={{
         fontFamily: "Inter",
         fontStyle: "normal",
         fontSize: "14px",
         fontWeight: 500,
         lineHeight: "1.43",
         color: "#344054",
         position:"relative",
         '&.Mui-focused':{
           color:"#344054"
         }
    }} htmlFor={name}>{label}</InputLabel>
    <ControledInputField value={value} onchange={onChange} name={name} placeholder={placeholder} type={type} readyonly={readonly} inputFieldProps={{onClick:onClick}} />
  </FormControl>
  )
}
