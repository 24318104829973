import {
  ButtonContained
} from "@components/atoms";
import { Box } from "@mui/material";
import React, {FC} from "react";
import { InputFieldWithLabel } from "@components/molecules";
import { ResetPasswordFormProps } from "./reset-password-form.types";
export const ResetPasswordForm : FC<ResetPasswordFormProps> = ({
  onUpdate
}): JSX.Element => {
 const handlePasswordUpdate = () => {
  onUpdate();
 }
  return (
    <Box
      sx={{
        width: "100%",
        
      }}
    >
      <form>
       
        <Box
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <InputFieldWithLabel type="password" name="password" label="Password" placeholder="••••••••"/>
        </Box>
        <Box
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <InputFieldWithLabel type="password" name="confirmPassword" label="Confirm Password" placeholder="••••••••"/>
        </Box>
        <Box
          sx={{
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <ButtonContained id="resetPasswordForm_button" clickHandler={handlePasswordUpdate} sizeVariant="large">Reset password</ButtonContained>
        </Box>
      </form>
    </Box>
  );
};
