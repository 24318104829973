"use client";

import React, { useState } from "react";
import { useParams, usePathname, useRouter } from "next/navigation";

import { Badge, Box } from "@mui/material";
import { CustomDialog } from "@components/organisms";
import { UsersSVG, PeaceIcon, HomeIcon, ExploreIcon } from "@components/atoms";
import { styled } from "@mui/system";
import { createMemory } from "@store";
import { useAppDispatch } from "@hooks";
import { NavItemLink, NavItemLinkStyle, NavItemWrapper } from "./private-navbar-items.styles";
import systemConfig from "@next/config/system.config";
import { NavItemName } from "../navbar-item-name";
import { CustomLink } from "../custom-link";

const CustomBadge = styled(Badge)({
  span: {
    top: "4px",
    right: "-4px",
    background: "#f62e8e",
  },
});

export const PrivateNavbarItems = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const params = useParams<{ lng: string }>(); 
  const pathname = usePathname();
  const currentPath = pathname.substring(pathname.indexOf("/") + 1);

  const isActivePath = (val: string) => {
    if (currentPath.includes(val)) {
      return true;
    } else false;
  };

  const handleClick = () => {
    dispatch(
      createMemory({
        loginToken: localStorage.getItem("token") || "",
      })
    );
    setOpen(true);
  };

  const isExploreActive = () => {
    const paths = ["explore", "experiences", "activities"];
    let isActive = false;
    paths.forEach((path) => {
      if (currentPath.includes(path)) {
        isActive = true;
      }
    });
    return isActive;
  };
 
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <CustomLink
          href="/"
          locale={params.lng}
          style={{
            ...NavItemLinkStyle
          }}
         
        >
          <HomeIcon isActive={isActivePath("home")} />
          <NavItemName name="Home" isActive={isActivePath("home")} />
        </CustomLink>
        <CustomLink
          href="/explore"
          locale={params.lng}
          style={{
            ...NavItemLinkStyle
          }}
        >
          <ExploreIcon fill={isExploreActive()} />
          <NavItemName name="Explore" isActive={isExploreActive()} />
        </CustomLink>

        {systemConfig.NAVBAR.NAV_ITEM.HAS_CREATE_MEMORY && (
          <Box onClick={() => handleClick()}>
            <NavItemWrapper>
              <CustomBadge badgeContent={4} color="primary">
                <PeaceIcon fill={open} />
              </CustomBadge>
              {open && (
                <Box
                  sx={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "100%",
                    background: "#f62e8e",
                    position: "absolute",
                    left: "50%",
                    bottom: "-8px",
                  }}
                />
              )}
            </NavItemWrapper>
          </Box>
        )}
        {systemConfig.NAVBAR.NAV_ITEM.HAS_CONTACTS && (
          <NavItemLink
            href="/my-contacts" //onClick={() => router.push("/my-contacts")}
          >
            <UsersSVG />
          </NavItemLink>
        )}
      </Box>
      <CustomDialog open={open} onclose={() => setOpen(false)} />
    </>
  );
};
