import {  List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React, {FC} from 'react'
import {ProfileAvatar} from "@components/atoms";
import { useAppSelector } from '@hooks';
import { styled } from '@mui/system';
import { NavAccountProfileUserInfoProps } from './nav-account-profile-user-info.types';
const CustomListItemText = styled(ListItemText)`
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }
`;
export const NavAccountProfileUserInfo :FC<NavAccountProfileUserInfoProps> = ({
  image,
   name,
    username,
    email
}) => {
    // const { user } = useAppSelector((state) => state.auth);
  return (
    <List>
    <ListItem sx={{
      padding:"0px"
    }} button>
          <ListItemAvatar>
              <ProfileAvatar name={name} imageURL={image}/>
          </ListItemAvatar>
          <CustomListItemText primary={name} secondary={email || username} />
        </ListItem>
    </List>
  )
}
