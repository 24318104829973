import { Box } from '@mui/material'
import React from 'react'
import { NavAccountProfile } from '../nav-account-profile';
import { NavAccountSearch } from '../nav-account-search';
import {SearchAutoComplete} from "../search-auto-complete";
import {MemorySearchAutoComplete} from "../memory-search-auto-complete";
import systemConfig from '@next/config/system.config';
export const NavAccount = () => {
  return (
    <Box sx={{
        display:"flex",
        width:"100%"
    }}>
       {systemConfig.NAVBAR.HAS_SEARCH && <Box sx={{
            marginRight:"25px",
            width:"100%",
            display:"flex",
            justifyContent:"flex-end"
        }}>
        {/* <NavAccountSearch/> */}
        <SearchAutoComplete/>
        </Box>}
        <Box sx={{
            marginRight:"10px",
            marginLeft:"auto"
        }}>
        <NavAccountProfile/>
            </Box>
       
        
    </Box>
  )
}
