import React from 'react'

export const TwitterGreyIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.55 21.75c9.055 0 14.008-7.503 14.008-14.007 0-.211-.004-.427-.014-.638A9.999 9.999 0 0 0 24 4.556c-.898.4-1.85.66-2.826.773a4.95 4.95 0 0 0 2.165-2.722A9.897 9.897 0 0 1 20.213 3.8a4.93 4.93 0 0 0-8.394 4.49 13.985 13.985 0 0 1-10.146-5.14 4.93 4.93 0 0 0 1.523 6.57 4.93 4.93 0 0 1-2.23-.614v.06a4.922 4.922 0 0 0 3.95 4.829 4.894 4.894 0 0 1-2.221.084 4.934 4.934 0 0 0 4.597 3.422A9.875 9.875 0 0 1 0 19.539a13.969 13.969 0 0 0 7.55 2.212z" fill="#98A2B3"/>
</svg>


  )
}
