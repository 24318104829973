import React from 'react'
import MuiSnackbar from '@mui/material/Snackbar';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Alert } from '@mui/material';
import { globalActions } from '@store';
const Snackbar = () => {
    const {open,type,message} = useAppSelector(state => state.global);
    const dispatch = useAppDispatch()
    const handleClose = () => {
        dispatch(globalActions.closeSnackbar())
    }
  return (
    <MuiSnackbar  open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical:"top", horizontal:"right" }} >
        <Alert variant='filled' icon={false} onClose={handleClose} severity={type ?? "success"} sx={{
            width: '300px',
            color:"#fff",
            "&.MuiAlert-action":{
                svg:{
                    fill:"#fff !important"
                }
            }
        }}>
           { message}
        </Alert>
      </MuiSnackbar>
  )
}
export default Snackbar