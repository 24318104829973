"use client";

import React, { useState } from "react";
import { useTranslation } from "i18n/client";

import { Image } from "@components/atoms";
import {
  SuggestActivityModal,
  SuggestExperienceModal,
} from "@components/molecules";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import systemConfig from "@next/config/system.config";

const ContributeAndImproveSection = () => {
  const [openSuggestExp, setOpenSuggestExp] = useState(false);
  const [openSuggestActivity, setOpenSuggestActivity] = useState(false);

  const handleOpenSuggestExp = () => {
    setOpenSuggestExp(true);
  };
  const handleCloseSuggestExp = () => {
    setOpenSuggestExp(false);
  };
  const handleOpenSuggestActivity = () => {
    setOpenSuggestActivity(true);
  };
  const handleCloseSuggestActivity = () => {
    setOpenSuggestActivity(false);
  };
  const theme = useTheme();
  const { t } = useTranslation("landing-page");
  const {
    LANDING_PAGE: {
      CONTRIBUTE_AND_IMPROVE: {
        SHOW_BUTTONS,
        SHOW_PROPOSE_AN_EXPERIENCE,
        SHOW_PROPOSE_A_PARTNER,
      },
    },
  } = systemConfig;
  return (
    <Grid
      container
      sx={{
        padding: { xs: "64px 16px", sm: "96px 80px" },
        rowGap: { xs: "64px", sm: "96px" },
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            maxWidth: "935px",
            margin: "auto",
          }}
        >
          <Typography
            variant="Text_mdSemibold"
            component="p"
            sx={{
              color: theme.palette.primary.dark,
              textAlign: "center",
            }}
          >
            {t("contribute_and_improve.title")}
          </Typography>
          <Typography
            variant="Display_lgSemibold"
            component="h2"
            sx={{
              color: theme.palette.grey[900],
              textAlign: "center",
              marginTop: "12px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "32px",
                lineHeight: "44px",
              },
            }}
          >
            {t("contribute_and_improve.heading")}
          </Typography>
          <Typography
            variant="Text_lgNormal"
            component="p"
            sx={{
              color: theme.palette.grey[500],
              textAlign: "center",
              marginTop: "24px",
              maxWidth: "768px",
              marginLeft: "auto",
              marginRight: "auto",
              [theme.breakpoints.down("sm")]: {
                fontSize: "18px",
                lineHeight: "28px",
                maxWidth: "100%",
              },
            }}
          >
            {t("contribute_and_improve.subtitle")}
          </Typography>
          {SHOW_BUTTONS && (
            <Box
              sx={{
                columnGap: "24px",
                rowGap: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: { xs: "column", sm: "row" },
                marginTop: { xs: "64px", sm: "32px" },
              }}
            >
              {SHOW_PROPOSE_AN_EXPERIENCE && (
                <Button
                id="landingPage_contribute_and_improve_propose_an_experience"
                  onClick={handleOpenSuggestExp}
                  variant="outlined"
                  sx={{
                    width: "100%",
                    maxWidth: { xs: "100%", sm: "254px" },
                    height: "60px",
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "28px",
                    textTransform: "unset",
                    padding: "0px",
                    border: `1px solid ${theme.palette.grey[300]}`,
                    color: theme.palette.grey[700],
                  }}
                >
                  {t("contribute_and_improve.propose_an_experience")}
                </Button>
              )}
              {SHOW_PROPOSE_A_PARTNER && (
                <Button
                  id="landingPage_contribute_and_improve_propose_a_partner"
                  onClick={handleOpenSuggestActivity}
                  variant="contained"
                  sx={{
                    width: "100%",
                    maxWidth: { xs: "100%", sm: "191px" },
                    height: "60px",
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "28px",
                    textTransform: "unset",
                    padding: "0px",
                  }}
                >
                  {t("contribute_and_improve.propose_a_partner")}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
           src={t("assets.images.image_seven.file")}
            alt={t("assets.images.image_seven.alt")}
            title={t("assets.images.image_seven.title")}
            width={1024}
            height={488}
          
          />
        </Box>
      </Grid>
      <SuggestExperienceModal
        open={openSuggestExp}
        onClose={handleCloseSuggestExp}
      />
      <SuggestActivityModal
        open={openSuggestActivity}
        onClose={handleCloseSuggestActivity}
      />
    </Grid>
  );
};
export default ContributeAndImproveSection;
