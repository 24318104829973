"use client";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import styled from "styled-components"

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
const CustomTab = {
  "&.MuiTab-root": {
    color: "#98a2b3",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
  },
  "&.Mui-selected": {
    color: "#101828",
  },
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const CustomTabs = ({ tabsData, border }: any) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box
      sx={{
        bgcolor: "trasparent",
        width: "100%",
        border: border || "1px solid #eaecf0",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: "#f858a5" } }}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {tabsData.map((tabItem: any, i: any) => {
            return (
              <Tab
                component="h3"
                key={i}
                label={tabItem.label}
                {...a11yProps(i)}
                sx={{ ...CustomTab }}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabsData.map(({ Component }: any, i: any) => {
        return (
          <TabPanel key={i} value={value} index={i}>
            {Component}
          </TabPanel>
        );
      })}
    </Box>
  );
};
