"use client";

import React, { FC } from "react";
import { Control } from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
interface IPhoneInputWithCountrySelect {
  name: string;
  control: Control;
}
export const PhoneInputWithCountrySelect: FC<IPhoneInputWithCountrySelect> = ({
  name,
  control,
}) => {
  return (
    <PhoneInputWithCountry
      name={name}
      control={control}
      rules={{ required: true }}
      defaultCountry="US"
      countrySelectProps={{ unicodeFlags: true }}
      international={true}
    />
  );
};
