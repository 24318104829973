"use client";

import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useRouter } from "next/navigation";
import { CustomTypography } from "@components/atoms";
import { CustomDatePicker } from "@components/atoms";
import { styled } from "@mui/system";
import { TagsInput, WhiteCrossIcon } from "@components/atoms";
import { LocationSearchAutoComplete } from "../location-search-auto-complete";
import { CarouselGrid } from "../carousel-grid";
import { ImageUploader } from "../image-uploader";

const CustomImage = styled(Box)`
  background-position: center;
  background-size: cover;
  height: ${(props: any) => props.height};
  max-width: 100%;
`;
const DeleteImage = styled(Box)`
  width: ${(props: any) => props.width};
  height: ${(props: any) => props.height};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 10px;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.7);
`;
import style from "./create-memory-step-one.style.module.css";
import { InputField } from "@next/components/atoms/custom-input-field/custom-input-field.style";
export const CreateMemoryStepOne = ({
  images,
  removeImage,
  isStepOneNext,
  setIsStepOneNext,
  onUpload,
  isInvalidFile,
  stepOneState,
  handleLocation,
  handleDate,
  handleHashTag,
  handleCaption,
  title,
  setTitle,
  handleTitle,
}: any) => {
  const router = useRouter();
  const [showSingleImage, setShowSingleImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [gallery, setGallery] = useState([]);
  useEffect(() => {
    setGallery(images);
  }, [images]);
  const loginRedirect = () => {
    router.push("/login");
  };
  const handleSelectedImage = (image: any) => {
    setShowSingleImage(true);
    setSelectedImage(image);
  };
  const restructureCarouselImages = () => {
    const newArray = [...gallery];
    const fromIndex = newArray.findIndex((object: any) => {
      return object.id === selectedImage.id;
    });

    if (fromIndex >= 0) {
      const element = newArray.splice(fromIndex, 1)[0];
      newArray.splice(0, 0, element);
      return newArray;
    }
    return newArray;
  };
  const handleRemoveImage = (val: any) => {
    if (images.length == 1) {
      setShowSingleImage(false);
      setSelectedImage({});
    }
    removeImage(val);
  };
  return (
    <Grid container>
      <Grid
        item
        sx={{
          width: {
            xs: "100%",
            sm: "60%",
          },
        }}
      >
        {images.length > 0 ? (
          <Box
            sx={{
              padding: "10px 0px 0px 0px",
            }}
          >
            {/* <CarouselGrid/> */}
            <Grid
              container
              className={style?.mainContainer}
              spacing={1}
              sx={{
                height: "100%",
                maxHeight: "566px",
                overflow: "scroll",
                overflowX: "hidden",
                display: {
                  xs: "none",
                  sm: "flex",
                },
              }}
            >
              {!showSingleImage ? (
                images.map((image: any, i: any) => {
                  return (
                    <Grid key={i} item xs={6} style={{ position: "relative" }}>
                      <CustomImage
                        onClick={() => handleSelectedImage(image)}
                        height="283px"
                        style={{ backgroundImage: `url(${image.file})` }}
                      ></CustomImage>
                      <DeleteImage width="36px" height="36px">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => handleRemoveImage(image.id)}
                        >
                          <WhiteCrossIcon />
                        </Box>
                      </DeleteImage>
                    </Grid>
                  );
                })
              ) : (
                <Grid xs={12} item style={{ position: "relative" }}>
                  <CarouselGrid
                    images={restructureCarouselImages()}
                    removeImage={handleRemoveImage}
                    gridView={() => setShowSingleImage(false)}
                  />
                  {/* <CustomImage height="566px"  style={{backgroundImage:`url(${selectedImage})`}}></CustomImage>
                    <DeleteImage>
                         <Box onClick={()=> setShowSingleImage(false)}>
                         <WhiteCrossIcon/>
                         </Box>
                        
                        </DeleteImage> */}
                </Grid>
              )}
            </Grid>

            <Grid
              container
              spacing={1}
              sx={{
                height: "100%",
                maxHeight: "566px",
                overflow: "scroll",
                scrollBehaviorX: "none",
                display: {
                  xs: !isStepOneNext ? "flex" : "none",
                  sm: "none",
                },
              }}
            >
              {!showSingleImage ? (
                images.map((image: any, i: any) => {
                  return (
                    <Grid key={i} item xs={4} style={{ position: "relative" }}>
                      <CustomImage
                        onClick={() => handleSelectedImage(image)}
                        height="118px"
                        style={{ backgroundImage: `url(${image.file})` }}
                      ></CustomImage>
                      <DeleteImage width="20px" height="20px">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => handleRemoveImage(image.id)}
                        >
                          <WhiteCrossIcon />
                        </Box>
                      </DeleteImage>
                    </Grid>
                  );
                })
              ) : (
                <Grid xs={12} item>
                  <Box position="relative">
                    <CarouselGrid
                      images={restructureCarouselImages()}
                      removeImage={handleRemoveImage}
                      gridView={() => setShowSingleImage(false)}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              sx={{
                display: {
                  xs: !isStepOneNext ? "block" : "none",
                  sm: "none",
                },
                width: "100%",
              }}
            >
              <Grid item>
                <Box
                  position="relative"
                  sx={{
                    width: "100%",
                    mt: "20px",
                  }}
                >
                  <ImageUploader
                    isButton={true}
                    onUpload={onUpload}
                    padding="0px 20px"
                  />
                </Box>
                {isInvalidFile && (
                  <Box
                    sx={{
                      marginTop: "5px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CustomTypography
                      fontsize="14px"
                      color="#d92d20"
                      fontweight="normal"
                      textalign="left"
                    >
                      *.png, *.jpg, *.heic supported.
                    </CustomTypography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            position="relative"
            sx={{
              width: "100%",
              mt: "20px",
            }}
          >
            <ImageUploader
              isImage={true}
              onUpload={onUpload}
              padding="0px 20px"
            />
          </Box>
        )}
      </Grid>
      <Grid
        item
        sx={{
          display: {
            xs: !isStepOneNext ? "none" : "block",
            sm: "block",
          },
          width: {
            xs: "100%",
            sm: "40%",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "10px 24px",
          }}
        >
          {isInvalidFile && (
            <Box
              sx={{
                marginBottom: "18px",
              }}
            >
              <CustomTypography
                fontsize="14px"
                color="#d92d20"
                fontweight="normal"
                textalign="left"
              >
                *.png, *.jpg, *.heic supported.
              </CustomTypography>
            </Box>
          )}
          <Box
            sx={{
              marginBottom: "18px",
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          >
            <ImageUploader isButton={true} onUpload={onUpload} />
          </Box>
          <Box
            sx={{
              marginBottom: "18px",
            }}
          >
            <InputField
              value={title}
              onChange={(e: any) => setTitle(e.target.value)}
              onBlur={() => handleTitle(title)}
              fullWidth
              placeholder="title"
            />
          </Box>
          <Box
            sx={{
              marginBottom: "18px",
            }}
          >
            <LocationSearchAutoComplete
              handleLocation={handleLocation}
              currentLocation={stepOneState.currentLocation}
            />
          </Box>
          <Box
            sx={{
              marginBottom: "18px",
            }}
          >
            <CustomDatePicker
              handleDate={handleDate}
              date={stepOneState.date}
            />
          </Box>
          <Box
            sx={{
              marginBottom: "18px",
            }}
          >
            <TagsInput
              handleHashTag={handleHashTag}
              hashTags={stepOneState.hashTags}
            />
          </Box>
          <Box>
            <CustomTypography fontsize="14px" color="#344054" textalign="left">
              Caption
            </CustomTypography>
            <TextareaAutosize
              value={stepOneState.caption}
              onChange={(e) => handleCaption(e.target.value)}
              aria-label="minimum height"
              placeholder="Enter a description..."
              style={{
                width: "100%",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "16px",
                fontWeight: "normal",
                lineHeight: "20px",
                color: "#101828",
                border: "1px solid rgba(16, 24, 40, 0.1)",
                boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
                borderRadius: "8px",
                padding: "10px 14px",
                height: "160px",
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
