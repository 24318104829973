import React from "react";
import {
    WhatsappShareButton
} from "react-share";
export const WhatsappShare = ({
    children,
    url
}: any) => {
    return (
        <WhatsappShareButton
            url={url}
        >
            {children}
        </WhatsappShareButton>
    )
}