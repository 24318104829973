import { InputLabel } from '@mui/material'
import React from 'react'
interface ILabel {
    name:string
    label :string
}
export const Label = ({
    name,
    label
}:ILabel) => {
  return (
    <InputLabel shrink  sx={{
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "1.43",
        color: "#344054",
        position:"relative",
        '&.Mui-focused':{
          color:"#344054"
        }
   }} htmlFor={name}>{label}</InputLabel>
  )
}
