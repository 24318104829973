import React from 'react'

export const CopyIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_394_4354)">
<path d="M3.33334 10.5H2.66668C2.31305 10.5 1.97392 10.3596 1.72387 10.1095C1.47382 9.85947 1.33334 9.52033 1.33334 9.16671V3.16671C1.33334 2.81309 1.47382 2.47395 1.72387 2.2239C1.97392 1.97385 2.31305 1.83337 2.66668 1.83337H8.66668C9.0203 1.83337 9.35944 1.97385 9.60949 2.2239C9.85953 2.47395 10 2.81309 10 3.16671V3.83337M7.33334 6.50004H13.3333C14.0697 6.50004 14.6667 7.09699 14.6667 7.83337V13.8334C14.6667 14.5698 14.0697 15.1667 13.3333 15.1667H7.33334C6.59696 15.1667 6.00001 14.5698 6.00001 13.8334V7.83337C6.00001 7.09699 6.59696 6.50004 7.33334 6.50004Z" stroke="#D0D5DD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_394_4354">
<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
  )
}
