import React from 'react'

export const LocationIcon = () => {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 8.33331C16 14.1666 8.5 19.1666 8.5 19.1666C8.5 19.1666 1 14.1666 1 8.33331C1 6.34419 1.79018 4.43653 3.1967 3.03001C4.60322 1.62349 6.51088 0.833313 8.5 0.833313C10.4891 0.833313 12.3968 1.62349 13.8033 3.03001C15.2098 4.43653 16 6.34419 16 8.33331Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.5 10.8333C9.88071 10.8333 11 9.71402 11 8.33331C11 6.9526 9.88071 5.83331 8.5 5.83331C7.11929 5.83331 6 6.9526 6 8.33331C6 9.71402 7.11929 10.8333 8.5 10.8333Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}
