import { MenuItem } from '@mui/material'
import React, {FC} from 'react'
import { CustomMenuItemProps } from './custom-menu-item.types'
export const CustomMenuItem : FC<CustomMenuItemProps> =({
    handleClick,
    children,
    fontsize,
    fontweight,
    fontstyle,
    lineheight,
    color,
    padding,
    height

}) : JSX.Element => {
  return <MenuItem sx={{
    fontSize: fontsize || "16px",
    fontStyle:fontstyle || "normal",
    fontFamily: 'Inter',
    lineHeight: lineheight || "24px",
    fontWeight: fontweight || "500",
    color: color || "#98A2B3",
    height:height || "24px",
    padding: padding || "10px 16px",
}} onClick={handleClick}>{children}</MenuItem>
}
