import { Box } from '@mui/material'
import React, { FC, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { ButtonContained, ImageUploadSVG, CustomTypography } from '@components/atoms'
import { useAppSelector } from '@hooks'
export const ImageUploader: FC<any> = ({
  onUpload,
  isButton = false,
  isImage = false,
  padding,
  isInvalidFile
}) => {
  const memories: any = useAppSelector(state => state.memories)
  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
     console.log("file droped",acceptedFiles)
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (e: any) => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result

        onUpload(file, memories.memory)
      }
      reader.readAsArrayBuffer(file,)
    })
    // onUpload()
  }, [memories.memory])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  // ,accept: {
  //   'image/png': ['.png'],
  //   'image/jpg': ['.jpg'],
  // }
  return (
    <div>
    <div {...getRootProps()} style={{height:!isButton?"500px":""}}>
      <input {...getInputProps()} />
      {
        isDragActive ?
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height:"100%"
         
        }}>
 <p style={{
         
           textAlign:"center"}}>Drop the files here ...</p>
        </Box>
        
       
          :
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height:"100%"
            // padding: !isButton ? "180px 0px" : "0px"
          }}>
            {
            isButton ?
              <Box sx={{
                width: "100%",
                padding: padding || "0px"
              }}>
                <ButtonContained id="image_uploader_select_file" sizeVariant='large' radius="0px" >Select from computer</ButtonContained>
              </Box>
              : isImage ?
                <>
                  <Box sx={{
                    marginBottom: "36px"
                  }}>
                    <ImageUploadSVG />
                  </Box>
                  <Box sx={{
                    marginBottom: "36px"
                  }}>
                    <CustomTypography
                      fontstyle="normal"
                      fontsize="16px"
                      fontweight="400"
                      lineheight="24px"
                      color="#262626"
                    >Drag photos and videos here</CustomTypography>
                  </Box>
                </>
                :
                <>
                 <Box sx={{
                  marginBottom: "36px"
                }}>
                  <ImageUploadSVG />
                </Box>
                  <Box sx={{
                    marginBottom: "36px"
                  }}>
                    <CustomTypography
                      fontstyle="normal"
                      fontsize="16px"
                      fontweight="400"
                      lineheight="24px"
                      color="#262626"
                    >Drag photos and videos here</CustomTypography>
                  </Box>

                  <Box>
                    <ButtonContained id="image_uploader_select_file" sizeVariant='large' radius="0px" >Select from computer</ButtonContained>
                  </Box>
                  {isInvalidFile && <Box
                    sx={{
                      marginTop: "18px",
                    }}
                  >
                    <CustomTypography fontsize="14px" color="#d92d20" fontweight="normal" textalign="left">
                      *.png, *.jpg, *.heic supported.
                    </CustomTypography>
                  </Box>}
                </>

            }

          </Box>

      }
    </div>
    </div>
  )
}