import React from "react";

export const FeaturedIconThree = () => {
  return (
    <svg
      width="102"
      height="101"
      viewBox="0 0 102 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="7.19971" width="86" height="86" rx="43" fill="#FCBEDC" />
      <rect
        x="8"
        y="7.19971"
        width="86"
        height="86"
        rx="43"
        stroke="#FEEAF4"
        strokeWidth="14.3333"
      />
      <path
        d="M50.7393 69.7166C47.7611 69.7166 45.1152 69.2059 42.8016 68.1844C40.5003 67.1507 38.6913 65.7293 37.3745 63.9203C36.0577 62.1112 35.3932 60.0314 35.3809 57.6809H45.7182C45.7306 58.407 45.9521 59.0592 46.3828 59.6376C46.8258 60.2037 47.4288 60.6468 48.1918 60.9667C48.9548 61.2867 49.8286 61.4467 50.8131 61.4467C51.7607 61.4467 52.5975 61.2805 53.3236 60.9483C54.0497 60.6037 54.6158 60.1299 55.0219 59.5269C55.428 58.9239 55.6249 58.2347 55.6126 57.4594C55.6249 56.6964 55.3911 56.0196 54.9111 55.4288C54.4435 54.8381 53.7851 54.3767 52.936 54.0444C52.0868 53.7121 51.1085 53.546 50.0009 53.546H46.1613V46.4575H50.0009C51.0223 46.4575 51.9207 46.2913 52.696 45.9591C53.4836 45.6268 54.0928 45.1653 54.5235 44.5746C54.9665 43.9839 55.1819 43.307 55.1696 42.5441C55.1819 41.8057 55.0034 41.1534 54.6342 40.5873C54.265 40.0212 53.7482 39.5782 53.0836 39.2582C52.4314 38.9383 51.6745 38.7783 50.8131 38.7783C49.8778 38.7783 49.041 38.9444 48.3026 39.2767C47.5765 39.609 47.0043 40.0705 46.5858 40.6612C46.1674 41.2519 45.9521 41.9287 45.9398 42.6917H36.1193C36.1316 40.3781 36.7653 38.3353 38.0206 36.5631C39.2882 34.791 41.0234 33.4004 43.2262 32.3913C45.429 31.3821 47.958 30.8776 50.8131 30.8776C53.6066 30.8776 56.0741 31.3514 58.2154 32.299C60.369 33.2466 62.0488 34.551 63.2549 36.2124C64.4732 37.8615 65.0762 39.7505 65.0639 41.8795C65.0885 43.9962 64.3624 45.7314 62.8857 47.0851C61.4212 48.4388 59.5629 49.2387 57.3109 49.4849V49.7802C60.3629 50.1125 62.658 51.0478 64.1963 52.5861C65.7346 54.1121 66.4914 56.0319 66.4668 58.3455C66.4791 60.5606 65.8146 62.5235 64.4732 64.2341C63.1441 65.9447 61.292 67.2861 58.9169 68.2583C56.554 69.2305 53.8282 69.7166 50.7393 69.7166Z"
        fill="#FEEAF4"
      />
    </svg>
  );
};
