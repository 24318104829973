import React, {FC} from 'react'
import {Button} from "@mui/material";
import { ButtonTextedProps } from './button-texted.types';
export const ButtonTexted : FC<ButtonTextedProps> = ({
    children,
    sizeVariant = "medium",
    fontsize,
  fontweight,
  fontstyle,
  lineheight,
  color,
  bg,
  border,
  ptb,
  plr,
  boxshadow,
  clickHandler,
  id
}) : JSX.Element =>  {
  return (
    <Button id={id} size={sizeVariant} variant="outlined"
    sx={{
        border:"none",
        width:"100%",
        fontFamily:"Inter",
        fontStyle: fontstyle || "normal",
        fontSize: fontsize || "16px",
        fontWeight: fontweight || "500",
        lineHeight: lineheight || "24px",
        color: color || "#000000",
        textTransform:"none",
        "&:hover":{
         color:color || "#000000",
         border:"none",
         background:"none"
        }
      }}
      onClick={clickHandler}
      >{children}</Button>
  )
}
