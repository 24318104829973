import { environment, interests } from "@config";
import axios from "axios";
export const getUserInterestByExperienceId = async ({
  experienceId,
  accountId,
  locale,
}: {
  experienceId: string;
  accountId: string;
  locale: string;
}) => {
  let baseUrl = environment.apiKey;
  baseUrl = `${baseUrl}${interests.FETCH_USER_INTERETS_BY_EXPERIENCE_ID.url}/${experienceId}?account-id=${accountId}`;

  return axios
    .get(baseUrl, {
      headers: {
        "Accept-Language": locale,
      },
    })
    .then((res) => Promise.resolve(res?.data))
    .catch((err) => Promise.reject(err));
};

export const deleteInterest = async ({
    interestId,
    locale,
  }: {
    interestId: string;
    locale: string;
  }) => {
    let baseUrl = environment.apiKey;
    baseUrl = `${baseUrl}${interests.DELETE_INTEREST.url}/${interestId}`;
  
    return axios
      .delete(baseUrl, {
        headers: {
          "Accept-Language": locale,
        },
      })
      .then((res) => Promise.resolve(res?.data))
      .catch((err) => Promise.reject(err));
  };
 
  export const updateInterest = async ({
    interestId,
    locale,
    data
  }: {
    interestId: string;
    locale: string;
    data:{
        interestLevel: number;
        isOnBucketList: boolean;
        isPersonalPassion: boolean
    }
  }) => {
    let baseUrl = environment.apiKey;
    baseUrl = `${baseUrl}${interests.UPDATE_INTEREST.url}/${interestId}`;
  
    return await axios
      .put(baseUrl,{...data}, {
        headers: {
          "Accept-Language": locale,
        },
      })
      .then((res) => Promise.resolve(res?.data))
      .catch((err) => Promise.reject(err));
  };