import { Box, styled } from "@mui/material";
import Link from "next/link";

export const NavItemLink = styled(Link)({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  cursor: "pointer",
  flexDirection: "column",
  alignItems: "center",
  WebkitTapHighlightColor: 'transparent',
  touchAction: 'manipulation',
  backgroundColor: 'transparent !important',
  WebkitUserSelect: 'none',
});
export const NavItemLinkStyle = {
  display: "flex",
  justifyContent: "center",
  position: "relative",
  cursor: "pointer",
  flexDirection: "column",
  alignItems: "center",
  WebkitTapHighlightColor: 'transparent',
  touchAction: 'manipulation',
  backgroundColor: 'transparent !important',
  WebkitUserSelect: 'none',
};
export const NavItemWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  cursor: "pointer",
  flexDirection: "column",
  alignItems: "center",
});
