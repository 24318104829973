import React, { useState, FC, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Dialog as MuiDialog } from '@mui/material';
import { CrossIcon } from '@components/atoms';




const BootstrapDialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        //  background:"rgba(52, 64, 84, 1)", //70%
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),

    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        ['@media (max-width:700px)']: {
            borderRadius: "0px",
            width: "400px !important",
            height: "100%",
            maxHeight: "100%",
            margin: "0px"
        },
        ['@media (min-width:700px)']: {
            borderRadius: "10px",
            width: '400px'
        }


    },

    scrollbarColor: "#6b6b6b #2b2b2b",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "#fff",
        width: "8px"
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: "#eaecf0",
        width: 8
    },
    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
        backgroundColor: "#eaecf0",
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
        backgroundColor: "#eaecf0",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#eaecf0",
    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
        backgroundColor: "#eaecf0",
    },

}));



export const Dialog: FC<any> = ({
    open,
    onClose,
    children,
    paddingLeft="10px"
}) => {

    return (
        <div>
            <BootstrapDialog
                // fullWidth={true}
                maxWidth="md"
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                disableScrollLock={true}
            >
                <Box sx={{
                    paddingLeft:{paddingLeft}
                }}>
                    <IconButton onClick={onClose}>
                         <CrossIcon/>
                    </IconButton>
                </Box>
             {children}
            </BootstrapDialog>
        </div>
    );
}
