import { StaticCalendar } from "@components/molecules";
// import {  InputFieldWithLabel } from "@components/molecules";
import { Button, Divider, Grid, Stack, useTheme } from "@mui/material";
// import { Box, Typography } from "@mui/material";
// import { Dialog } from "@next/components/molecules/dialog";
import dynamic from "next/dynamic";
const Dialog = dynamic(
  () => import("@next/components/molecules/dialog").then((mod) => mod.Dialog),
  {
    loading: () => <p>Loading...</p>,
    ssr: false,
  }
);
import React, { FC } from "react";
interface ISelectDateModal {
  open: boolean;
  onClose: () => void;
}
export const SelectDateModal: FC<ISelectDateModal> = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={onClose} paddingLeft="24px">
      <Grid container px="24px">
        <Grid item xs={12}>
          <StaticCalendar />
          <Divider
            sx={{
              background: theme.palette.grey[200],
              marginTop: "10px",
            }}
          />
          <Stack
            flexDirection="row"
            sx={{
              columnGap: "12px",
              mt: "16px",
            }}
          >
            <Button
              id="settings_select_date_modal_cancel_btn"
              variant="outlined"
              sx={{
                width: "100%",
                textTransform: "unset",
                border: `1px solid ${theme.palette.grey[300]}`,
                color: theme.palette.grey[700],
              }}
            >
              Cancel
            </Button>
            <Button
               id="settings_select_date_modal_apply_btn"
              variant="contained"
              sx={{ width: "100%", textTransform: "unset" }}
            >
              Apply
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Dialog>
  );
};
