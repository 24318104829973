"use client";

// Npm imports
import React, { useState, FC } from "react";

// TS imports
import { MemoryTimerProps } from "./memory-timer.types";

// MUI imports
import { Typography, Box } from "@mui/material";

// Component imports
import {
  WarningIcon,
  ModalMakeitPersonalComponent,
  MemoryDetailAccessModal,
} from "@components/atoms";
import { useAppSelector } from "@hooks";

export const MemoryTimer: FC<MemoryTimerProps> = ({
  textAlign,
  justifyContent,
  marginTop,
}: any) => {
  const [optionsmodalopen, setOptionsModalOpen] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const { loggedIn } = useAppSelector((state) => state.auth);
  const handleClickMoreOptions = () => {
    setOptionsModalOpen(true);
  };
  return (
    <>
      <div
        className="right-layout-timer"
        style={{
          cursor: "pointer",
          margin: "0 10px",
          textAlign: textAlign || "start",
        }}
      >
        <Box
          sx={{
            marginTop: marginTop || "80px",
            display: "flex",
            justifyContent: {
              xs: justifyContent || "center",
              sm: justifyContent || "start",
            },
            textAlign: "center",
          }}
        >
          <span className="warning">
            <WarningIcon /> &nbsp;
          </span>

          <Typography
            variant="h6"
            sx={{
              fontFamily: "Inter",
              fontSize: 16,
              fontWeight: 500,
              color: "#101828",
              marginBottom: "0px",
            }}
            color="text.secondary"
            gutterBottom
          >
            23:59 h
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ paddingBottom: "10px" }}>
          The availability of your time is limited
        </Typography>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#e02a81",
              fontWeight: 500,
              fontSize: "14px",
              marginBottom: "10px",
            }}
            onClick={() => handleClickMoreOptions()}
          >
            Make it personal
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#e02a81",
              fontWeight: 500,
              fontSize: "14px",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
            onClick={() => setOpenAccessModal(true)}
          >
            Restricted
          </Typography>
        </Box>

        {/*Modal Definition  */}
        <ModalMakeitPersonalComponent
          open={optionsmodalopen}
          onclose={() => setOptionsModalOpen(false)}
          title="Pending Moments"
          btnStatus="Review"
        />
        <MemoryDetailAccessModal
          open={openAccessModal}
          onclose={() => setOpenAccessModal(false)}
        />
      </div>
    </>
  );
};
