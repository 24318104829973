"use client";

import React, { useState } from "react";
import { useTranslation } from "i18n/client";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  ButtonContained,
  FacebookGreyIcon,
  InstagramGreyIcon,
  LogoWhite,
  TelegramGreyIcon,
  TwitterGreyIcon,
} from "@components/atoms";
import InputBase from "@mui/material/InputBase";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import {
  EmailShare,
  FacebookShare,
  TelegramShare,
  TwitterShare,
  WhatsappShare,
} from "../social-share";
import GoogleIcon from "@next/components/atoms/svgs/google/google";
import systemConfig from "@next/config/system.config";
import { subscribeForNewsletter } from "@next/ApiReq/newsletter/newsletter-api";
import toastify from "@next/utils/toastify";
import { validateEmail } from "@next/utils/validate-email";
import { CustomLink } from "../custom-link";
import { useParams } from "next/navigation";

export default function FooterPrimary() {
  const [email, setEmail] = useState("");
  const theme = useTheme();
  const params = useParams<{lng: string}>()
  const { t } = useTranslation(["common","messages"]);
  const isMobile = useMediaQuery(`${[theme.breakpoints.down("sm")]}`);

  const footerLinks = [
    {
      name: t("mission"),
      href: "/mission",
    },
    {
      name: t("faq"),
      href: "/faq",
    },
    {
      name: t("help"),
      href: "/help",
    },
    {
      name: t("contact"),
      href: "/contact",
    },
    {
      name: t("imprint"),
      href: "/imprint",
    },
    {
      name: t("terms_of_use"),
      href: "/terms-of-use",
    },
    {
      name: t("privacy_policy"),
      href: "/privacy-policy",
    },
  ];

  const handleSubscribe = () => {
    if (validateEmail(email)) {
      subscribeForNewsletter({ email })
        .then((res) => {
          toastify("success", t("success.subscribe_successful",{ns:"messages"}));
        })
        .catch((err) => {
          
          toastify("error", err?.response?.data?.Message);
        });
      setEmail("");
    } else {
      toastify("error", t("error.invalid_email",{ns:"messages"}));
    }
  };
  const FooterLink = ({ name, href }: { name: string; href: string }) => {
    return (
      <CustomLink href={href} locale={params.lng}>
        <Typography
          component="span"
          variant="Text_mdMedium"
          sx={{
            color: "#FFFFFF",
          }}
        >
          {name}
        </Typography>
      </CustomLink>
    );
  };
  return (
    <Box>
      <Grid
        container
        sx={{
          background: "#1d2939",
          width: "100%",
          padding: { xs: "48px 0px 51px", sm: "64px 80px 51px" },
        }}
      >
        <Grid item container xs={12}>
          <Grid
            item
            xs={12}
            sm={8.5}
            sx={{
              padding: { xs: "0px 10px", sm: "0px 32px" },
            }}
          >
            <Stack rowGap="32px">
              <LogoWhite />
              <Box
                sx={{
                  display: "flex",
                  columnGap: "32px",
                  alignItems: "center",
                  [theme.breakpoints.down("sm")]: {
                    display: "none",
                  },
                }}
              >
                {footerLinks.map(({ name, href }, i) => {
                  return <FooterLink key={i} name={name} href={href} />;
                })}
              </Box>
              <Grid
                container
                sx={{
                  [theme.breakpoints.up("sm")]: {
                    display: "none",
                  },
                }}
              >
                {footerLinks.map(({ name, href }, i) => {
                  return (
                    <Grid item key={i} xs={6} sm={2}>
                      <FooterLink name={name} href={href} />
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          </Grid>
          {systemConfig.FOOTER_PRIMARY.HAS_SUBSCRIPTION && (
            <Grid
              item
              xs={12}
              sm={3.5}
              sx={{
                padding: { xs: "0px 10px", sm: "0px 32px" },
                [theme.breakpoints.down("sm")]: {
                  marginTop: "48px",
                },
              }}
            >
              <Stack rowGap="16px">
                <Typography
                  component="label"
                  variant="Text_smMedium"
                  sx={{
                    color: "#ffffff",
                  }}
                >
                  {t("footer.subscribe_card.field_label")}
                </Typography>
                <Stack
                  flexDirection="row"
                  columnGap="16px"
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                      rowGap: "16px",
                    },
                  }}
                >
                  <InputBase
                    value={email}
                    onChange={(e) => setEmail(e?.target?.value)}
                    placeholder={t("footer.subscribe_card.field_placeholder")}
                    sx={{
                      height: "44px",
                      background: "#ffffff",
                      padding: "10px 14px",
                      borderRadius: "8px",
                      input: {
                        "&::placeholder": {
                          color: "#667085",
                          fontSize: "16px",
                          fontWeight: "normal",
                        },
                      },
                    }}
                  />
                  <ButtonContained
                    id="footer_subscribe_btn"
                    clickHandler={handleSubscribe}
                    sizeVariant="large"
                    width={isMobile ? "100%" : "114px"}
                    height="44px"
                  >
                    {t("footer.subscribe_card.btn")}
                  </ButtonContained>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: { xs: "0px 10px", sm: "0px 32px" },
            mt: { xs: "48px", sm: "64px" },
          }}
        >
          <Divider
            sx={{
              background: "#475467",
            }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{
            [theme.breakpoints.down("sm")]: {
              padding: "32px 0px",
              rowGap: "24px",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={9.5}
            sx={{
              padding: { xs: "0px 10px", sm: "32px" },
              [theme.breakpoints.down("sm")]: {
                order: 1,
              },
            }}
          >
            <Typography
              variant="Text_smMedium"
              sx={{
                color: theme.palette.grey[400],
              }}
            >
              {
                // TODO @Hassam: Read brand name from central config file
              }            
              ©marveltime. {t("footer.all_rights_reserved")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2.5}
            sx={{
              padding: { xs: "0px 10px", sm: "32px" },
            }}
          >
            <Stack flexDirection="row" columnGap="24px">
              {systemConfig.FOOTER_PRIMARY.SOCIAL_ICONS.HAS_FACEBOOK_ICON && (
                <FacebookShare url="local:3000">
                  <FacebookGreyIcon />
                </FacebookShare>
              )}

              {systemConfig.FOOTER_PRIMARY.SOCIAL_ICONS.HAS_INSTAGRAM_ICON && (
                <WhatsappShare url="local:3000">
                  <InstagramGreyIcon />
                </WhatsappShare>
              )}

              {systemConfig.FOOTER_PRIMARY.SOCIAL_ICONS.HAS_TELEGRAM_ICON && (
                <TelegramShare url="local:3000">
                  <TelegramGreyIcon />
                </TelegramShare>
              )}

              {systemConfig.FOOTER_PRIMARY.SOCIAL_ICONS.HAS_TWITTER_ICON && (
                <TwitterShare url="local:3000">
                  <TwitterGreyIcon />
                </TwitterShare>
              )}

              {systemConfig.FOOTER_PRIMARY.SOCIAL_ICONS.HAS_GOOGLE_ICON && (
                <EmailShare url="local:3000">
                  <GoogleIcon />
                </EmailShare>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
