import React from "react";

export const AccessIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m10.334 5 2.333-2.333M14 1.334l-1.333 1.333L14 1.334zM7.594 7.74a3.667 3.667 0 1 1-5.186 5.185 3.667 3.667 0 0 1 5.185-5.184h0zm0 0L10.334 5l-2.74 2.74zM10.334 5l2 2 2.333-2.333-2-2L10.334 5z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
