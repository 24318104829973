export const fallbackLng = "en-us";
export const languages = [fallbackLng, "de-de"];
export const defaultNS = "common";
export const cookieName = "NEXT_LOCALE";//"i18next";

export function getOptions(lng = fallbackLng, ns = defaultNS) {
  return {
    // debug: true,
    lowerCaseLng: true,
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    prefixDefault: false,
    locales: languages,
    defaultLocale: fallbackLng,
    localeDetection: true,
  };
}
