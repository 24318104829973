"use client";
import { useParams, useRouter } from "next/navigation";
import { useAppSelector } from "@next/hooks";
import { Box, Grid } from "@mui/material";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import systemConfig from "@next/config/system.config";
import dynamic from "next/dynamic";
import {  CustomLink, LinearProgress } from "@components/molecules";

// import { ButtonContained, ButtonTexted, LogoBlack } from "@components/atoms";
const ButtonContained = dynamic(
  () => import("@components/atoms").then((mod) => mod.ButtonContained),
  {
    loading: () => <p>Loading...</p>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
const ButtonTexted = dynamic(
  () => import("@components/atoms").then((mod) => mod.ButtonTexted),
  {
    loading: () => <p>Loading...</p>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
const LogoBlack = dynamic(
  () => import("@components/atoms").then((mod) => mod.LogoBlack),
  {
    loading: () =><></>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);

// import { NavDropdown } from "@components/molecules";
const NavDropdown = dynamic(
  () => import("@components/molecules").then((mod) => mod.NavDropdown),
  {
    loading: () => <></>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
const PrivateNavbarItems = dynamic(
  () => import("@components/molecules").then((mod) => mod.PrivateNavbarItems),
  {
    loading: () => <LinearProgress/>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
// import { PrivateNavbarItems } from "@components/molecules";
// import { useAppDispatch } from "@next/hooks";
// import { NavAccount } from "@components/molecules";
const NavAccount = dynamic(
  () => import("@components/molecules").then((mod) => mod.NavAccount),
  {
    loading: () => <></>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);

export const PrivateNavbar = (): JSX.Element => {
  const { loggedIn } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const params = useParams<{ lng: string }>();
  const router = useRouter();
  const signupRedirect = () => {
    router.push("/signup");
  };
  const loginRedirect = () => {
    router.push("/login");
  };
  return (
    <Grid
      container
      sx={{
        height: "60px",
        borderBottom: "1px solid #EAECF0",
      }}
    >
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "25px",
        }}
      >
        <CustomLink
          href="/"
          locale={params.lng}
          // TODO @Hassam: Read value from translation file (EN-US: Marveltime - Make memories, DE-DE: Marveltime - Erinnerungen schaffen)
          title="Marveltime - Unforgettable experiences for ever" 
        >
          <LogoBlack />
        </CustomLink>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          alignItems:"center"
        }}
      >
        <PrivateNavbarItems />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {loggedIn ? (
          <NavAccount />
        ) : (
          <>
            <Box
              sx={{
                marginRight: "23px",
              }}
            >
              <NavDropdown />
            </Box>
            {systemConfig.NAVBAR.HAS_LOGIN_SIGNUP && (
              <>
                <Box
                  sx={{
                    marginRight: "23px",
                  }}
                >
                  <ButtonTexted
                    id="privateNavbar_login_btn"
                    clickHandler={loginRedirect}
                    sizeVariant="small"
                    fontsize="16px"
                  >
                    {t("common:Login.Name")}
                  </ButtonTexted>
                </Box>
                <Box
                  sx={{
                    marginRight: "23px",
                  }}
                >
                  <ButtonContained
                    id="privateNavbar_register_btn"
                    clickHandler={signupRedirect}
                    sizeVariant="small"
                    fontsize="16px"
                  >
                    {t("common:Register.Name")}
                  </ButtonContained>
                </Box>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
