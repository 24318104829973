import { Box, Typography } from '@mui/material';
import i18next from 'i18next';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { ErrorCheck } from 'src/assets/svg/AlertChecks/ErrorCheck';
// import { ErrorCross } from 'src/assets/svg/AlertChecks/ErrorCross';
// import { InfoCheck } from 'src/assets/svg/AlertChecks/InfoCheck';
// import { InfoCross } from 'src/assets/svg/AlertChecks/InfoCross';
// import { SuccessCheck } from 'src/assets/svg/AlertChecks/SuccessCheck';
// import { SuccessCross } from 'src/assets/svg/AlertChecks/SuccessCross';
// import { WarningCheck } from 'src/assets/svg/AlertChecks/WarningCheck';
// import { WarningCross } from 'src/assets/svg/AlertChecks/WarningCross';


type NotificationType = 'error' | 'success' | 'info' | 'warning';
const closeButtonStyle = {
    height: '24px',
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    m: '10px',
    borderRadius: '50%',
    '&:hover': {
        backgroundColor: '#0000000f',
    },
    '&:focus': {
        border: '1px solid #fff',
    },
};
const textStyle = { fontWeight: '600', fontSize: '14px' };

const toastify = (type: NotificationType, message: string, options?: ToastOptions) => {
    switch (type) {
        case 'error':
            toast.error(
                <Box mb="10px" minWidth="100%">
                    <Typography sx={textStyle}>{i18next.t("common:toast.warnings")}</Typography>
                    <span style={{ lineHeight: '14px' }}>{message}</span>
                </Box>,
                {
                    icon: <></>,//<ErrorCheck />
                    closeButton: (
                        <Box sx={closeButtonStyle}>
                            {/* <ErrorCross /> */}
                        </Box>
                    ),
                    ...options,
                }
            );
            break;
        case 'success':
            toast.success(
                <Box mb="10px">
                    <Typography sx={textStyle}>{i18next.t("common:toast.congratulations")}</Typography>
                    <span style={{ lineHeight: '14px' }}>{message}</span>
                </Box>,
                {
                    icon: <></>,//<SuccessCheck />
                    closeButton: (
                        <Box sx={closeButtonStyle}>
                            {/* <SuccessCross /> */}
                        </Box>
                    ),
                    ...options,
                }
            );
            break;
        case 'info':
            toast.info(
                <Box mb="10px">
                    <Typography sx={textStyle}>{i18next.t("common:toast.information")}</Typography>
                    <span style={{ lineHeight: '14px' }}>{message}</span>
                </Box>,
                {
                    icon: <></>,//<InfoCheck />
                    closeButton: (
                        <Box sx={closeButtonStyle}>
                            {/* <InfoCross /> */}
                        </Box>
                    ),
                    ...options,
                }
            );
            break;
        case 'warning':
            toast.warning(
                <Box mb="10px">
                    <Typography sx={textStyle}>{i18next.t("common:toast.warning")}</Typography>
                    <span style={{ lineHeight: '14px' }}>{message}</span>
                </Box>,
                {
                    icon: <></>,//<WarningCheck />
                    closeButton: (
                        <Box sx={closeButtonStyle}>
                            {/* <WarningCross /> */}
                        </Box>
                    ),
                    ...options,
                }
            );
            break;
        default:
            toast(message, options);
            break;
    }
};

export default toastify;
