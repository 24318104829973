import { Stack, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
interface ISettingsPrivacyHeader {
  heading: string;
  subtitle: string;
}
export const SettingsPrivacyHeader: FC<ISettingsPrivacyHeader> = ({
  heading,
  subtitle,
}) => {
  const theme = useTheme();
  return (
    <Stack
    >
      <Typography
        component="h1"
        variant="Text_smMedium"
        sx={{
          color: theme.palette.grey[700],
        }}
      >
        {heading}
      </Typography>
      <Typography
        component="p"
        variant="Text_smNormal"
        sx={{
          color: theme.palette.grey[500],
        }}
      >
        {subtitle}
      </Typography>
    </Stack>
  );
};
