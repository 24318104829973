"use client";

import * as React from "react";
import { useParams, useRouter } from "next/navigation";
import { useTranslation } from "i18n/client";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { ButtonContained, CustomMenuItem, CrossIcon } from "@components/atoms";
import { CustomLink, NavDropdown } from "@components/molecules";
import systemConfig from "@next/config/system.config";
import Link from "next/link";

const StyledMenu = styled(Menu)`
  left: 0px;
`;
export const MobileHeaderMenu = () => {
  const { t } = useTranslation("common");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const params = useParams<{lng: string}>();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const router = useRouter();
  const signupRedirect = () => {
    router.push("/signup");
  };
  const loginRedirect = () => {
    router.push("/login");
  };
  const MenuItem = ({ href, name }: { href: string; name: string }) => {
    return (
      <CustomLink href={href} locale={params?.lng}>
        <CustomMenuItem handleClick={handleClose}>{name}</CustomMenuItem>
      </CustomLink>
    );
  };
  return (
    <Box>
      <Button
        id="mobile_header_menu"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {anchorEl ? (
          <Box sx={{ paddingTop: "5px" }}>
            <CrossIcon />
          </Box>
        ) : (
          <MenuIcon
            sx={{
              color: "#101828",
            }}
          />
        )}
      </Button>
      <Menu
        className="menu__container"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <MenuItem href="/mission" name={t("mission")} />
            <MenuItem href="/faq" name={t("faq")} />
            <MenuItem href="/help" name={t("help")} />
            <MenuItem href="/contact" name={t("contact")} />
          </Grid>
          <Grid item xs={6}>
            <MenuItem href="/imprint" name={t("imprint")} />
            <MenuItem href="/terms-of-use" name={t("terms_of_use")} />
            <MenuItem href="/privacy-policy" name={t("privacy_policy")} />
            <NavDropdown />
          </Grid>
          <Grid item xs={12}>
            {systemConfig.NAVBAR.HAS_LOGIN_SIGNUP && (
              <>
                <Box
                  sx={{
                    width: "100%",
                    margin: "24px 0px 12px 0px",
                    padding: "0px 16px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ButtonContained
                    id="mobile_header_menu_register"
                    clickHandler={signupRedirect}
                    sizeVariant="large"
                  >
                    {t("common:Register.Name")}
                  </ButtonContained>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    padding: "0px 16px",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "24px",
                  }}
                >
                  <ButtonContained
                    id="mobile_header_menu_login"
                    clickHandler={loginRedirect}
                    sizeVariant="large"
                    border="none"
                    bg="#EAECF0"
                    color="#98A2B3"
                  >
                    {t("common:Login.Name")}
                  </ButtonContained>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Menu>
    </Box>
  );
};
