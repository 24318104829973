"use client";

import React, { useState } from "react";
import { useTranslation } from "i18n/client";

import { SettingsPrivacyHeader } from "@components/molecules";
// import { IOSSwitch, SettingsSectionDivider } from "@components/molecules";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from "@mui/material";
// import { Box, Typography } from "@mui/material";
// import { SwitchWidthLabel } from "@next/components/molecules/switch-with-label";

export const NotificationMemories = () => {
  const { t } = useTranslation("settings");
  const options = [
    {
      label: t("notifications.memories.add_automatically"),
      value: "add-automatically",
    },
    {
      label: t("notifications.memories.add_manually"),
      value: "add-manually",
    },
  ];
  const [selectedMemoryOption, setSelectedMemoryOption] = useState<string>(
    options[0].value
  );
  const theme = useTheme();
  return (
    <Stack
      rowGap="16px"
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        pb: "35px",
      }}
    >
      <SettingsPrivacyHeader
        heading={t("notifications.memories.heading")}
        subtitle={t("notifications.memories.desc")}
      />
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={selectedMemoryOption}
        name="radio-buttons-group"
      >
        {options.map(({ label, value }, i) => {
          return (
            <FormControlLabel
              key={i}
              value={value}
              control={<Radio />}
              label={label}
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: 1.43,
                color: theme.palette.grey[700],
              }}
            />
          );
        })}
      </RadioGroup>
    </Stack>
  );
};
