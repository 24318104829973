import * as React from 'react';
import {Box,TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { SearchIcon } from '@components/atoms';
export const SearchAutoComplete = () => {
  // <li {...props}>{option.label}</li>
  return (
    <Box className="search__autoComplete" sx={{
      width:"100%",
      maxWidth:"300px"
    }}>
     <SearchIcon  />
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Movie" variant="outlined" />}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
    />
    
    </Box>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },

];
