"use client";
import React, { useState, FC, useEffect } from "react";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
// import { SmallCard } from "@components/organisms";
const SmallCard = dynamic(() =>
  import("@components/organisms").then((mod) => mod.SmallCard)
);
// import { SearchDefault, CrossIcon, TickIcon } from '@components/atoms';
const SearchDefault = dynamic(() =>
  import("@components/atoms").then((mod) => mod.SearchDefault)
);
const CrossIcon = dynamic(() =>
  import("@components/atoms").then((mod) => mod.CrossIcon)
);
const TickIcon = dynamic(() =>
  import("@components/atoms").then((mod) => mod.TickIcon)
);

import { validateFile } from "@utils";
import { useAppDispatch, useAppSelector } from "@hooks";
import { addMomentAssets } from "@ApiReq";
import {
  CardContent,
  Typography,
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  IconButton,
  CardHeader,
  Avatar,
  Grid,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import dynamic from "next/dynamic";
// import { styled, theme } from '@mui/system';

const BootstrapDialog = styled(Dialog)(({}) => ({
  "& .MuiDialog-container": {
    //  background:"rgba(52, 64, 84, 1)", //70%
  },
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    // padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    ["@media (max-width:700px)"]: {
      borderRadius: "0px",
      width: "100% !important",
      height: "100%",
      maxHeight: "100%",
      margin: "0px",
    },
    ["@media (min-width:700px)"]: {
      borderRadius: "10px",
    },

    // height:"100%",
    // maxHeight:"70%"
  },

  scrollbarColor: "#6b6b6b #2b2b2b",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#eaecf0",
    width: 8,
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#eaecf0",
  },
}));

export const AddContactModal: FC<any> = ({ open, onclose }) => {
  const [selectedContact, setSelectedContact] = useState<any[]>([]);
  const handleClose = () => {
    onclose();
  };
  const handleContactSelect = (val: any) => {
    const isExist = selectedContact.some((item: any) => item.id == val.id);
    if (isExist) {
      const updatedSelectedContact = selectedContact.filter(
        (item: any) => item.id != val.id
      );
      setSelectedContact(updatedSelectedContact);
    } else {
      setSelectedContact([...selectedContact, val]);
    }
  };
  const isSelected = (id: any) => {
    return selectedContact.some((item: any) => item.id == id);
  };
  return (
    <div>
      <BootstrapDialog
        // fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
      >
        <>
          <Card
            sx={{
              border: "1px solid #eaecf0",
              borderRadius: 0,
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{ borderBottom: "1px solid #eaecf0", padding: "7px 16px" }}
            >
              <Grid container>
                <Grid item xs={4}>
                  <IconButton onClick={handleClose} sx={{ padding: "0px 0px" }}>
                    {<CrossIcon />}
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#101828",
                      marginBottom: "0px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    gutterBottom
                  >
                    Add Contact
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#f858a5",
                      marginBottom: "0px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    gutterBottom
                  >
                    Done
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            {/* <Box sx={{backgroundColor: "red"}}> */}

            <SearchDefault />
            {/* </Box> */}
            <Box sx={{ overflowY: "scroll", height: "300px" }}>
              {[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }].map(
                (group: any, i: any) => {
                  return (
                    <CardHeader
                      key={i}
                      onClick={() => handleContactSelect(group)}
                      avatar={
                        <div style={{ marginRight: 0 }}>
                          <Avatar
                            alt="Remy Sharp"
                            src=""
                            sx={{ width: 32, height: 32 }}
                          />
                        </div>
                      }
                      action={
                        <IconButton aria-label="settings">
                          {isSelected(group.id) && <TickIcon />}
                        </IconButton>
                      }
                      titleTypographyProps="h4"
                      title={
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "14px",
                              color: "#101828",
                            }}
                          >
                            {"Phoenix Baker"}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "14px",
                              color: "#667085",
                              marginLeft: "8px",
                            }}
                          >
                            {"@phoenix"}
                          </Typography>
                        </Box>
                      }
                      subheaderTypographyProps="body1"
                    />
                  );
                }
              )}
            </Box>
          </Card>
        </>
      </BootstrapDialog>
    </div>
  );
};
