import { Avatar } from "@mui/material";
import React, { FC } from "react";
import { ProfileAvatarProps } from "./profile-avatar.types";
export const ProfileAvatar: FC<ProfileAvatarProps> = ({
  name,
  imageURL,
  width,
  height
}): JSX.Element => {
  return <Avatar  alt={name} src={imageURL} sx={{
    width:width || "32px",
    height:height || "32px"
  }} />;
};
