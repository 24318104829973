"use client";

import React, { useState } from "react";
import { useTranslation } from "i18n/client";

import { CutomCheckbox } from "@components/atoms";
import { SettingsPrivacyHeader } from "@components/molecules";
import { Box, Stack, Typography, useTheme } from "@mui/material";

export const ActivityStatus = () => {
  const { t } = useTranslation("settings");
  const [activityStatus, setActivityStatus] = useState([
    {
      label: t(
        "privacy_and_security.activity_status.show_activity_status.label"
      ),
      subtitle: t(
        "privacy_and_security.activity_status.show_activity_status.subtitle"
      ),
      checked: false,
    },
  ]);
  const theme = useTheme();

  return (
    <Stack
      rowGap="20px"
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        pb: "24px",
      }}
    >
      <SettingsPrivacyHeader
        heading={t("privacy_and_security.activity_status.heading")}
        subtitle={t("privacy_and_security.activity_status.desc")}
      />
      {activityStatus.map(({ label, subtitle }, i) => {
        return (
          <Stack
            key={i}
            flexDirection="row"
            sx={{
              columnGap: "8px",
            }}
          >
            <Box>
              <CutomCheckbox />
            </Box>
            <Box>
              <Typography
                component="h3"
                variant="Text_smMedium"
                sx={{
                  color: theme.palette.grey[700],
                }}
              >
                {label}
              </Typography>
              <Typography
                component="p"
                variant="Text_smNormal"
                sx={{
                  color: theme.palette.grey[500],
                }}
              >
                {subtitle}
              </Typography>
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};
