import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import { useTranslation } from "i18n/client";
const IconWrapper = styled(Box)({
  margin: "0px 10px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  img: {
    height: "48px",
  },
});
export const EmojiRating = ({ handleNext }: any) => {
  const theme = useTheme();
  const {t} = useTranslation("common")
  const ratingEmojis = [
    {
      value: 1,
      label:t("start_rating_modal.emojies.hate"),
      default: "/assets/images/rating/smiley-hate-inactive.png",
      active: "/assets/images/rating/smiley-hate.png",
      gif: "/assets/images/rating/smiley-hate.gif",
    },
    {
      value: 2,
      label:t("start_rating_modal.emojies.dislike"),
      default: "/assets/images/rating/smiley-disappointed-inactive.png",
      active: "/assets/images/rating/smiley-disappointed.png",
      gif: "/assets/images/rating/smiley-disappointed.gif",
    },
    {
      value: 3,
      label:t("start_rating_modal.emojies.neutral"),
      default: "/assets/images/rating/smiley-neutral-inactive.png",
      active: "/assets/images/rating/smiley-neutral.png",
      gif: "/assets/images/rating/smiley-neutral.gif",
    },
    {
      value: 4,
      label:t("start_rating_modal.emojies.like"),
      default: "/assets/images/rating/smiley-good-inactive.png",
      active: "/assets/images/rating/smiley-good.png",
      gif: "/assets/images/rating/smiley-good.gif",
    },
    {
      value: 5,
      label:t("start_rating_modal.emojies.love"),
      default: "/assets/images/rating/smiley-excellent-inactive.png",
      active: "/assets/images/rating/smiley-excellent.png",
      gif: "/assets/images/rating/smiley-excellent.gif",
    },
  ];
  const [selectedEmoji, setSelectedEmoji] = useState(0);
  const [currentGif, setCurrentGif] = useState(0);
  const handleSelectedEmoji = (value: number) => {
    // setSelectedEmoji(value);
    setSelectedEmoji(0);
    setSelectedEmoji(0);
    handleNext(value);
  
  };

  return (
    <Box
    
      sx={{
        display: "flex",
      }}
    >
      {ratingEmojis.map((emoji: any, i) => {
        return (
          <IconWrapper
            key={i}
            onMouseOver={() => setCurrentGif(emoji.value)}
            onMouseLeave={() => setCurrentGif(0)}
            onClick={() => handleSelectedEmoji(emoji.value)}
          >
            <img
              src={
                emoji.value === currentGif
                  ? emoji?.gif
                  : emoji.value !== selectedEmoji
                  ? emoji?.default
                  : emoji?.active
              }
            />
            <Typography variant="Text_smNormal" sx={{
                 color: theme.palette.grey[500],
                 mt:"5px"
            }}>{emoji.label}</Typography>
          </IconWrapper>
        );
      })}
    </Box>
  );
};
