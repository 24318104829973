import React, { FC } from "react";
import { useTranslation } from "i18n/client";

import { Button, Grid, Typography, useTheme } from "@mui/material";
// import {  Stack } from "@mui/material";
import { Dialog } from "@next/components/molecules/dialog";
interface IDeleteAccountModal {
  open: boolean;
  onClose: () => void;
}
export const DeleteAccountModal: FC<IDeleteAccountModal> = ({
  open,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("settings");
  return (
    <Dialog open={open} onClose={onClose} paddingLeft="24px">
      <Grid container px="24px">
        <Grid item xs={12}>
          <Typography
            component="h2"
            variant="Body_largeMedium"
            sx={{
              color: theme.palette.grey[900],
              textAlign: "center",
            }}
          >
            {t("manage_account.modal.heading")}
          </Typography>
          <Typography
            component="p"
            variant="Body_smallRegular"
            sx={{
              color: theme.palette.grey[500],
              mt: "8px",
              textAlign: "center",
            }}
          >
            {t("manage_account.modal.subtitle")}
          </Typography>

          <Button
            id="settings_delete_account_modal_button"
            variant="contained"
            sx={{ width: "100%", textTransform: "unset", mt: "24px" }}
          >
            {t("manage_account.modal.button")}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
