"use client";

import React from "react";
import { useParams, useRouter } from "next/navigation";

import { Typography, Box } from "@mui/material";
import { CutomCheckbox } from "@components/atoms";

export const ForgotPasswordFormFooterText = () => {
  const router = useRouter();
  const params = useParams()
  const loginRedirect = () => {
    router.push(`/${params.lng}/login`);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        marginBottom: "16px",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
          color: "#E02A81",
          cursor: "pointer",
          // marginBottom:"24px"
        }}
        component="span"
        onClick={loginRedirect}
      >
        {" "}
        Back to log in
      </Typography>
    </Box>
  );
};
