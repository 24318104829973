import React, { FC } from "react";
import { Button } from "@mui/material";
import { ButtonOutlinedProps } from "./button-outlined.types";
export const ButtonOutlined: FC<ButtonOutlinedProps> = ({
  children,
  sizeVariant = "medium",
  fontsize,
  fontweight,
  fontstyle,
  lineheight,
  color,
  bg,
  border,
  ptb,
  plr,
  boxshadow,
  width,
  height,
  clickHandler,
  id
}): JSX.Element => {
  return (
    <Button
      id={id}
      size={sizeVariant}
      variant="outlined"
      onClick={clickHandler}
      sx={{
        border: border || "1px solid #F62E8E",
        boxShadow: boxshadow || "0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: "8px",
        width: width || "100%",
        height: height || "100%",
        fontFamily: "Inter",
        fontStyle: fontstyle || "normal",
        fontSize: fontsize || "16px",
        fontWeight: fontweight || "500",
        lineHeight: lineheight || "24px",
        color: color || "#000000",
        textTransform: "none",
        "&:hover": {
          color: color || "#000000",
        },
        
      }}
    >
      {children}
    </Button>
  );
};
