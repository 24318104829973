import React from 'react'
import { CarouselDefault } from '../carousel-default';
import { IImage, IImages } from '@types';
import { Skeleton } from '@mui/material';

export default function RatingModalCarousel({images,isLoading}:{images:IImages,isLoading:boolean}) {
   if(isLoading) return <Skeleton variant="rectangular" height={300} sx={{
    width:"100%",
  
}}></Skeleton>
  return (
    <CarouselDefault
    images={
        images
        .map((image:IImage) => ({
          file: image?.file ?? "",
          alt: image?.alt ?? "",
          title: image?.title ?? "",
        }))
        .filter((image:IImage) => !!image.file) ?? []
    }
  />
  )
}
