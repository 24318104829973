"use client";

// Npm imports
import React, { FC, useState } from "react";

// MUI imports
import {
  Box,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  IconButton,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Component imports
import {
  WatchIcon,
  HeartIcon,
  HandsIcon,
  ModalMoreOptionsComponent,
} from "@components/atoms";
import {
  TotalUsers,
  CarouselGridVersionTwo,
  MemoryCarousel,
} from "@components/molecules";

// TS imports
import { MemoryDetailsCardProps } from "./memory-detail-card.types";

const imgData = [
  {
    file: "/assets/images/explore/placeholder-memory.png",
    fileName: "Breakfast",
  },
  {
    file: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    fileName: "Burger",
  },
  {
    file: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    fileName: "Camera",
  },
  {
    file: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    fileName: "Coffee",
  },
];

export const MemoryDetailsCard: FC<MemoryDetailsCardProps> = ({
  memoryTitle,
  memoryPlace,
  postedByFirstPerson,
  postedUsernameFirstPerson,
  postDescriptionFirstPerson,
  memoryImgFirstPerson,
  friendStatusFirstPerson,
}): JSX.Element => {
  const [optionsmodalopen, setOptionsModalOpen] = useState(false);

  const handleClickMoreOptions = () => {
    setOptionsModalOpen(true);
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* Top Header */}
            <CardHeader
              sx={{
                fontSize: "20px",
              }}
              action={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={() => handleClickMoreOptions()}>
                    <MoreVertIcon />
                  </IconButton>
                  <ModalMoreOptionsComponent
                    open={optionsmodalopen}
                    onclose={() => setOptionsModalOpen(false)}
                    title="Pending Moments"
                    btnStatus="Review"
                  />
                </div>
              }
              titleTypographyProps="h4"
              title={
                <Typography
                  sx={{ fontSize: "14px", color: "#344054", fontWeight: "500" }}
                >
                  {memoryTitle}
                </Typography>
              }
              subheaderTypographyProps="body1"
              subheader={
                <Typography sx={{ fontSize: "14px", color: "#667085" }}>
                  {memoryPlace}
                </Typography>
              }
            />
          </Grid>

          {/* Img Gallery Slider Start */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <MemoryCarousel images={imgData} />
          </Grid>
          {/* Img Gallery Slider Start */}

          {/* Bottom Header */}
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <Box sx={{ display: "flex", padding: "0px", margin: "0px" }}>
              <CardHeader
                avatar={
                  <div style={{ marginRight: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      // src={userImg}
                      sx={{
                        width: 32,
                        height: 32,
                        paddingRight: 0,
                        marginRight: 0,
                      }}
                    />
                  </div>
                }
                titleTypographyProps="h4"
                title={
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#101828",
                      fontWeight: "500",
                    }}
                  >
                    {postedByFirstPerson}
                  </Typography>
                }
                subheaderTypographyProps="body1"
                subheader={
                  <Typography sx={{ fontSize: "12px", color: "#667085" }}>
                    {postedUsernameFirstPerson}
                  </Typography>
                }
              />
              <Typography
                variant="body2"
                sx={{
                  color: "#e02a81",
                  fontSize: "14px",
                  fontWeight: 500,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: "15px 0",
                  margin: "3px 0",
                }}
              >
                {friendStatusFirstPerson}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {/* Post Status icons */}
            <div style={{ width: "100%" }}>
              <Box>
                <ul className="postStatusicons">
                  <li>
                    <WatchIcon />{" "}
                  </li>
                  <li>
                    <HeartIcon />
                  </li>
                  <li>
                    <HandsIcon />
                  </li>
                </ul>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* Post Status icons */}
            <CardContent sx={{ marginTop: 0, paddingTop: 0 }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#667085", fontSize: "14px" }}
              >
                {postDescriptionFirstPerson}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
