"use client";
import React, { useState, FC, useEffect } from "react";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import { SmallCard } from "@components/organisms";
import { SearchDefault } from "@components/atoms";
import { validateFile } from "@utils";
import { useAppDispatch, useAppSelector } from "@hooks";
import { addMomentAssets } from "@ApiReq";
import {
  CardContent,
  Typography,
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  IconButton,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
// import { styled, theme } from '@mui/system';

const BootstrapDialog = styled(Dialog)(({}) => ({
  "& .MuiDialog-container": {
    //  background:"rgba(52, 64, 84, 1)", //70%
  },
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    // padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    ["@media (max-width:700px)"]: {
      borderRadius: "0px",
      width: "100% !important",
      height: "100%",
      maxHeight: "100%",
      margin: "0px",
    },
    ["@media (min-width:700px)"]: {
      borderRadius: "0px",
    },

    // height:"100%",
    // maxHeight:"70%"
  },

  scrollbarColor: "#6b6b6b #2b2b2b",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#eaecf0",
    width: 8,
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#eaecf0",
  },
}));

export const ModalSeeAllComponent: FC<any> = ({
  open,
  onclose,
  title,
  data,
  btnStatus,
  icon,
}) => {
  // const [open, setOpen] = React.useState(false);
  const [isImagesUploaded, setIsImagesUploaded] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [momentAssets, setMomentAssets] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [memory, setMemory] = useState<any>({});
  const memories: any = useAppSelector((state) => state.memories);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  const handleClickOpen = () => {
    // setOpen(true);
  };
  const handleClose = () => {
    setIsImagesUploaded(false);
    setFiles([]);
    setMomentAssets([]);
    onclose();
  };
  const handleImageUpload = async (image: any, momentId: any) => {
    const isValid = validateFile(image);
    const currentMomentId = memories.memory?.momentId;
    if (isValid) {
      setFiles((prevFiles: any) => {
        return [
          ...prevFiles,
          {
            file: image,
            preview: URL.createObjectURL(image),
          },
        ];
      });
      const formData = new FormData();
      formData.append("assets", image, image.name);
      debugger;
      addMomentAssets({
        loginToken: localStorage.getItem("token") || "",
        momentId: currentMomentId,
        assets: formData,
      })
        .then((res: any) => {
          if (res?.data?.result) {
            setIsImagesUploaded(true);
            setIsInvalidFile(false);
            setMomentAssets((prevAssets: any) => {
              return [...prevAssets, ...res?.data?.result?.assets];
            });
          }
        })
        .catch((err: any) => {});
    } else {
      setIsInvalidFile(true);
    }
  };
  const handleRemoveImage = (imageIndex: any) => {
    const newFiles = files.filter((item: any, i: any) => i != imageIndex);
    setFiles(newFiles);
  };

  return (
    <div>
      <BootstrapDialog
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
      >
        <>
          <Card
            sx={{
              border: "1px solid #eaecf0",
              borderRadius: 0,
              boxShadow: "none",
            }}
          >
            <CardContent
              sx={{ borderBottom: "1px solid #eaecf0", padding: "7px 16px" }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#101828",
                    marginBottom: "0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  gutterBottom
                >
                  {title}
                </Typography>

                <IconButton sx={{ padding: "0px 0px" }}>{icon}</IconButton>
              </Box>
            </CardContent>
            {/* <Box sx={{backgroundColor: "red"}}> */}

            <SearchDefault />
            {/* </Box> */}
            <Box sx={{ overflowY: "scroll", height: "300px" }}>
              <SmallCard
                userName="Name"
                userMemory="Memory Title"
                userBtnText={btnStatus}
              />
              <SmallCard
                userName="Name"
                userMemory="Memory Title"
                userBtnText={btnStatus}
              />
              <SmallCard
                userName="Name"
                userMemory="Memory Title"
                userBtnText={btnStatus}
              />
              <SmallCard
                userName="Name"
                userMemory="Memory Title"
                userBtnText={btnStatus}
              />
              <SmallCard
                userName="Name"
                userMemory="Memory Title"
                userBtnText={btnStatus}
              />
            </Box>
            <CardContent
              sx={{
                borderTop: "1px solid #eaecf0",
                marginTop: "0px",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 14, color: "#e02a81", marginBottom: 0 }}
                color="text.secondary"
                gutterBottom
                onClick={handleClose}
              >
                See Less
              </Typography>
            </CardContent>
          </Card>
        </>
      </BootstrapDialog>
    </div>
  );
};
