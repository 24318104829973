import React from "react";
import { useTranslation } from "i18n/client";
import { Box, Typography, Button } from "@mui/material";
interface IMakeARecommendation {
  btnText: string;
  onClick: () => void;
  buttonId:string
}
const MakeARecommendation = ({ btnText, onClick, buttonId }: IMakeARecommendation) => {
  const { t } = useTranslation("common");
  return (
    <Box
      sx={{
        height: "439px",
        backgroundImage:
          'url("/assets/images/explore/collection-of-your-best-moments-in-life.webp")',
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage:
            "linear-gradient(to bottom, #fff 5%, rgba(255, 255, 255, 0.51) 180%)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "783px",
          }}
        >
          <Typography
            variant="Display_lgSemibold"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            {t("recommendations.banner.heading")}
          </Typography>
          <Button
            id={buttonId}
            variant="contained"
            onClick={onClick}
            sx={{
              marginTop: "48px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              textTransform: "unset",
            }}
          >
            {btnText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default MakeARecommendation;
