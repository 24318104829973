"use client";
import React from "react";
import { useParams, usePathname } from "next/navigation";
import { useTranslation } from "i18n/client";

import {
  AboutIcon,
  BellIcon,
  HelpIcon,
  KeyIcon,
  LockIcon,
  ProfileIcon,
  SettingIcon,
} from "@components/atoms";
import {
  Badge,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Link from "next/link";
import { CustomLink } from "@components/molecules";

export const SettingsSidebar = ({
  showHeading = true,
}: {
  showHeading: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslation("settings");
  const pathname = usePathname();
  const params = useParams<{lng: string}>();
  const sidebarItems = [
    {
      icon: <ProfileIcon />,
      label: t("side_pannel.edit_profile"),
      href: "/edit-profile",
    },
    {
      icon: <KeyIcon />,
      label: t("side_pannel.change_password"),
      href: "/change-password",
    },
    {
      icon: <SettingIcon />,
      label: t("side_pannel.manage_account"),
      href: "/manage-account",
    },
    {
      icon: <BellIcon />,
      label: t("side_pannel.notifications"),
      href: "/notifications",
    },
    {
      icon: <LockIcon />,
      label: t("side_pannel.privacy_and_security"),
      href: "/privacy-and-security",
    },
    {
      icon: <HelpIcon />,
      label: t("side_pannel.help"),
      href: "/help",
    },
    {
      icon: <AboutIcon />,
      label: t("side_pannel.about"),
      href: "/about",
    },
  ];
  const isActive = (link: string) => {
    return pathname === link;
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        {showHeading && (
          <Typography
            component="h2"
            variant="Text_mdMedium"
            sx={{
              color: theme.palette.grey[900],
              marginBottom: "16px",
            }}
          >
            {t("heading")}
          </Typography>
        )}
        <List
          sx={{
            padding: "0px",
          }}
        >
          {sidebarItems.map((sidebarItem, i) => {
            const link = `/settings${sidebarItem.href}`;
            return (
              <ListItem
                key={i}
                sx={{
                  padding: "8px 12px",
                  marginBottom: "4px",
                  background: isActive(link) ? theme.palette.grey[50] : "",
                  borderRadius: "6px",
                  "& :last-item": {
                    marginBottom: "0px",
                  },
                }}
                secondaryAction={
                  sidebarItem.label === t("side_pannel.notifications") ? (
                    <Badge>0</Badge>
                  ) : null
                }
              >
                <ListItemAvatar
                  sx={{
                    minWidth: "auto",
                    marginRight: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {sidebarItem?.icon}
                </ListItemAvatar>
                <CustomLink href={link} locale={params?.lng}>
                  <ListItemText
                    primary={sidebarItem?.label}
                    sx={{
                      ".MuiListItemText-primary": {
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: 1.5,
                        color: isActive(link)
                          ? theme.palette.grey[900]
                          : theme.palette.grey[700],
                      },
                    }}
                  />
                </CustomLink>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};
