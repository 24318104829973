"use client"
import { Box } from "@mui/material";
import React from "react";
import { CustomLink, MobileHeaderMenu } from "@components/molecules";
import { useAppSelector } from "@hooks";
import { NavAccountProfile } from "@components/molecules";
import Link from "next/link";
import { BackIcon, LogoBlack } from "@components/atoms";
import { useParams } from "next/navigation";

export const MobileHeader = ({showBackBtn,onClick}:{showBackBtn?:boolean, onClick?:() => void}) => {
  const { loggedIn } = useAppSelector((state) => state.auth);
  const params = useParams<{lng: string}>();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "0px 10px",
        height: "60px",
        position: "relative",
        borderBottom: "1px solid #EAECF0",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomLink href="/" locale={params.lng}>
          <LogoBlack/>
          
        </CustomLink>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "10px",
          // ...(loggedIn ? {right: "10px",} : {left:"10px"})
          right:"10px"
        }}
      >
        {loggedIn ? <NavAccountProfile /> : <MobileHeaderMenu />}
      </Box>
     {showBackBtn && <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "18px",
          left:"10px",
          cursor:"pointer",
          WebkitTapHighlightColor: 'transparent',
          touchAction: 'manipulation',
          backgroundColor: 'transparent !important',
          WebkitUserSelect: 'none',
        }}
      >
         <BackIcon/>
      </Box>}
    </Box>
  );
};
