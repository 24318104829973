import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
// import {
//   ExploreDetailRatingBoxCard,
//   ListWithIcon,
// } from "@components/molecules";

import { LocationIcon } from "@components/atoms";
interface IAddress {
  address: {
    city: string;
    country: string;
    countryCode: string;
    number: string;
    positionLatitude: string;
    positionLongitude: string;
    postalCode: string;
    street: string;
    text: string;
  };
}
export const Address = ({ address }: IAddress) => {
  const street = address?.street ?? "";
  const number = address?.number ?? "";
  const postalCode = address?.postalCode ?? "";
  const city = address?.city ?? "";
  const country = address?.country ?? "";

  return (
    <Box sx={{ marginTop: "24px" }}>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
        }}
      >
        <LocationIcon />
        <Stack>
          <Typography variant="Text_smNormal" color="#667085">
            {street + " " + number}
          </Typography>
          <Typography variant="Text_smNormal" color="#667085">
            {postalCode + " " + city}
          </Typography>
          <Typography variant="Text_smNormal" color="#667085">
            {country}
          </Typography>
        </Stack>
      </Box>
      {/* {!list ? null : <ListWithIcon list={list} />} */}
    </Box>
  );
};
