import { Typography } from '@mui/material';
import React from 'react';


export const ErrorField = ({ error }: { error: string | undefined }) => {
    return (
        <Typography
            color="error.main"
            sx={{
                fontSize: '12px',
                marginTop: '5px',
                ml: '5px',
            }}
        >
            <> {error}</>
        </Typography>
    );
};