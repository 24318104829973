import React from "react";

export const FeaturedIconOne = () => {
  return (
    <svg
      width="102"
      height="101"
      viewBox="0 0 102 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="7.19971" width="86" height="86" rx="43" fill="#FCBEDC" />
      <rect
        x="8"
        y="7.19971"
        width="86"
        height="86"
        rx="43"
        stroke="#FEEAF4"
        strokeWidth="14.3333"
      />
      <path
        d="M58.8961 31.3945V69.1997H48.6326V40.8458H48.411L40.1411 45.7929V37.08L49.4448 31.3945H58.8961Z"
        fill="#FEEAF4"
      />
    </svg>
  );
};
