import NextImage from "next/image";

import React from "react";
interface ImageType {
  src: string;
  alt: string;
  title: string;
  width: number;
  height: number;
}
export const Image = ({ src, alt, title, width, height }: ImageType) => {
  return (
    <NextImage
      src={src}
      alt={alt}
      title={title}
      width={width}
      height={height}
      style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }}
    />
  );
};
