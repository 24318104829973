import React from 'react'

export const TwitterIcon = () => {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 14.7107C0 6.72273 6.47554 0.247192 14.4635 0.247192C22.4515 0.247192 28.927 6.72273 28.927 14.7107C28.927 22.6987 22.4515 29.1742 14.4635 29.1742C6.47554 29.1742 0 22.6987 0 14.7107Z" fill="#F858A5"/>
    <path d="M14.0303 12.0033L14.0607 12.5038L13.5548 12.4425C11.7136 12.2076 10.105 11.4109 8.73917 10.0729L8.07146 9.40904L7.89947 9.89929C7.53526 10.9922 7.76795 12.1463 8.52672 12.9225C8.93139 13.3515 8.84034 13.4128 8.14227 13.1575C7.89947 13.0757 7.68701 13.0145 7.66678 13.0451C7.59596 13.1166 7.83877 14.046 8.03099 14.4137C8.29403 14.9244 8.83023 15.4249 9.41701 15.7211L9.91274 15.956L9.32596 15.9662C8.75941 15.9662 8.73917 15.9764 8.79988 16.1909C9.00221 16.8548 9.80145 17.5596 10.6917 17.866L11.319 18.0805L10.7727 18.4073C9.96332 18.8771 9.01233 19.1427 8.06134 19.1631C7.60608 19.1733 7.23175 19.2142 7.23175 19.2448C7.23175 19.3469 8.46602 19.9189 9.18432 20.1436C11.3392 20.8075 13.8988 20.5215 15.821 19.3878C17.1868 18.5809 18.5526 16.9774 19.19 15.4249C19.5339 14.5976 19.8779 13.086 19.8779 12.3608C19.8779 11.891 19.9083 11.8297 20.4748 11.2679C20.8087 10.9411 21.1223 10.5836 21.183 10.4815C21.2842 10.2874 21.2741 10.2874 20.7581 10.461C19.8981 10.7675 19.7767 10.7266 20.2017 10.267C20.5153 9.94015 20.8896 9.34775 20.8896 9.17412C20.8896 9.14348 20.7379 9.19455 20.5659 9.28647C20.3838 9.38861 19.9791 9.54181 19.6756 9.63374L19.1293 9.80737L18.6335 9.47032C18.3604 9.28647 17.9759 9.0822 17.7736 9.02092C17.2576 8.87792 16.4685 8.89835 16.0031 9.06177C14.7385 9.52139 13.9393 10.7062 14.0303 12.0033Z" fill="#FCFCFD"/>
    </svg>
  )
}
