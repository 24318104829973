import React from "react";
import { Box, Button, Grid, GridProps, useTheme } from "@mui/material";
interface ISectionLayout {
  left: React.ReactNode;
  right: React.ReactNode;
  buttonText: string;
  gridOneProps?: GridProps;
  gridTwoProps?: GridProps;
  showButton: boolean;
  onClick?: () => void;
  buttonId?:string
}
const SectionLayout = ({
  left,
  right,
  buttonText,
  gridOneProps,
  gridTwoProps,
  showButton,
  onClick,
  buttonId
}: ISectionLayout) => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        padding: { xs: "48px 0px", sm: "0px 32px" },
        rowGap: "24px",
        columnSpacing: { xs: "", sm: "96px" },
      }}
    >
      <Grid item xs={12} sm={6} order={0} {...gridOneProps}>
        {left}
      </Grid>
      <Grid item xs={12} sm={6} {...gridTwoProps}>
        {right}
      </Grid>
      {showButton && (
        <Grid item xs={12} order={2}>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              id={buttonId}
              onClick={onClick}
              sx={{
                background: "#ffffff",
                border: `1px solid ${theme.palette.grey[300]}`,
                width: "191px",
                height: "60px",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                color: theme.palette.grey[700],
                textTransform: "unset",
                padding: "0px",
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
export default SectionLayout;
