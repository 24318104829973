"use client";
import { useParams, useRouter } from "next/navigation";
import { useTranslation } from "i18n/client";
import {
  ButtonContained,
  CustomTypography,
  CustomInputField,
} from "@components/atoms";
import { Box } from "@mui/material";
import Link from "next/link";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { IFormValuesType } from "./login-form.types";
import { useAppDispatch, useAppSelector } from "@next/hooks";
import { login } from "@next/store";
import { CustomLink } from "@components/molecules";

const schema = Yup.object({
  email: Yup.string().required("Password.Forgot").email("Email.Invalid"),
  password: Yup.string().required("Password.Required").min(8).max(255),
  checkbox: Yup.boolean(),
}).required();

export const LoginForm = (): JSX.Element => {
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useAppDispatch();
  const params = useParams<{lng: string}>();
  const auth = useAppSelector((state) => state.auth);
  const { t } = useTranslation("auth");
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IFormValuesType>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
    reValidateMode: "onChange",
  });
  const handleLoginSubmit: SubmitHandler<IFormValuesType> = ({
    email,
    password,
  }) => {
    dispatch(
      login({
        identifier: email,
        password: password,
      })
    )
      .unwrap()
      .then((res: any) => {
        router.push("/home");
        setErrorMessage("");
      })
      .catch((err: any) => {
        setErrorMessage(err?.message);
      });
  };
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <form onSubmit={handleSubmit(handleLoginSubmit)}>
        <Box
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <CustomInputField
            type="email"
            name="email"
            register={register}
            placeholder={t(`Login.email.placeholder`)}
          />
          <Box
            sx={{
              marginTop: "6px",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <CustomInputField
            register={register}
            type="password"
            name="password"
            placeholder={t(`Login.Password.placeholder`)}
          />
          {errors?.password && (
            <Box
              sx={{
                marginTop: "6px",
              }}
            >
              <CustomTypography
                textalign="left"
                fontstyle="normal"
                fontsize="14px"
                fontweight="400"
                lineheight="20px"
                color="#667085"
              >
                {errors?.password?.message}
              </CustomTypography>
            </Box>
          )}
          {errorMessage.length > 0 && (
            <Box
              sx={{
                marginTop: "6px",
              }}
            >
              <CustomTypography
                textalign="left"
                fontstyle="normal"
                fontsize="14px"
                fontweight="400"
                lineheight="20px"
                color="#E02A81"
              >
                {errorMessage}
              </CustomTypography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            marginBottom: "24px",
          }}
        >
          <CustomLink href="/forgot-password" locale={params?.lng}>
            <CustomTypography
              textalign="left"
              fontstyle="normal"
              fontsize="14px"
              fontweight="500"
              lineheight="20px"
              color="#E02A81"
            >
              {t("Login.forgot_password")}
            </CustomTypography>
          </CustomLink>
        </Box>
        <Box
          sx={{
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <ButtonContained id="loginForm_btn" sizeVariant="large">
            {t("Login.title")}
          </ButtonContained>
        </Box>
      </form>
    </Box>
  );
};
