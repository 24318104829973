import { MenuItem, Select as MuiSelect, SelectChangeEvent } from '@mui/material'
import React, { FC, useState } from 'react'
interface IOption {
    label:string,
    value:string
}
interface ISelect {
    value:string;
    setValue: (value:string) => void
    options : IOption[]
}
export const Select : FC<ISelect> = ({
    value,
    setValue,
    options
}) => {
    
    const handleChange = (e:SelectChangeEvent) => {
       setValue(e.target.value)
    }
  return (
    <MuiSelect
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={value}
          onChange={handleChange}
          fullWidth={true}
        >
         {
            options.map((option,i) => {
                return (
                    <MenuItem key={i} value={option?.value}>{option?.label}</MenuItem>
                )
            })
         }
        </MuiSelect>
  )
}
