import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/system";
import { MoreIcon } from "@components/atoms";
const CustomListItemText = styled(ListItemText)`
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }
`;
export const ListWithAvatarAndIcon = ({data, handleClickOpen }: any) => {
  const handleClick = () => {
    handleClickOpen();
  };

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper", paddingBottom: "0px" }}
    >
      {data && data.map((item: any, i: any) => {
        return (
          <ListItem
            sx={{
              padding: "16px 16px",
              borderBottom: "1px solid #eaecf0",
            }}
            key={i}
            secondaryAction={
              <IconButton edge="end" aria-label="menu" onClick={handleClick}>
                <MoreIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
            {  item?.image ? <img src={item.image} /> :
                <ImageIcon />}
              </Avatar>
            </ListItemAvatar>
            <CustomListItemText primary={item?.primary} secondary={item.secondary} />
          </ListItem>
        );
      })}
    </List>
  );
};
