import key from "config/key";

export default function getURL(path: string) {
  const host =
    typeof window !== "undefined"
      ? window?.location?.host
      : key.NEXT_PUBLIC_DOMAIN;

  const baseURL = host
    ? host.includes("localhost")
      ? `http://localhost:3000/`
      : `${key.NEXT_PUBLIC_DOMAIN}/`
    : host;

  return new URL(path, baseURL).toString();
}
