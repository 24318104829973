"use client";

import * as React from "react";
import { useParams, usePathname, useRouter } from "next/navigation";
import { useAppSelector } from "@hooks";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LanguageIcon } from "@components/atoms";
import getURL from "@next/utils/getURL";
import { languages as locales } from "i18n/settings";
import i18next from "i18next";
const ITEM_HEIGHT = 48;

export const NavDropdown = () => {
  const params = useParams<{ lng: string }>();
  const pathname = usePathname();
  const router = useRouter();

  const [localesData, setLocalsData] = React.useState<
    { url: string; locale: string }[]
  >([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { urlFromApi } = useAppSelector((state) => state.global);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
 

  React.useEffect(() => {
    localStorage.setItem("locale", params?.lng ?? "");

    const isUrlFromApiExist = urlFromApi && urlFromApi.routeKey;
    const isSwitchOnCurrentRoute =
      isUrlFromApiExist && urlFromApi.routeKey === pathname;
    if (isUrlFromApiExist && isSwitchOnCurrentRoute && urlFromApi.urls) {
      const structLocals = locales?.map((locale: string) => {
        let urlPathname = urlFromApi.urls[locale.toLowerCase()];
        if(!urlPathname.includes(`${locale}`)){
          urlPathname = `/${locale}/${urlPathname}`
        }
        const structUrl = getURL(urlPathname);
        return {
          url: structUrl,
          locale: locale,
        };
      });
      setLocalsData(structLocals);
    } else {
      const structLocals = locales?.map((locale: string) => {
        const langPrefix = params.lng;
        const path = pathname.split(`/${langPrefix}`).pop();

        return {
          url: `/${locale}/${path}`,
          locale,
        };
      });
      setLocalsData(structLocals);
    }
  }, [pathname, params.lng, urlFromApi]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          "&:hover": {
            background: "none",
          },
        }}
      >
        <LanguageIcon />
        <KeyboardArrowDownIcon
          sx={{
            color: "#101828",
          }}
        />
      </IconButton>
      <Menu
        className="local__dropdown"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {localesData?.map(
          ({ url, locale }: { url: string; locale: string }) => (
            <MenuItem
              key={locale}
              selected={locale === "Pyxis"}
              onClick={handleClose}
              sx={{
                padding: "0px",
              }}
            >
              <span
                onClick={() => {
                 
                 router.replace(url);
                }}
                style={{
                  display: "inline-block",
                  width: "100%",
                  padding: "6px 16px",
                }}
              >
                {locale == "en-us" && "English"}
                {locale == "de-de" && "German"}
              </span>
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};
