import React, { useState, FC, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { CardContent, Typography, Box, Card, Dialog, DialogTitle, DialogContent, Button, DialogActions, IconButton } from '@mui/material';
import { createTheme } from "@mui/material/styles";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        //  background:"rgba(52, 64, 84, 1)", //70%
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),

    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        ['@media (max-width:700px)']: {
            borderRadius: "10px",
            width: "400px !important",
            height: "100%",
            maxHeight: "100%",
            margin: "0px"
        },
        ['@media (min-width:700px)']: {
            borderRadius: "10px",
            width: '400px'
        }


    },

    scrollbarColor: "#6b6b6b #2b2b2b",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "#fff",
        width: "8px"
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: "#eaecf0",
        width: 8
    },
    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
        backgroundColor: "#eaecf0",
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
        backgroundColor: "#eaecf0",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#eaecf0",
    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
        backgroundColor: "#eaecf0",
    },

}));



export const ModalMoreOptionsComponent: FC<any> = ({
    open,
    onclose,
    handleEditMemory
}) => {

    const handleClose = () => {

        onclose();
    };
    const handleMemoryEdit = () => {
        onclose();
        handleEditMemory()
    }
    return (
        <div>
            <BootstrapDialog
                // fullWidth={true}
                maxWidth="md"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                disableScrollLock={true}
            >

                <>
                    <Card sx={{ boxShadow: 'none' }}>

                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <nav aria-label="main mailbox folders">
                                <List sx={{ alignItem: "center", padding: 0 }}>
                                    <ListItem disablePadding >
                                        <ListItemButton sx={{ textAlign: 'center' }}>

                                            <ListItemText primary="Share to" />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ textAlign: 'center' }}>

                                            <ListItemText primary="Manage Access" />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={handleMemoryEdit} sx={{ textAlign: 'center' }}>

                                            <ListItemText primary="Edit Memory" />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ textAlign: 'center' }}>

                                            <ListItemText primary="Edit Moment" />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ textAlign: 'center' }}>

                                            <ListItemText primary="Remove Moment" />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ textAlign: 'center', color: '#fa0000' }}>

                                            <ListItemText primary="Delete" />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ textAlign: 'center' }} onClick={handleClose}>

                                            <ListItemText primary="Cancel" />
                                        </ListItemButton>
                                    </ListItem>

                                </List>
                            </nav>


                        </Box>

                    </Card>
                </>

            </BootstrapDialog>
        </div>
    );
}
