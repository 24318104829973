import { OutlinedInput, OutlinedInputProps, useTheme } from '@mui/material'
import React from 'react';
import { alpha } from '@mui/material/styles';
interface ITextarea extends OutlinedInputProps {
    borderRadius?:string
}
export const InputFieldWithIcon = ({borderRadius="8px",sx,...rest}: ITextarea) => {
    const theme = useTheme()
  return (
    
    <OutlinedInput sx={{
        width:"100%",
        fieldset : {
            // border:"none !important"
            border: '1px solid #D0D5DD',
       },
      //  border: '1px solid #D0D5DD',
       borderRadius: borderRadius,
        '& .MuiInputBase-input': {
           
            position: 'relative',
            backgroundColor: '#ffffff',
           
            width: '100%',
            padding: '10px 14px',
            fontStyle:"normal",
            fontWeight:"400",
            fontSize:"16px",
            lineHeight:"24px",
            color: "#101828",
            // Use the system font instead of the default Roboto font.
            fontFamily: [
              'Inter',
            ].join(','),
           
            
            '&::placeholder':{
               color:theme.palette.grey[500],
               fontSize:"16px",
               fontWeight:"normal"
            }
          },
          '&.Mui-focused': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
          },
          ...sx
    }}
    {...rest}
    />
  )
}
