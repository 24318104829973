import * as React from "react";
import { useTranslation } from "i18n/client";

import { Box } from "@mui/material";
import { ExploreDetailRatingBoxCard } from "@components/molecules";

export const ExploreDetailRatings = ({
  fitnessLevel,
  priceLevel,
  adrenalineLevel,
}: {
  fitnessLevel: string;
  priceLevel: string;
  adrenalineLevel: string;
}) => {
  const { t } = useTranslation("experiences");
  return (
    <Box
      sx={{
        flexDirection: "row",
        gap: "24px",
        display: "flex",

        marginTop: "24px",
      }}
    >
      <ExploreDetailRatingBoxCard
        title={t("fitness")}
        ratings={Number(fitnessLevel)}
      />
      <ExploreDetailRatingBoxCard
        title={t("price")}
        ratings={Number(priceLevel)}
      />
      <ExploreDetailRatingBoxCard
        title={t("adrenaline")}
        ratings={Number(adrenalineLevel)}
      />
    </Box>
  );
};
