import React from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
interface IFeatureCard {
  title: string;
  heading: string;
  subtitle: string;
  buttonText: string;
  showButton: boolean;
  onClick?:() => void;
  buttonId?:string
}
const FeatureCard = ({
  title,
  heading,
  subtitle,
  buttonText,
  showButton,
  onClick,
  buttonId
}: IFeatureCard) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          variant="Text_mdSemibold"
          component="span"
          sx={{
            color: theme.palette.primary.dark,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="heading_md"
          component="h3"
          sx={{
            color: theme.palette.grey[900],
            marginTop: "24px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "24px",
              lineHeight: "32px",
            },
          }}
        >
          {heading}
        </Typography>
        <Typography
          variant="supporting_text_md"
          component="p"
          sx={{
            color: theme.palette.grey[500],
            marginTop: "16px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "16px",
              lineHeight: "24px",
              marginTop: "8px",
            },
          }}
        >
          {subtitle}
        </Typography>
        {showButton && <Button
        id={`landingPage_${buttonId}`}
        onClick={onClick}
          sx={{
            marginTop: "32px",
            background: "#ffffff",
            border: `1px solid ${theme.palette.grey[300]}`,
            width: "191px",
            height: "60px",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "28px",
            color: theme.palette.grey[700],
            textTransform: "unset",
            padding: "0px",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
        >
          {buttonText}
        </Button>}
      </Box>
    </Box>
  );
};
export default FeatureCard;
