"use client";

import React, { useState } from "react";
import {
  FacebookGreyIcon,
  InstagramGreyIcon,
  TwitterGreyIcon,
  TelegramGreyIcon,
  LinkIcon,
} from "@components/atoms";
import Box from "@mui/material/Box";
import GoogleIcon from "@next/components/atoms/svgs/google/google";
import Link from "next/link";

import {
  FacebookShare,
  TwitterShare,
  TelegramShare,
  EmailShare,
  WhatsappShare,
} from "../social-share";
import CopyToClipboard from "react-copy-to-clipboard";
import key from "../../../../config/key";
import { CustomLink } from "../custom-link";
import { useParams } from "next/navigation";
export const RightFooter = () => {
  const [open, setOpen] = useState(false);
  const [copyValue, setCopyValue] = useState({
    value: `${key.NEXT_PUBLIC_DOMAIN}m/${"code"}`,
    copied: false,
    socialCopy: false,
  });
  const params = useParams<{lng: string}>();
  const handleCopy = () => {
    setCopyValue({ ...copyValue, copied: true });
    setTimeout(() => {
      setCopyValue({ ...copyValue, copied: false });
    }, 500);
    setOpen(true);
  };
  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={
          {
            // display: 'flex',
            // alignContent: 'space-between',
          }
        }
      >
        <ul className="socialicons">
          <li>
            <FacebookShare url="local:3000">
              <FacebookGreyIcon />
            </FacebookShare>
          </li>
          <li>
            <WhatsappShare url="local:3000">
              <InstagramGreyIcon />
            </WhatsappShare>
          </li>
          <li>
            <TelegramShare url="local:3000">
              <TelegramGreyIcon />
            </TelegramShare>
          </li>
          <li>
            <TwitterShare url="local:3000">
              <TwitterGreyIcon />
            </TwitterShare>
          </li>
          <li>
            <EmailShare url="local:3000">
              <GoogleIcon />
            </EmailShare>
          </li>
          <li>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <CopyToClipboard
                text={copyValue.value}
                onCopy={() => handleCopy()}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  <LinkIcon />
                </span>
              </CopyToClipboard>
              {copyValue.copied ? (
                <span
                  style={{
                    color: "#E02A81",
                    position: "absolute",
                    top: "-14px",
                    right: "-5px",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontWeight: "600",
                  }}
                >
                  Copied.
                </span>
              ) : null}
            </Box>
          </li>
        </ul>
      </Box>

      <Box
        sx={
          {
            // display: 'flex',
            // alignContent: 'space-between',
          }
        }
      >
        <ul className="footermenu">
          <li>
            <CustomLink href="/missing" locale={params?.lng}>Missing</CustomLink>
          </li>
          <li>
            <CustomLink href="/contact" locale={params?.lng}>Contact</CustomLink>
          </li>
          <li>
            <CustomLink href="/help" locale={params?.lng}>Help</CustomLink>
          </li>
        </ul>
        <ul className="footermenu">
          <li>
            <CustomLink href="/faq" locale={params?.lng}>FAQ</CustomLink>
          </li>
          <li>
            <CustomLink href="/terms-o-fuse" locale={params?.lng}>Terms of Use</CustomLink>
          </li>
          <li>
            <CustomLink href="/imprint" locale={params?.lng}>Imprint</CustomLink>
          </li>
          <li>
            <CustomLink href="/privacy-policy" locale={params?.lng}>Privacy Policy</CustomLink>
          </li>
        </ul>
        
        {
         // TODO @Hassam: Read brand name from central config file
        }
        <p className="footerBrand">©Marveltime. All righs reserved.</p>
      </Box>
    </div>
  );
};
