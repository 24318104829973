import * as React from "react";
import dynamic from "next/dynamic";
import { Box, Typography } from "@mui/material";
// import {
//   ExploreDetailRatingBoxCard,
//   ListWithIcon,
// } from "@components/molecules";

// import {
//   FacebookGreyIcon,
//   InstagramGreyIcon,
//   TwitterGreyIcon,
//   TelegramGreyIcon,
//   LinkIcon,
// } from "@components/atoms";
const FacebookGreyIcon = dynamic(
  () => import("@components/atoms").then((mod) => mod.FacebookGreyIcon),
  {
    loading: () => <p>Loading...</p>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
const InstagramGreyIcon = dynamic(
  () => import("@components/atoms").then((mod) => mod.InstagramGreyIcon),
  {
    loading: () => <p>Loading...</p>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
const TwitterGreyIcon = dynamic(
  () => import("@components/atoms").then((mod) => mod.TwitterGreyIcon),
  {
    loading: () => <p>Loading...</p>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
const TelegramGreyIcon = dynamic(
  () => import("@components/atoms").then((mod) => mod.TelegramGreyIcon),
  {
    loading: () => <p>Loading...</p>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
const LinkIcon = dynamic(
  () => import("@components/atoms").then((mod) => mod.LinkIcon),
  {
    loading: () => <p>Loading...</p>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
// import { CheckBoxStaticIcon } from "@components/atoms";
import getURL from "@next/utils/getURL";
import Link from "next/link";
interface ISocialLinks {
  socialConnection: {
    instagram?: string;
    meta?: string;
    pinterest?: string;
    tikTok?: string;
    whatsapp?: string;
    x?: string;
    youtube?: string;
  };
}
export const SocialLinks = ({ socialConnection }: ISocialLinks) => {
  const { instagram, meta, pinterest, tikTok, whatsapp, x, youtube } =
    socialConnection;
  const domain = getURL("");
  const list = [
    ...(meta
      ? [
          {
            item: "Meta",
            link: meta,
            Component: FacebookGreyIcon,
          },
        ]
      : []),
    ...(instagram
      ? [
          {
            item: "Instagram",
            link: instagram,
            Component: InstagramGreyIcon,
          },
        ]
      : []),
    ...(pinterest
      ? [
          {
            item: "Pinterest",
            link: pinterest,
            Component: TelegramGreyIcon,
          },
        ]
      : []),
    ...(tikTok
      ? [
          {
            item: "TikTok",
            link: tikTok,
            Component: TelegramGreyIcon,
          },
        ]
      : []),
    ...(x
      ? [
          {
            item: "X",
            link: x,
            Component: TwitterGreyIcon,
          },
        ]
      : []),
    ...(youtube
      ? [
          {
            item: "Youtube",
            link: youtube,
            Component: TelegramGreyIcon,
          },
        ]
      : [])
  ];
  return (
    <Box sx={{ marginTop: "24px" }}>
      <Box
        display="flex"
        flexDirection="column"
        gap="15px"
        sx={{
          marginTop: {
            md: "32px",
          },
        }}
      >
        {list.map(({ item, link, Component }: any, index: any) => (
          // These are social links which dont have locale prefix so no need for customLink
          <Link href={link} key={index} target="_blank">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Component />

              <Typography
                variant="Text_smNormal"
                color="#667085"
                sx={{ marginLeft: "12px" }}
              >
                {item}
              </Typography>
            </Box>
          </Link>
        ))}
       
      </Box>
    </Box>
  );
};
