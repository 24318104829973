import { Typography, useTheme } from "@mui/material";

export const NavItemName = ({name,isActive}:{name:string;isActive?:boolean}) => {
    const theme = useTheme()
    return (
      <Typography sx={{
            color: isActive ? "primary.dark" : "",
            display:"none",
            [theme.breakpoints.down("sm")]:{
                 display:"block",
                 fontSize:"12px"
            }
      }} >{name}</Typography>
    )
}
