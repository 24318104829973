import { addNewQuestionForExperience } from '@ApiReq';
import { yupResolver } from '@hookform/resolvers/yup';
import toastify from '@next/utils/toastify';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
interface IFormValuesType {
    name:string;
    email: string;
    question: string;
    termOfServices: boolean
}
const schema = Yup.object({
    name: Yup.string().trim('').required("Name is required").min(2,"Name is required with minimum 2 characters"),
    email:  Yup.string().trim('').required("Name is required").email('Email must be valid'),
    question: Yup.string()
    .trim('')
    .required("Question must not be empty"),
    termOfServices: Yup.boolean().oneOf([true], "Terms and condition are required"),
}).required();
export default function useExperienceAskQuestionModal({id,onClose}:{id:string, onClose: () => void;}) {
    
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<IFormValuesType>({
        resolver: yupResolver(schema),
        defaultValues: {
            name:'',
            email: '',
            question: '',
            termOfServices:false
        },
        mode: 'onChange',
    });
    const handleSendProposal = handleSubmit((data) => {
          
          addNewQuestionForExperience({id,data}).then((res) => {
              
               toastify("success","Thank you! We'll come back soon with an answer.");
               onClose()
           
          }).catch(err => {
            toastify("error",err?.data?.message)
              console.log("err", err)
          })
    })
  return {control,errors,isValid,handleSendProposal}
}
