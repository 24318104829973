import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
export const InputField = styled(InputBase)(({ theme }) => ({
    // 'label + &': {
    //   marginTop: theme.spacing(3),
    // },
    '& .MuiInputBase-input': {
      borderRadius: "8px",
      position: 'relative',
      backgroundColor: '#ffffff',
      border: '1px solid #D0D5DD',
      width: '100%',
      padding: '10px 14px',
      fontStyle:"normal",
      fontWeight:"400",
      fontSize:"16px",
      lineHeight:"24px",
      color: "#101828",
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Inter',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      '&::placeholder':{
         color:theme.palette.grey[500],
         fontSize:"16px",
         fontWeight:"normal"
      }
    },
  }));