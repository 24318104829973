import React, { FC } from "react";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { smallCardProps } from "./small-card.types";

export const SmallCard: FC<smallCardProps> = ({
  userName,
  userMemory,
  userImg,
  userBtnText,
}): JSX.Element => {
  return (
    <CardHeader
      avatar={
        <div style={{ marginRight: 0 }}>
          <Avatar
            alt="Remy Sharp"
            src={userImg}
            sx={{ width: 32, height: 32 }}
          />
        </div>
      }
      action={
        <IconButton aria-label="settings" sx={{ height: "0px" }}>
          <Typography
            sx={{
              fontSize: 14,
              textAlign: "center",
              color: "#e02a81",
              marginBottom: "0px",
              fontWeight: 500,
            }}
            color="text.secondary"
            gutterBottom
          >
            {userBtnText}
          </Typography>
        </IconButton>
      }
      titleTypographyProps="h4"
      title={
        <Typography sx={{ fontSize: "14px", color: "#101828" }}>
          {userName}
        </Typography>
      }
      subheaderTypographyProps="body1"
      subheader={
        <Typography sx={{ fontSize: "12px", color: "#667085" }}>
          {userMemory}
        </Typography>
      }
    />
  );
};
