import React, { FC, forwardRef } from "react";
import { Button } from "@mui/material";
import { ButtonContainedProps } from "./button-contained.types";

const SubmitButton = forwardRef((props: any, ref) => (
  <button ref={ref} {...props} type="submit" />
));

SubmitButton.displayName = "SubmitButton";

export const ButtonContained: FC<ButtonContainedProps> = ({
  children,
  sizeVariant = "medium",
  fontsize,
  fontweight,
  fontstyle,
  lineheight,
  color,
  bg,
  border,
  ptb,
  plr,
  boxshadow,
  clickHandler,
  radius,
  width,
  height,
  disabled,
  id
}): JSX.Element => {
  return (
    <Button
      id={id}
      size={sizeVariant}
      disabled={disabled}
      variant="contained"
      component={SubmitButton}
      sx={{
        backgroundColor: bg || "#E02A81",
        border: border || "1px solid #F62E8E",
        boxShadow: boxshadow || "0px 1px 2px rgba(16, 24, 40, 0.05)",
        borderRadius: radius || "8px",
        width: width || "100%",
        height: height || "100%",
        fontFamily: "Inter",
        fontStyle: fontstyle || "normal",
        fontSize: fontsize || "16px",
        fontWeight: fontweight || "500",
        lineHeight: lineheight || "24px",
        color: color || "#ffffff",
        textTransform: "none",
        "&:hover": {
          backgroundColor: bg || "#E02A81",
          color: color || "#ffffff",
        },
      }}
      onClick={clickHandler}
      // t(`common:${children}`)
    >
      {children}
    </Button>
  );
};
