import { Grid, Skeleton } from "@mui/material";
import React from "react";

const RecommendationsSkeletonLoader = () => {
  return (
   <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Skeleton variant="rounded" height="280px" width="100%" />
        <Skeleton
          variant="text"
          sx={{ width: "80%", lineHeight: 1.5, marginTop: "10px" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Skeleton variant="rounded" height="280px" width="100%" />
        <Skeleton
          variant="text"
          sx={{ width: "80%", lineHeight: 1.5, marginTop: "10px" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Skeleton variant="rounded" height="280px" width="100%" />
        <Skeleton
          variant="text"
          sx={{ width: "80%", lineHeight: 1.5, marginTop: "10px" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Skeleton variant="rounded" height="280px" width="100%" />
        <Skeleton
          variant="text"
          sx={{ width: "80%", lineHeight: 1.5, marginTop: "10px" }}
        />
      </Grid>
      </>
  );
};
export default RecommendationsSkeletonLoader;
