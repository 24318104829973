"use client";

import React, { useState } from "react";
import { CustomTypography, ButtonContained } from "@components/atoms";
import { Box } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  VuesaxIcon,
  SubtractIcon,
  TelegramIcon,
  CopyIcon,
} from "@components/atoms";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAppSelector } from "@hooks";
import key from "../../../../config/key";
import {
  FacebookShare,
  TwitterShare,
  TelegramShare,
  EmailShare,
  WhatsappShare,
} from "../social-share";
export const CreateMemoryStepThree = () => {
  const memories = useAppSelector((state) => state.memories);
  const [open, setOpen] = useState(false);
  const [copyValue, setCopyValue] = useState({
    value: `${key.NEXT_PUBLIC_DOMAIN}m/${memories?.memory?.code}`,
    copied: false,
    socialCopy: false,
  });
  const handleCopy = () => {
    setCopyValue({ ...copyValue, copied: true });
    setTimeout(() => {
      setCopyValue({ ...copyValue, copied: false });
    }, 500);
    setOpen(true);
  };
  const handleSocialCopy = () => {
    setCopyValue({ ...copyValue, socialCopy: true });
    setTimeout(() => {
      setCopyValue({ ...copyValue, socialCopy: false });
    }, 500);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box
      sx={{
        padding: "40px 32px",
      }}
    >
      <Box
        sx={{
          marginBottom: "8px",
        }}
      >
        <CustomTypography
          fontsize="20px"
          lineheight="30px"
          fontweight="500"
          color="#101828"
        >
          Marvelous, your personal memory is ready
        </CustomTypography>
      </Box>
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <CustomTypography
          fontsize="13px"
          lineheight="20px"
          fontweight="500"
          color="#667085"
        >
          Share this with your friends and loved ones
        </CustomTypography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "22px 0px",
          marginBottom: "18px",
          background: "#F9FAFB",
        }}
      >
        <QRCodeSVG value={copyValue.value} />
      </Box>
      <Box
        sx={{
          marginBottom: "12px",
        }}
      >
        <CustomTypography
          fontsize="24px"
          lineheight="32px"
          fontweight="500"
          color="#667085"
        >
          23:59 h
        </CustomTypography>
      </Box>
      <Box
        sx={{
          marginBottom: "18px",
        }}
      >
        <CustomTypography
          fontsize="14px"
          lineheight="20px"
          fontweight="500"
          color="#667085"
        >
          The availability of your memory is limited
        </CustomTypography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "28px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "321px",
          }}
        >
          <ButtonContained id="createMemory_step_three_makeItPersonal">Make it personal</ButtonContained>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "28px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "321px",
            minWidth: "321px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #D0D5DD",
            borderRadius: "8px",
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            padding: "10px 14px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              marginRight: "10px",
              overflow: "hidden",
            }}
          >
            <CustomTypography
              fontsize="14px"
              lineheight="20px"
              fontweight="500"
              color="#667085"
            >
              {copyValue.value}
            </CustomTypography>
          </Box>

          <CopyToClipboard text={copyValue.value} onCopy={() => handleCopy()}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <CopyIcon />
            </span>
          </CopyToClipboard>
          {copyValue.copied ? (
            <span
              style={{
                color: "#E02A81",
                position: "absolute",
                top: "-2px",
                right: "4px",
                fontFamily: "Inter",
                fontSize: "10px",
                fontWeight: "600",
              }}
            >
              Copied.
            </span>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            margin: "0px 6px",
          }}
        >
          <FacebookShare url={copyValue.value}>
            <FacebookIcon />
          </FacebookShare>
        </Box>
        <Box
          sx={{
            margin: "0px 6px",
          }}
        >
          <WhatsappShare url={copyValue.value}>
            <InstagramIcon />
          </WhatsappShare>
        </Box>
        <Box
          sx={{
            margin: "0px 6px",
          }}
        >
          <TelegramShare url={copyValue.value}>
            <TelegramIcon />
          </TelegramShare>
        </Box>
        <Box
          sx={{
            margin: "0px 6px",
          }}
        >
          <TwitterShare url={copyValue.value}>
            <TwitterIcon />
          </TwitterShare>
        </Box>
        <Box
          sx={{
            margin: "0px 6px",
          }}
        >
          <EmailShare url={copyValue.value}>
            <VuesaxIcon />
          </EmailShare>
        </Box>
        <Box
          sx={{
            margin: "0px 6px",
            position: "relative",
          }}
        >
          <CopyToClipboard
            text={copyValue.value}
            onCopy={() => handleSocialCopy()}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <SubtractIcon />
            </span>
          </CopyToClipboard>
          {copyValue.socialCopy ? (
            <span
              style={{
                color: "#E02A81",
                position: "absolute",
                top: "-14px",
                right: "-5px",
                fontFamily: "Inter",
                fontSize: "10px",
                fontWeight: "600",
              }}
            >
              Copied.
            </span>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
