"use client";

import React, { useEffect, useState } from "react";
import {
  ContactListModal,
  ListWithAvatarAndIcon,
  CreateGroupBox,
} from "@components/molecules";
import { Box } from "@mui/material";
import { useAppSelector } from "@hooks";
export const MyGroupsOrganism = () => {
  const [open, setOpen] = React.useState(false);
  const [availaleGroups, setAvailableGroups] = useState([]);

  const { groups } = useAppSelector((state) => state.myContacts);
  useEffect(() => {
    const structGroup = groups.map((group: any) => {
      return {
        id: group.id,
        primary: group.name,
        secondary: group.numberOfMembers,
        image: "",
        isAdmin: group.isAdmin,
      };
    });
    setAvailableGroups(structGroup);
  }, [groups]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          padding: "21px 0px 16px 0px",
        }}
      >
        <CreateGroupBox buttonId="myGroups_create_group_button" btnText="Create group" />
      </Box>
      <ListWithAvatarAndIcon
        handleClickOpen={handleClickOpen}
        data={availaleGroups}
      />
      <ContactListModal open={open} handleClose={handleClose} />
    </>
  );
};
