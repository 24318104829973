import React, {FC} from 'react'
import {InputField} from "./custom-input-field.style";
import {CustomInputFieldProps} from "./custom-input-field.types"
export const CustomInputField : FC<CustomInputFieldProps> = ({
    placeholder,
    name,
    type="text",
    register
}) =>  {
  return (
    !!register ?
    <InputField  type={type} id={name} { ...register(name)}  fullWidth placeholder={placeholder}  />:
    <InputField  type={type} id={name}   fullWidth placeholder={placeholder}  />
  )
}
