import React from 'react'

export const VuesaxIcon = () => {
  return (
    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.927032" y="0.211426" width="28.927" height="26.9986" rx="13.4993" fill="#F858A5"/>
<path d="M19.0064 19.8577H11.7747C9.60513 19.8577 8.15878 18.7729 8.15878 16.2418V11.1796C8.15878 8.64848 9.60513 7.56372 11.7747 7.56372H19.0064C21.176 7.56372 22.6223 8.64848 22.6223 11.1796V16.2418C22.6223 18.7729 21.176 19.8577 19.0064 19.8577Z" stroke="#FCFCFD" strokeWidth="0.867811" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.0064 11.5411L16.7429 13.3491C15.998 13.9421 14.7758 13.9421 14.031 13.3491L11.7747 11.5411" stroke="#FCFCFD" strokeWidth="0.867811" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}
