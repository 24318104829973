"use client";

import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useRouter } from "next/navigation";
import { CustomTypography, WhiteCrossIcon } from "@components/atoms";
import { NavAccountSearch } from "../nav-account-search";
import { MemorySearchAutoComplete } from "../memory-search-auto-complete";
import { SwitchWidthLabel } from "../switch-with-label";
import { UserInfoWidthRole } from "../user-info-width-role";
import { styled } from "@mui/system";

import style from "./create-memory-step-two.style.module.css";
import { CarouselGrid } from "../carousel-grid";
import { ImageUploader } from "../image-uploader";
import { searchData, assignParticipant } from "@ApiReq";
import { useAppSelector } from "@hooks";
import { EditControlSettings } from "@ApiReq";
const CustomImage = styled(Box)`
  background-position: center;
  background-size: cover;
  height: ${(props: any) => props.height};
  max-width: 100%;
`;
const DeleteImage = styled(Box)`
  width: ${(props: any) => props.width};
  height: ${(props: any) => props.height};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 10px;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.7);
`;
export const CreateMemoryStepTwo = ({
  images,
  removeImage,
  isStepTwoNext,
  setIsStepTwoNext,
  onUpload,
  isInvalidFile,
}: any) => {
  const router = useRouter();
  const [showSingleImage, setShowSingleImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [searchValue, setSearchValue] = useState<any>("");
  const [options, setOptions] = useState<any>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [assignedParticipent, setAssignedParticipent] = useState([]);
  const [isPublic, setIsPublic] = useState(true);
  const [allowContribution, setAllowContribution] = useState(true);
  const [allowAnonymousAccess, setAllowAnonymousAccess] = useState(true);
  const [gallery, setGallery] = useState([]);
  useEffect(() => {
    setGallery(images);
  }, [images]);
  const memories = useAppSelector((state: any) => state.memories);
  const auth = useAppSelector((state) => state.auth);
  const loginRedirect = () => {
    router.push("/login");
  };
  const handleSelectedImage = (image: any) => {
    setShowSingleImage(true);
    setSelectedImage(image);
  };
  const handleChange = (val: string) => {
    const value = val;
    searchData({
      loginToken: localStorage.getItem("token") || "",
      item: value,
    })
      .then((res: any) => {
        if (res?.data?.statusCode == 200) {
          setOptions(res?.data?.result?.searchItems);
        }
      })
      .catch((err: any) => {
        console.log("res error", err.message);
      });
  };
  const handleAssignParticipant = (user: any) => {
    assignParticipant({
      loginToken: localStorage.getItem("token") || "",
      memoryId: memories?.memory?.id,
      identifier: user.id,
      identifierType: "User",
      guestName: "",
      isAdmin: isAdmin,
    })
      .then((res: any) => {
        if (res.data) {
          setAssignedParticipent(
            res.data?.result?.participants?.participantsByUser
          );
        }
      })
      .catch((err: any) => {
        console.log("err", err.message);
      });
  };
  const handleEditControlSettings = (
    publicValue: boolean,
    contributionValue: boolean,
    AnonymousValue: boolean
  ) => {
    EditControlSettings({
      loginToken: localStorage.getItem("token") || "",
      memoryId: memories?.memory?.id,
      isPublic: publicValue,
      allowContribution: contributionValue,
      allowAnonymousAccess: AnonymousValue,
    })
      .then((res: any) => {
        console.log("res update access setting");
      })
      .catch((err: any) => {
        console.log("err update access setting");
      });
  };
  const handlePublicChange = (val: boolean) => {
    handleEditControlSettings(val, allowContribution, allowAnonymousAccess);
    setIsPublic(val);
  };
  const handleContributionChange = (val: boolean) => {
    handleEditControlSettings(isPublic, val, allowAnonymousAccess);
    setAllowContribution(val);
  };
  const handleAnonymousChange = (val: boolean) => {
    handleEditControlSettings(isPublic, allowContribution, val);
    setAllowAnonymousAccess(val);
  };
  const restructureCarouselImages = () => {
    const newArray = [...gallery];
    const fromIndex = newArray.findIndex((object: any) => {
      return object.id === selectedImage.id;
    });
    if (fromIndex >= 0) {
      const element = newArray.splice(fromIndex, 1)[0];
      newArray.splice(0, 0, element);
      return newArray;
    }
    return newArray;
  };
  const handleRemoveImage = (val: any) => {
    if (images.length == 1) {
      setShowSingleImage(false);
      setSelectedImage({});
    }
    removeImage(val);
  };
  return (
    <Grid container>
      <Grid
        item
        sx={{
          width: {
            xs: "100%",
            sm: "60%",
          },
        }}
      >
        {images.length > 0 ? (
          <Box
            sx={{
              padding: "10px 0px 0px 0px",
            }}
          >
            {/* <CarouselGrid/> */}
            <Grid
              container
              className={style?.mainContainer}
              spacing={1}
              sx={{
                height: "100%",
                maxHeight: "566px",
                overflow: "scroll",
                overflowX: "hidden",
                display: {
                  xs: "none",
                  sm: "flex",
                },
              }}
            >
              {!showSingleImage ? (
                images.map((image: any, i: any) => {
                  return (
                    <Grid
                      key={i}
                      onClick={() => handleSelectedImage(image.file)}
                      item
                      xs={6}
                      style={{ position: "relative" }}
                    >
                      <CustomImage
                        height="283px"
                        style={{ backgroundImage: `url(${image.file})` }}
                      ></CustomImage>
                      <DeleteImage width="36px" height="36px">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => handleRemoveImage(image.id)}
                        >
                          <WhiteCrossIcon />
                        </Box>
                      </DeleteImage>
                    </Grid>
                  );
                })
              ) : (
                <Grid xs={12} item style={{ position: "relative" }}>
                  <CarouselGrid
                    images={restructureCarouselImages()}
                    removeImage={handleRemoveImage}
                    gridView={() => setShowSingleImage(false)}
                  />

                  {/* <CustomImage height="566px"  style={{backgroundImage:`url(${selectedImage})`}}></CustomImage>
                    <DeleteImage>
                         <Box onClick={()=> setShowSingleImage(false)}>
                         <WhiteCrossIcon/>
                         </Box>
                        
                        </DeleteImage> */}
                </Grid>
              )}
            </Grid>
            {/* <Grid
            container
            spacing={1}
            sx={{
              height: "100%",
              maxHeight: "566px",
              overflow: "scroll",
              scrollBehaviorX: "none",
              display: {
                xs: !isStepTwoNext ? "flex" : "none",
                sm: "none",
              },
            }}
          >
            {!showSingleImage ? (
              images.map((image: any, i: any) => {
                return (
                  <Grid
                    key={i}
                    onClick={() => handleSelectedImage(image.file)}
                    item
                    xs={4}
                    style={{ position: "relative" }}
                  >
                    <CustomImage
                      height="118px"
                      style={{ backgroundImage: `url(${image.file})` }}
                    ></CustomImage>
                    <DeleteImage width="20px" height="20px">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => removeImage(i)}
                      >
                        <WhiteCrossIcon />
                      </Box>
                    </DeleteImage>
                  </Grid>
                );
              })
            ) : (
              <Grid xs={12} item>
                <Box position="relative">
                  <CarouselGrid
                    images={images}
                    removeImage={removeImage}
                    gridView={() => setShowSingleImage(false)}
                  />
                </Box>
                   <Box
                  position="relative"
                  sx={{
                    width: "100%",
                  }}
                >
                  <ImageUploader
                    isButton={true}
                    onUpload={onUpload}
                    padding="0px 20px"
                  />
                </Box>
                {/* <CustomImage height="566px"  style={{backgroundImage:`url("/assets/images/explore/placeholder-memory.png")`}}></CustomImage>
                  <DeleteImage>
                       <Box onClick={()=> setShowSingleImage(false)}>
                       <CrossIcon/>
                       </Box>
                      
                      </DeleteImage> */}
            {/* </Grid>
            )}
          </Grid> */}
          </Box>
        ) : (
          <Box
            position="relative"
            sx={{
              width: "100%",
              mt: "20px",
            }}
          >
            <ImageUploader
              isImage={true}
              onUpload={onUpload}
              padding="0px 20px"
            />
          </Box>
        )}
      </Grid>
      <Grid
        item
        sx={{
          display: {
            xs: isStepTwoNext ? "none" : "block",
            sm: "block",
          },
          width: {
            xs: "100%",
            sm: "40%",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "10px 5px",
          }}
        >
          {!auth.loggedIn && (
            <Box
              sx={{
                marginBottom: "22px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#344054",
                  // marginBottom:"24px"
                }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    color: "#E02A81",
                    cursor: "pointer",
                    // marginBottom:"24px"
                  }}
                  component="span"
                  onClick={loginRedirect}
                >
                  Log in{" "}
                </Typography>
                to make it personal and load your existing contacts
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              marginBottom: "22px",
              position: "relative",
            }}
          >
            <MemorySearchAutoComplete
              onchange={handleChange}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              options={options}
              handleAssignParticipant={handleAssignParticipant}
            />
          </Box>
          <Box
            sx={{
              marginBottom: "22px",
            }}
          >
            <SwitchWidthLabel
              checked={isPublic}
              onchange={handlePublicChange}
              label="Public"
            />
            <SwitchWidthLabel
              checked={allowContribution}
              onchange={handleContributionChange}
              label="Allow other users to contribute with moments"
            />
            <SwitchWidthLabel
              checked={allowAnonymousAccess}
              onchange={handleAnonymousChange}
              label="Allow anonymous access via link"
            />
          </Box>
          <Box
            sx={{
              marginBottom: "22px",
            }}
          >
            <CustomTypography
              fontsize="14px"
              fontweight="normal"
              lineheight="20px"
              color="#667085"
              textalign="left"
            >
              Control setting information, read more
            </CustomTypography>
          </Box>
          <Box
            sx={{
              marginBottom: "22px",
            }}
          >
            <CustomTypography
              fontsize="14px"
              color="#344054"
              lineheight="20px"
              textalign="left"
            >
              Assigned by you
            </CustomTypography>
          </Box>
          {assignedParticipent.map((item: any, i: any) => (
            <UserInfoWidthRole key={i} user={item} />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
