import {Box,Typography } from '@mui/material'
import React, {FC} from 'react'
import { NavAccountProfileUserInfo } from '../nav-account-profile-user-info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MoreIcon } from "@components/atoms";
import { UserInfoWidthRoleProps } from './user-info-width-role.types';
export const UserInfoWidthRole : FC<UserInfoWidthRoleProps> = ({
  user
}) => {
  // image={item.identifierImage} name={item.name} username={item.username}
  return (
   <Box
   sx={{
     display:"flex",
     justifyContent:"space-between",
     alignItems:"center"
   }}
   >
     <NavAccountProfileUserInfo name={user.name} username={user.username} image={user.identifierImage}/>
     <Box sx={{
       display:"flex"
     }}>
{user.isAdmin && <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
          color: "#344054",
          cursor:"pointer",
          background: "#F2F4F7",
          borderRadius: "16px",
          padding: "2px 8px"
          // marginBottom:"24px"
        }}
        component="span"
      >
        {" "}
       Admin
      </Typography>}
      <MoreIcon/>
     </Box>
   </Box>
  )
}
