import React from 'react'

export const GridViewIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.54476 3H2.71143V8.83333H8.54476V3Z" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.7114 3H11.8781V8.83333H17.7114V3Z" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.7114 12.1667H11.8781V18H17.7114V12.1667Z" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.54476 12.1667H2.71143V18H8.54476V12.1667Z" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
