"use client";
import React from "react";
import { useTranslation } from "i18n/client";

import { Grid, Typography, useTheme, Box } from "@mui/material";
import SectionLayout from "../section-layout/section-layout";
import FeatureCard from "../feature-card/feature-card";
import { Image } from "@components/atoms";
import { useResponsiveLayout } from "@hooks";
import systemConfig from "@next/config/system.config";

const HowItWorksSection = ({
  handleRatingModalOpen,
}: {
  handleRatingModalOpen: () => void;
}) => {
  const theme = useTheme();
  const { isMobile } = useResponsiveLayout();
  const { t } = useTranslation("landing-page");
  const {
    LANDING_PAGE: {
      RATE_YOUR_INTEREST,
      CONNECT_WITH_LOVED_ONES,
      DISCOVER_MATCHING_EXPERIENCES,
      EXPERIENCE_THE_UNKNOWN,
      BUILD_LASTING_MEMORIES,
    },
  } = systemConfig;
  return (
    <Grid
      container
      sx={{
        padding: { xs: "64px 16px", sm: "96px 80px" },
        rowGap: { xs: "0px", sm: "48px" },
        background: theme.palette.grey[50],
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            maxWidth: "935px",
            margin: "auto",
          }}
        >
          <Typography
            variant="Text_mdSemibold"
            component="p"
            sx={{
              color: theme.palette.primary.dark,
              textAlign: "center",
            }}
          >
            {t("how_it_works.title")}
          </Typography>
          <Typography
            variant="heading"
            component="h2"
            sx={{
              color: theme.palette.grey[900],
              textAlign: "center",
              marginTop: "12px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "32px",
                lineHeight: "44px",
              },
            }}
          >
            {t("how_it_works.heading")}
          </Typography>
          <Typography
            variant="supporting_text"
            component="p"
            sx={{
              color: theme.palette.grey[500],
              textAlign: "center",
              marginTop: "20px",
              [theme.breakpoints.down("sm")]: {
                fontSize: "18px",
                lineHeight: "28px",
              },
            }}
          >
            {t("how_it_works.subtitle")}
          </Typography>
        </Box>
      </Grid>
      <SectionLayout
        left={
          <FeatureCard
            title={t("how_it_works.rate_your_interests.title")}
            heading={t("how_it_works.rate_your_interests.heading")}
            subtitle={t("how_it_works.rate_your_interests.subtitle")}
            buttonText={t("how_it_works.rate_your_interests.button")}
            showButton={RATE_YOUR_INTEREST.SHOW_BUTTON}
            onClick={handleRatingModalOpen}
            buttonId="how_it_works_rate_your_interests_button"
          />
        }
        right={
          <Box>
            <Image
              src={t("assets.images.image_two.file")}
              alt={t("assets.images.image_two.alt")}
              title={t("assets.images.image_two.title")}
              width={496}
              height={451}
            />
          </Box>
        }
        buttonText={t("how_it_works.rate_your_interests.button")}
        showButton={RATE_YOUR_INTEREST.SHOW_BUTTON}
        buttonId="how_it_works_rate_your_interests_button"
      />
      <SectionLayout
        left={
          <Box>
            <Image
              src={t("assets.images.image_three.file")}
              alt={t("assets.images.image_three.alt")}
              title={t("assets.images.image_three.title")}
              width={533}
              height={500}
            />
          </Box>
        }
        right={
          <FeatureCard
            title={t("how_it_works.connect_with_loved_ones.title")}
            heading={t("how_it_works.connect_with_loved_ones.heading")}
            subtitle={t("how_it_works.connect_with_loved_ones.subtitle")}
            buttonText={t("how_it_works.connect_with_loved_ones.button")}
            showButton={CONNECT_WITH_LOVED_ONES.SHOW_BUTTON}
            buttonId="how_it_works_connect_with_loved_ones_button"
          />
        }
        buttonText={t("how_it_works.connect_with_loved_ones.button")}
        showButton={CONNECT_WITH_LOVED_ONES.SHOW_BUTTON}
        buttonId="how_it_works_connect_with_loved_ones_button"
        {...(isMobile
          ? { gridOneProps: { order: 1 }, gridTwoProps: { order: 0 } }
          : {})}
      />
      <SectionLayout
        left={
          <FeatureCard
            title={t("how_it_works.discover_matching_experiences.title")}
            heading={t("how_it_works.discover_matching_experiences.heading")}
            subtitle={t("how_it_works.discover_matching_experiences.subtitle")}
            buttonText={t("how_it_works.discover_matching_experiences.button")}
            showButton={DISCOVER_MATCHING_EXPERIENCES.SHOW_BUTTON}
            buttonId="how_it_works_discover_matching_experiences_button"
          />
        }
        right={
          <Box>
            <Image
              src={t("assets.images.image_four.file")}
              alt={t("assets.images.image_four.alt")}
              title={t("assets.images.image_four.title")}
              width={672}
              height={512}
            />
          </Box>
        }
        buttonText={t("how_it_works.discover_matching_experiences.button")}
        showButton={DISCOVER_MATCHING_EXPERIENCES.SHOW_BUTTON}
        buttonId="how_it_works_discover_matching_experiences_button"
      />
      <SectionLayout
        left={
          <Box>
            <Image
              src={t("assets.images.image_five.file")}
              alt={t("assets.images.image_five.alt")}
              title={t("assets.images.image_five.title")}
              width={571}
              height={512}
            />
          </Box>
        }
        right={
          <FeatureCard
            title={t("how_it_works.experience_the_unknown.title")}
            heading={t("how_it_works.experience_the_unknown.heading")}
            subtitle={t("how_it_works.experience_the_unknown.subtitle")}
            buttonText={t("how_it_works.experience_the_unknown.button")}
            showButton={EXPERIENCE_THE_UNKNOWN.SHOW_BUTTON}
            buttonId="how_it_works_experience_the_unknown_button"
          />
        }
        buttonText={t("how_it_works.experience_the_unknown.button")}
        showButton={EXPERIENCE_THE_UNKNOWN.SHOW_BUTTON}
        buttonId="how_it_works_experience_the_unknown_button"
        {...(isMobile
          ? { gridOneProps: { order: 1 }, gridTwoProps: { order: 0 } }
          : {})}
      />
      <SectionLayout
        left={
          <FeatureCard
            title={t("how_it_works.build_lasting_memories.title")}
            heading={t("how_it_works.build_lasting_memories.heading")}
            subtitle={t("how_it_works.build_lasting_memories.subtitle")}
            buttonText={t("how_it_works.build_lasting_memories.button")}
            showButton={BUILD_LASTING_MEMORIES.SHOW_BUTTON}
            buttonId="how_it_works_build_lasting_memories_button"
          />
        }
        right={
          <Box>
            <Image
              src={t("assets.images.image_six.file")}
              alt={t("assets.images.image_six.alt")}
              title={t("assets.images.image_six.title")}
              width={571}
              height={512}
            />
          </Box>
        }
        buttonText={t("how_it_works.build_lasting_memories.button")}
        showButton={BUILD_LASTING_MEMORIES.SHOW_BUTTON}
        buttonId="how_it_works_build_lasting_memories_button"
      />
    </Grid>
  );
};
export default HowItWorksSection;
