import React from "react";

export const  multilineTranslation = (translatedString:string) =>  {
    const toStringArray = translatedString.split('\n\n');
    const formattedString = toStringArray.map((text, index) => (
        <React.Fragment key={index}>
          {text}
          {index < (toStringArray.length - 1) && <br />}
        </React.Fragment>
      ));
    
      return formattedString
  }