import { Stack, Typography, useTheme } from '@mui/material'
import React, { FC } from 'react'
interface ISettingsPageHeader {
    heading: string;
    subtitle: string
}
export const SettingsPageHeader : FC<ISettingsPageHeader> = ({
    heading,
    subtitle
}) => {
    const theme = useTheme()
  return (
    <Stack rowGap="4px" pb="20px" sx={{
          borderBottom: `1px solid ${theme.palette.grey[200]}`
    }}>
        <Typography component="h2" variant='Text_lgMedium' sx={{color:theme.palette.grey[900]}}>{heading}</Typography>
        <Typography component="p" variant='Text_smNormal' sx={{color:theme.palette.grey[500]}}>{subtitle}</Typography>
    </Stack>
  )
}
