import React from "react";

export const FeaturedIconTwo = () => {
  return (
    <svg
      width="102"
      height="101"
      viewBox="0 0 102 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="7.19971" width="86" height="86" rx="43" fill="#FCBEDC" />
      <rect
        x="8"
        y="7.19971"
        width="86"
        height="86"
        rx="43"
        stroke="#FEEAF4"
        strokeWidth="14.3333"
      />
      <path
        d="M36.7077 69.1997V61.8159L50.8108 50.1494C51.7461 49.3741 52.546 48.648 53.2105 47.9712C53.8874 47.282 54.4043 46.5744 54.7611 45.8483C55.1303 45.1222 55.3149 44.3162 55.3149 43.4301C55.3149 42.4579 55.1057 41.6272 54.6873 40.9381C54.2812 40.2489 53.7151 39.7197 52.989 39.3505C52.2629 38.969 51.4261 38.7783 50.4785 38.7783C49.5309 38.7783 48.6941 38.969 47.968 39.3505C47.2542 39.732 46.7005 40.292 46.3066 41.0304C45.9128 41.7687 45.7159 42.6671 45.7159 43.7255H35.9693C35.9693 41.0673 36.5661 38.7783 37.7599 36.8585C38.9536 34.9387 40.6396 33.4619 42.8178 32.4282C44.996 31.3945 47.5496 30.8776 50.4785 30.8776C53.5059 30.8776 56.1272 31.3637 58.3423 32.3359C60.5698 33.2958 62.2865 34.6495 63.4925 36.397C64.7109 38.1445 65.32 40.1935 65.32 42.5441C65.32 43.9962 65.0185 45.4422 64.4155 46.8821C63.8125 48.3096 62.7295 49.891 61.1666 51.6262C59.6037 53.3614 57.3824 55.4288 54.5027 57.8286L50.9585 60.7821V61.0036H65.7261V69.1997H36.7077Z"
        fill="#FEEAF4"
      />
    </svg>
  );
};
