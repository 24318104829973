"use client";

import React, { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import Link from "next/link";
import { VisitProviderModal } from "@next/components/atoms/modal/visit-provider-modal";
// import { Trans, useTranslation } from "next-i18next";
import { CustomLink } from "../custom-link";
import { useParams } from "next/navigation";
import { useTranslation } from "i18n/client";
import { Trans } from "react-i18next";

const styleLink = {
  fontWeight: "600",
};
export const ImageCopyrightText = ({ url,showAds }: { url: string; showAds?:boolean }) => {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const params = useParams<{lng: string}>()
  const { t } = useTranslation("common");
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <Typography
        sx={{
          color: theme.palette.grey[500],
          fontSize: "14px",
        }}
      >
        <Trans
          i18nKey="image_may_be_subjected_to_copyright"
          values={{}}
          components={[
            <CustomLink key="0" href="/legal-copyright" locale={params?.lng}></CustomLink>, // For <0>no</0>
            <Typography
              key="1"
              component="span"
              sx={{ ...styleLink, fontSize: "14px" }}
            ></Typography>, // For <1>#</1>
            <Typography
              key="2"
              component="span"
              onClick={handleOpenModal}
              sx={{ ...styleLink, cursor: "pointer", fontSize: "14px" }}
            ></Typography>, // For <1>#</1>
          ]}
          t={t}
        />
      </Typography>
      {openModal && (
        <VisitProviderModal open={openModal} onClose={handleClose} url={url} showAds={showAds}/>
      )}
    </>
  );
};
