import React, { FC } from 'react'
import { Typography, useTheme } from '@mui/material';
interface IPrivacyPolicySectionContent {
    children: React.ReactNode | string;
    mb?:string
}
export const PrivacyPolicySectionContent: FC<IPrivacyPolicySectionContent> = ({children,...rest}) => {
    const theme = useTheme()
  return (
    <Typography
                component="p"
                variant="Text_lgNormal"
                color={theme.palette.grey[500]}
                {...rest}
              >
                {children}
              </Typography>
  )
}
