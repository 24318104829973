import React from "react";
import { useTranslation } from "i18n/client";

import { Box } from "@mui/material";
import { ButtonContained, CustomTypography } from "@components/atoms";
import systemConfig from "@next/config/system.config";

export const ExploreSectionTwo = ({ setRatingModalOpen }: any) => {
  const { t } = useTranslation("explore");
  return (
    <Box
      sx={{
        // Test: Deployment test for development environment
        backgroundImage:
          'url("/assets/images/explore/collection-of-your-best-moments-in-life.webp")',
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "800px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {systemConfig.EXPLORE.HAS_START_RATING_EXPERIENCES && (
        <Box
          sx={{
            flex: "0 1 500px",
            background: "#fff",
            borderRadius: "12px",
            boxShadow:
              "0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08)",
            padding: "24px",
          }}
        >
          <Box
            sx={{
              marginBottom: "8px",
            }}
          >
            <CustomTypography fontsize="36px" color="#101828" lineheight="1.22">
              {t("start_rating_card.heading")}
            </CustomTypography>
          </Box>
          <Box
            sx={{
              marginBottom: "32px",
            }}
          >
            <CustomTypography
              fontsize="20px"
              color="#667085"
              lineheight="1.5"
              fontweight="normal"
            >
              {t("start_rating_card.subtitle")}
            </CustomTypography>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <ButtonContained id="explore_start_rating_card_btn" clickHandler={setRatingModalOpen}>
              {t("start_rating_card.btn")}
            </ButtonContained>
          </Box>
        </Box>
      )}
    </Box>
  );
};
