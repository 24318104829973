import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { Box, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ButtonContained, ButtonOutlined } from '@components/atoms';
import { styled } from '@mui/system';
const CustomListItemText = styled(ListItemText)`
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }
`;
export const ListWithAvatarAndButton = () => {
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper',paddingBottom:"0px" }}>
            {[1, 2, 3].map((item: any, i: any) => {
                return (
                    <ListItem
                        sx={{
                            padding: "16px 16px",
                            borderBottom: "1px solid #eaecf0"
                        }}
                        key={i}
                        secondaryAction={
                            // <IconButton edge="end" aria-label="menu">
                            //     <MoreVertIcon />
                            // </IconButton>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{
                                    marginRight: "8px"
                                }}>
                                    <ButtonOutlined id="delete" fontsize='14px' color="#344054" border="1px solid rgba(16, 24, 40, 0.05)" sizeVariant='small'>Delete</ButtonOutlined>
                                </Box>

                                <ButtonContained id="confirm" fontsize='14px'  sizeVariant='small'>Confirm</ButtonContained>
                            </Box>
                        }
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <ImageIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <CustomListItemText primary="Olivia Rhye" secondary="username" />
                    </ListItem>
                )
            })}
        </List>
    );
}
