"use client";

import React from "react";
// import { useRouter } from "next/navigation";
// import { useTranslation } from "i18n/client";
import { useAppSelector } from "@next/hooks";

import { Box, Grid } from "@mui/material";
import { CustomLink, NavbarItems } from "@components/molecules";
import { NavAccount } from "@components/molecules";
import Link from "next/link";

// import { Button } from "@mui/material";
// import { ButtonContained, ButtonTexted, LogoBlack } from "@components/atoms";
import { NavDropdown } from "@components/molecules";
import { LogoBlack } from "@components/atoms";
import { useParams } from "next/navigation";

export const Navbar = (): JSX.Element => {
  const { loggedIn } = useAppSelector((state) => state.auth);
  const params = useParams<{lng: string}>();
  // const { t } = useTranslation();
  // const router = useRouter();
  // const signupRedirect = () => {
  //   router.push("/signup");
  // };
  // const loginRedirect = () => {
  //   router.push("/login");
  // };
  return (
    <Grid
      container
      sx={{
        height: "60px",
        borderBottom: "1px solid #EAECF0",
      }}
    >
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "25px",
        }}
      >
        <CustomLink href="/" locale={params?.lng}>
          <LogoBlack />
        </CustomLink>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
        }}
      >
        <NavbarItems />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {loggedIn ? (
          <NavAccount />
        ) : (
          <>
            <Box
              sx={{
                marginRight: "23px",
              }}
            >
              <NavDropdown />
            </Box>
            {/* <Box
              sx={{
                marginRight: "23px",
              }}
            >
              <ButtonTexted
                clickHandler={loginRedirect}
                sizeVariant="small"
                fontsize="16px"
              >
                {t("common:Login.Name")}
              </ButtonTexted>
            </Box>
            <Box
              sx={{
                marginRight: "23px",
              }}
            >
              <ButtonContained
                clickHandler={signupRedirect}
                sizeVariant="small"
                fontsize="16px"
              >
                {t("common:Register.Name")}
              </ButtonContained>
            </Box> */}
          </>
        )}
      </Grid>
    </Grid>
  );
};
