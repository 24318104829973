"use-client";
import * as React from "react";
import { useTranslation } from "i18n/client";

import Box from "@mui/material/Box";

import { CustomTypography } from "@components/atoms";
import { CrossIcon, ButtonContained, ButtonTexted } from "@components/atoms";

import { EmojiRating } from "../emoji-rating";
import { addRating, getRatings } from "@ApiReq";
import { IUnratedExperiences } from "@types";
import RatingModalCarousel from "./rating-modal-carousel";
import { Skeleton, useTheme } from "@mui/material";
import Image from "next/image";

export const RatingModalStepper = ({ onclose }: { onclose: any }) => {
  const [limit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [totalFeedback, setTotalFeedback] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [ratings, setRatings] = React.useState<IUnratedExperiences>([]);
  const { t } = useTranslation("explore");
  const { t: t2 } = useTranslation("landing-page");
  const theme = useTheme();
  const callGetRatings = () => {
    setIsLoading(true);
    getRatings({
      userId: sessionStorage.getItem("AccountId") || "",
      pageSize: limit,
    })
      .then((res: any) => {
        setIsLoading(false);
        console.log("rating response", res);
        const result = res.data;
        if (result && result.length > 0) {
          setRatings([...ratings, ...result]);
          setTotalFeedback(res?.pagination?.total);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const hasFetched = React.useRef(false);
  React.useEffect(() => {
    if (!hasFetched.current) {
      callGetRatings();
      hasFetched.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLoadMore = () => {
    callGetRatings();
  };
  const handleNext = (interestLevel: number) => {
    const accountId = sessionStorage.getItem("AccountId") || "";
    addRating({
      accountId: accountId,
      experienceId: ratings[activeStep].id,
      interestLevel: interestLevel,
      isOnBucketList: false,
      isPersonalPassion: false,
    }).then((res: any) => ({}));

    const isLastStep = activeStep == ratings.length - 1;

    if (isLastStep && totalFeedback) {
      setPage((prevPage) => prevPage + 1);
      handleLoadMore();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const ratingCard = () => {
    return (
      <>
        <Box
          sx={{
            position: "relative",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              left: "24px",
              cursor: "pointer",
              zIndex: 3,
              [theme.breakpoints.down("sm")]: {
                left: "12px",
              },
            }}
            onClick={onclose}
          >
            <CrossIcon />
          </Box>

          <CustomTypography fontsize="14px" color="#101828" lineheight="1.43">
            {t("start_rating_modal.header.title")}
          </CustomTypography>
        </Box>
        <Box
          sx={{
            padding: {
              xs: "0px",
              sm: "24px",
            },
            background: "#f9fafb",
          }}
        >
          <Box
            sx={{
              marginBottom: "14px",
            }}
          >
            <RatingModalCarousel
              images={ratings[activeStep]?.assets?.images ?? []}
              isLoading={isLoading}
            />
          </Box>
          <Box
            sx={{
              marginBottom: "18px",
            }}
          >
            <CustomTypography fontsize="14px" color="#101828" lineheight="1.43">
              {ratings[activeStep]?.name}
            </CustomTypography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <EmojiRating handleNext={handleNext} />
          </Box>
        </Box>
      </>
    );
  };
  return (
    <Box sx={{ width: "100%" }}>
      {activeStep == ratings.length && !isLoading ? (
        <React.Fragment>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "30px",
                left: "0px",
                width: "100%",
                height: "calc(100% - 30px)",
                backgroundImage:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%)",
                boxShadow:
                  "0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "24px",
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  marginBottom: "8px",
                }}
              >
                {/* TODO: Enable it when private area available */}
                {/* <CustomTypography
                  fontsize="36px"
                  lineheight="1.22"
                  color="#fcfcfd"
                >
                  {t("start_rating_modal.heading")}
                </CustomTypography> */}
              </Box>
              <Box
                sx={{
                  marginBottom: "32px",
                }}
              >
                <CustomTypography
                  fontsize="20px"
                  lineheight="1.5"
                  color="#fcfcfd"
                >
                  {/* TODO: Enable it when private area available */}
                  {/* {t("start_rating_modal.subtitle")} */}
                  Thank you for the definition of your interests! We will try to
                  find now the best experiences for you
                </CustomTypography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: "24px",
                }}
              >
                {/* TODO: Enable it when private area available */}
                {/* <Box
                  sx={{
                    marginBottom: "12px",
                  }}
                >
                  <ButtonContained id="start_rating_modal_register">
                    {t("start_rating_modal.register")}
                  </ButtonContained>
                </Box>
                <Box>
                  <ButtonContained id="start_rating_modal_login" bg="#fff" color="#344054" border="none">
                    {t("start_rating_modal.login")}
                  </ButtonContained>
                </Box> */}
              </Box>
              {/* TODO: Enable it when private area available */}
              {/* <Box>
                  
                <ButtonTexted
                  id="start_rating_modal_continue_rating"
                  color="#e02a81"
                >
                  {t("start_rating_modal.continue_rating")}
                </ButtonTexted>
              </Box> */}
            </Box>
            <Box
              sx={{
                position: "relative",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "10px",
                  left: "12px",
                  cursor: "pointer",
                }}
                onClick={onclose}
              >
                <CrossIcon />
              </Box>

              <CustomTypography
                fontsize="14px"
                color="#101828"
                lineheight="1.43"
              >
                {ratings[activeStep - 1]?.name}
              </CustomTypography>
            </Box>
            <Box
              sx={{
                padding: {
                  xs: "0px",
                  sm: "24px",
                },
                background: "#f9fafb",
              }}
            >
              <Image
                loading="eager"
                src={t2("assets.images.image_one.file")}
                alt={t2("assets.images.image_one.alt")}
                title={t2("assets.images.image_one.title")}
                width={720}
                height={400}
                style={{
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box>{ratingCard()}</Box>
        </React.Fragment>
      )}
    </Box>
  );
};
