"use client";

import React from "react";
import { useParams, useRouter } from "next/navigation";
import { useTranslation } from "i18n/client";
import { Grid, Typography, Box, useTheme, Button } from "@mui/material";
import systemConfig from "@next/config/system.config";
import Image from "next/image";
import { useResponsiveLayout } from "@hooks";

const HeroSection = ({handleRatingModalOpen}:{handleRatingModalOpen:() => void}) => {
  const { t } = useTranslation("landing-page");
  const theme = useTheme();
  const router = useRouter();
const params = useParams();
const {isMobile} = useResponsiveLayout()
  const {
    LANDING_PAGE: {
      HERO_SECTION: { SHOW_BUTTONS, SHOW_RATE_NOW, SHOW_EXPLORE_IDEAS },
    },
  } = systemConfig;

  return (
    <Grid
      container
      sx={{
        marginBottom: { xs: "64px", sm: "0px" },
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          padding: { xs: "64px 16px 0px", sm: "220px 64px 0px 0px" },
          marginBottom: { xs: "64px", sm: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              maxWidth: "544px",
            }}
          >
            <Typography
              variant="heading"
              component="h1"
              sx={{
                color: theme.palette.grey[900],
                [theme.breakpoints.down("sm")]: {
                  fontSize: "32px",
                  lineHeight: "44px",
                  textAlign: { xs: "center", sm: "left" },
                },
              }}
            >
              {t("hero_section.heading")}
            </Typography>
            <Typography
              variant="supporting_text"
              component="p"
              sx={{
                marginTop: { xs: "16px", sm: "24px" },
                color: theme.palette.grey[500],
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              {t("hero_section.subtitle")}
            </Typography>
            {SHOW_BUTTONS && (
              <Box
                sx={{
                  display: "flex",
                  columnGap: "16px",
                  marginTop: { xs: "32px", sm: "48px" },
                  flexDirection: { xs: "column", sm: "row" },
                  rowGap: "12px",
                }}
              >
                {SHOW_RATE_NOW && (
                  <Button
                    id="landingPage_hero_section_rate_now"
                    variant="contained"
                    onClick={handleRatingModalOpen}
                    sx={{
                      width: { xs: "100%", sm: "157px" },
                      height: "60px",
                      textTransform: "unset",
                      padding: "0px",
                    }}
                  >
                    {t("hero_section.rate_now")}
                  </Button>
                )}
                {SHOW_EXPLORE_IDEAS && (
                  <Button
                    id="landingPage_hero_section_explore_ideas"
                    onClick={() => router.push(`/${params.lng}/explore`)}
                    variant="outlined"
                    sx={{
                      width: { xs: "100%", sm: "157px" },
                      height: "60px",
                      textTransform: "unset",
                      padding: "0px",
                      border: `1px solid ${theme.palette.grey[300]}`,
                      color: theme.palette.grey[700],
                    }}
                  >
                    {t("hero_section.explore_ideas")}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <picture>
      <img
          loading="eager"
          src={t("assets.images.image_one.file")}
          alt={t("assets.images.image_one.alt")}
          title={t("assets.images.image_one.title")}
         
          style={{
            maxWidth: "100%",
            width:"auto",
               height:"auto",
               objectFit:"contain",
            ...(!isMobile && {
              width:"720px",
              height:"837px",
               objectFit:"none"
            })
          }}
        />
        </picture>
      </Grid>
    </Grid>
  );
};
export default HeroSection;
