import React, { FC, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";
import { getRatings } from "@ApiReq";
import dynamic from "next/dynamic";
import { IUnratedExperiences } from "@types";

const RatingModalStepper = dynamic(
  () => import("@components/molecules").then((mod) => mod.RatingModalStepper),
  {
    loading: () => <p>Loading...</p>,
    ssr: false, // Note: Changed to false since we're handling named exports manually
  }
);
const BootstrapDialog = styled(Dialog)(({}) => ({
  "& .MuiDialog-container": {
    //  background:"rgba(52, 64, 84, 1)", //70%
  },
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    // padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "500px",
      borderRadius: "10px",
      padding:"0px",
      background:"#f9fafb",
    ["@media (max-width:700px)"]: {
      borderRadius: "0px",
      width: "100% !important",
      height: "100%",
      maxHeight: "100%",
      margin: "0px",
    },
    // ["@media (min-width:700px)"]: {
    //   width: "100%",
    //   maxWidth: "500px",
    //   borderRadius: "10px",
    // },
  },

  scrollbarColor: "#6b6b6b #2b2b2b",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#eaecf0",
    width: 8,
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#eaecf0",
  },
}));

export const RatingModal: FC<any> = ({ open, onclose }) => {

  const handleClose = () => {
    onclose();
  };

  return (
    <div>
      <BootstrapDialog
        // fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
      >
        <RatingModalStepper onclose={handleClose} />
      </BootstrapDialog>
    </div>
  );
};
