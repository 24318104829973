"use client";

import { useParams } from "next/navigation";
import i18next, { KeyPrefix } from "i18next";
import {
  UseTranslationOptions,
  initReactI18next,
  useTranslation as useTranslationOrg,
} from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { cookieName, getOptions, languages } from "./settings";
import { setCookie } from "cookies-next";

const runsOnServerSide = typeof window === "undefined";

//
i18next
  .use(initReactI18next)
  .use(LanguageDetector) 
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./locales/${language}/${namespace}.json`)
    )
  )
  .init({
    ...getOptions(),
    load: "currentOnly",
    lng: undefined, // let detect the language on client side
    detection: {
      order: ["path", "cookie", "navigator","htmlTag"],
    },
    preload: runsOnServerSide ? languages : [],
  });

export function useTranslation(
  ns?: string | string[],
  options?: UseTranslationOptions<KeyPrefix<string>>
) {
  const { lng } = useParams<{ lng: string }>();
  const ret = useTranslationOrg(ns, options);

  if (lng && ret.i18n.resolvedLanguage !== lng) {
    ret.i18n.changeLanguage(lng);

    setCookie(cookieName, lng);
  }

  return ret;
}
