import React from 'react'

export const WarningIcon = () => {
    return (
        <div>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#la1spoz8sa)">
                    <path d="M8.5 11.165V8.5m0-2.667h.007m7.076 2.667c0 3.682-3.171 6.666-7.083 6.666-3.912 0-7.083-2.984-7.083-6.666 0-3.682 3.17-6.667 7.083-6.667 3.912 0 7.083 2.985 7.083 6.667z" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="la1spoz8sa">
                        <path fill="#fff" transform="translate(0 .5)" d="M0 0h17v16H0z" />
                    </clipPath>
                </defs>
            </svg>

        </div>
    )
}
