"use client";

import React, { useState, FC, useEffect } from "react";
// import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { ImageUploader, CreateMemory } from "@components/molecules";
import { validateFile } from "@utils";
import { useAppDispatch, useAppSelector } from "@hooks";
import { addMomentAssets, removeMomentAssets } from "@ApiReq";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    //  background:"rgba(52, 64, 84, 1)", //70%
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    minHeight: "610px",
    ["@media (max-width:700px)"]: {
      borderRadius: "0px",
      width: "100% !important",
      height: "100%",
      maxHeight: "100%",
      margin: "0px",
    },
    ["@media (min-width:700px)"]: {
      borderRadius: "12px",
    },

    // height:"100%",
    // maxHeight:"70%"
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        textAlign: "center",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const CustomDialog: FC<any> = ({ open, onclose, isEdit }) => {
  const [isImagesUploaded, setIsImagesUploaded] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [momentAssets, setMomentAssets] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [memory, setMemory] = useState<any>({});
  const memories: any = useAppSelector((state) => state.memories);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);
  useEffect(() => {
    if (isEdit) {
      setIsImagesUploaded(true);
    }
  }, [open]);
  const handleClickOpen = () => {};
  const handleClose = () => {
    setIsImagesUploaded(false);
    setFiles([]);
    setMomentAssets([]);
    onclose();
  };
  const handleImageUpload = async (image: any, momentId: any) => {
    const isValid = validateFile(image);
    const currentMomentId = memories.memory?.momentId;
    if (isValid) {
      setFiles((prevFiles: any) => {
        return [
          ...prevFiles,
          {
            file: image,
            preview: URL.createObjectURL(image),
          },
        ];
      });
      const formData = new FormData();
      formData.append("assets", image, image.name);
      addMomentAssets({
        loginToken: localStorage.getItem("token") || "",
        momentId: currentMomentId,
        assets: formData,
      })
        .then((res: any) => {
          if (res?.data?.result) {
            setIsImagesUploaded(true);
            setIsInvalidFile(false);
            setMomentAssets((prevAssets: any) => {
              return [...prevAssets, ...res?.data?.result?.assets];
            });
          }
        })
        .catch((err: any) => {
          console.log("error");
        });
    } else {
      setIsInvalidFile(true);
    }
  };
  const handleRemoveImage = (imageIndex: any) => {
    const newFiles = files.filter((item: any, i: any) => i != imageIndex);

    removeMomentAssets({
      loginToken: localStorage.getItem("token") || "",
      id: imageIndex,
    })
      .then((res: any) => {
        if (res?.data?.result) {
          setMomentAssets(res?.data?.result?.assets);
        }
      })
      .catch((err: any) => {
        console.log("error");
      });
  };

  return (
    <div>
      <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
      >
        {!isImagesUploaded ? (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => handleClose()}
            >
              Create Moment
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <ImageUploader
                onUpload={handleImageUpload}
                isInvalidFile={isInvalidFile}
              />
            </DialogContent>
          </>
        ) : (
          <>
            <CreateMemory
              images={momentAssets}
              isInvalidFile={isInvalidFile}
              removeImage={handleRemoveImage}
              onclose={() => handleClose()}
              onUpload={handleImageUpload}
            />
          </>
        )}
      </BootstrapDialog>
    </div>
  );
};
