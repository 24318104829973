import React from "react";
import { useTranslation } from "i18n/client";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import systemConfig from "@next/config/system.config";
import { FacebookGreyIcon } from "@components/atoms";
import GoogleIcon from "@next/components/atoms/svgs/google/google";

export const AuthSocialButtons = (): JSX.Element => {
  const { t } = useTranslation("auth");

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {systemConfig.AUTHENTICATION.SHOW_SOCIAL_LOGIN_GOOGLE && (
        <Box
          sx={{
            marginBottom: "12px",
            width: "100%",
          }}
        >
          <Button
            id="authSocialButton_login_google"
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #D0D5DD",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              borderRadius: "8px",
              width: "100%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              color: "#98a2b3",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#ffffff",
                color: "#98a2b3",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  marginRight: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <GoogleIcon />
              </Box>
              <span>{t("Login.google")}</span>
            </Box>
          </Button>
        </Box>
      )}
      {systemConfig.AUTHENTICATION.SHOW_SOCIAL_LOGIN_META && (
        <Box
          sx={{
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <Button
          id="authSocialButton_login_facebook"
            sx={{
              backgroundColor: "#ffffff",
              border: "1px solid #D0D5DD",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              borderRadius: "8px",
              width: "100%",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              color: "#98a2b3",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#ffffff",
                color: "#98a2b3",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  marginRight: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FacebookGreyIcon />
              </Box>
              <span>{t("Login.facebook")}</span>
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
};
