import React from "react";
import { Grid } from "@mui/material";
// import {List,ListItem,ListItemButton,ListItemText } from "@mui/material";
import { CustomTypography } from "@components/atoms";
import { brand } from "@constants";
import { useTranslation } from "i18n/client";

export const DesktopFooter = () => {
  const { t } = useTranslation("common");

  const navItems = [
    {
      name: t("mission"),
      width: "50px",
    },
    {
      name: t("contact"),
      width: "50px",
    },
    {
      name: t("support_help"),
      width: "50px",
    },
    {
      name: t("faq"),
      width: "30px",
    },
    {
      name: t("terms_of_use"),
      width: "50px",
    },
    {
      name: t("privacy_policy"),
      width: "50px",
    },
    {
      name: t("imprint"),
      width: "50px",
    },
  ];
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50px",
        }}
      >
        {navItems.map((item, i) => {
          return (
            <CustomTypography
              key={i}
              fontstyle="normal"
              fontsize="12px"
              fontweight="400"
              lineheight="20px"
              color="#667085"
              padding="0px 10px"
            >
              {item.name}
            </CustomTypography>
          );
        })}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50px",
        }}
      >
        <CustomTypography
          fontstyle="normal"
          fontsize="14px"
          fontweight="400"
          lineheight="20px"
          color="#98a2b3"
          padding="0px 10px"
        >
          {brand} {new Date().getFullYear()}
        </CustomTypography>
      </Grid>
    </Grid>
  );
};
