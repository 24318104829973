"use client";

import React from "react";
import { useAppSelector } from "@hooks";
import { useRouter } from "next/navigation";
import { useAppDispatch } from "@hooks";

import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { NavAccountProfileUserInfo } from "../nav-account-profile-user-info";
import { CustomMenuItem } from "@components/atoms";
import { ProfileAvatar, CrossIcon } from "@components/atoms";
import { logout } from "@store";

export const NavAccountProfile = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { user } = useAppSelector((state) => state.auth);
  const settings = ["Profile", "Account", "Dashboard", "Logout"];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  const handleRedirect = () => {
    router.push("/profile");
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <ProfileAvatar
            name={user?.userName}
            imageURL={user?.profileImage}
            width="40px"
            height="40px"
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        disableScrollLock={true}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          sx={{
            padding: "0px 16px",
          }}
          onClick={handleCloseUserMenu}
        >
          <NavAccountProfileUserInfo
            name={user.userName}
            image={user.profileImage}
            email={user.email}
          />
          {/* <Typography textAlign="center">{setting}</Typography> */}
        </MenuItem>
        <CustomMenuItem
          height="auto"
          color="#344054"
          handleClick={handleRedirect}
        >
          View profile
        </CustomMenuItem>
        <CustomMenuItem height="auto" color="#344054">
          Settings
        </CustomMenuItem>
        <Box
          sx={{
            borderTop: "1px solid #F2F4F7",
          }}
          onClick={handleLogout}
        >
          <CustomMenuItem height="auto" color="#344054">
            Log out
          </CustomMenuItem>
        </Box>
      </Menu>
    </Box>
  );
};
