"use client";

import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import systemConfig from "@next/config/system.config";
import { SuggestExperienceModal } from "../suggest-experience-modal";
import { SuggestActivityModal } from "../suggest-activity-modal";
import { useTranslation } from "i18n/client";

const NothingFound = () => {
  const [openSuggestExp, setOpenSuggestExp] = useState(false);
  const [openSuggestActivity, setOpenSuggestActivity] = useState(false);

  const handleOpenSuggestExp = () => {
    setOpenSuggestExp(true);
  };
  const handleCloseSuggestExp = () => {
    setOpenSuggestExp(false);
  };
  const handleOpenSuggestActivity = () => {
    setOpenSuggestActivity(true);
  };
  const handleCloseSuggestActivity = () => {
    setOpenSuggestActivity(false);
  };
  const {
    RECOMMENDATIONS: {
      NOTHING_FOUND: {
        HAS_TITLE,
        HAS_PROPOSE_EXPERIENCE,
        HAS_PROPOSE_ACTIVITY,
      },
    },
  } = systemConfig;
  const theme = useTheme();
  const { t } = useTranslation("common");
  return (
    <Stack justifyContent="center" alignItems="center" rowGap="24px">
      <Icon></Icon>
      <Typography
        component="h2"
        variant="Display_xlSemibold"
        color={theme.palette.grey[900]}
        sx={{ textAlign: "center" }}
      >
        {t("recommendations.no_result.heading")}
      </Typography>
      <Stack alignItems="center">
        {HAS_TITLE && (
          <Typography
            component="p"
            variant="Text_xlNormal"
            color={theme.palette.grey[500]}
          >
            {t("recommendations.no_result.subtitle")}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
            alignItems: "center",
          }}
        >
          {HAS_PROPOSE_EXPERIENCE && (
            <Button
              id="recommendations_no_result_suggest_experience"
              variant="contained"
              onClick={handleOpenSuggestExp}
              sx={{
                mt: "50px",
                height: "48px",
                textTransform: "unset",
              }}
            >
              {t("recommendations.no_result.suggest_experience")}
            </Button>
          )}
          {HAS_PROPOSE_ACTIVITY && (
            <Button
              id="recommendations_no_result_suggest_activity"
              variant="outlined"
              onClick={handleOpenSuggestActivity}
              sx={{
                mt: "50px",
                height: "48px",
                textTransform: "unset",
                border: `1px solid ${theme.palette.grey[300]}`,
                color: theme.palette.grey[700],
              }}
            >
              {t("recommendations.no_result.suggest_activity")}
            </Button>
          )}
        </Box>
      </Stack>

      <SuggestExperienceModal
        open={openSuggestExp}
        onClose={handleCloseSuggestExp}
      />
      <SuggestActivityModal
        open={openSuggestActivity}
        onClose={handleCloseSuggestActivity}
      />
    </Stack>
  );
};
export default NothingFound;
