import React from 'react'

export const InstagramIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14.6095" cy="14.7107" r="14.4635" fill="#F858A5"/>
    <path d="M18.5068 5.06836H10.7121C7.5443 5.06836 4.9671 7.64556 4.9671 10.8134V18.608C4.9671 21.7759 7.5443 24.3531 10.7121 24.3531H18.5068C21.6746 24.3531 24.2518 21.7759 24.2518 18.608V10.8134C24.2518 7.64556 21.6746 5.06836 18.5068 5.06836ZM22.3118 18.608C22.3118 20.7095 20.6082 22.413 18.5068 22.413H10.7121C8.6107 22.413 6.90714 20.7095 6.90714 18.608V10.8134C6.90714 8.71193 8.6107 7.0084 10.7121 7.0084H18.5068C20.6082 7.0084 22.3118 8.71193 22.3118 10.8134V18.608Z" fill="#FCFCFD"/>
    <path d="M14.6094 9.72302C11.8592 9.72302 9.62173 11.9605 9.62173 14.7107C9.62173 17.4609 11.8592 19.6984 14.6094 19.6984C17.3597 19.6984 19.5972 17.4609 19.5972 14.7107C19.5972 11.9605 17.3597 9.72302 14.6094 9.72302ZM14.6094 17.7584C12.9262 17.7584 11.5618 16.3939 11.5618 14.7107C11.5618 13.0275 12.9263 11.6631 14.6094 11.6631C16.2926 11.6631 17.6571 13.0275 17.6571 14.7107C17.6571 16.3939 16.2926 17.7584 14.6094 17.7584Z" fill="#FCFCFD"/>
    <path d="M19.6068 10.9556C20.2669 10.9556 20.802 10.4205 20.802 9.76046C20.802 9.1004 20.2669 8.56531 19.6068 8.56531C18.9467 8.56531 18.4117 9.1004 18.4117 9.76046C18.4117 10.4205 18.9467 10.9556 19.6068 10.9556Z" fill="#FCFCFD"/>
    </svg>
  )
}
