import React from "react";

export const WhatsAppGreyIcon = () => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#w5264qqrma)" fill="#98A2B3">
        <path d="m0 24 1.696-6.16a11.778 11.778 0 0 1-1.593-5.948C.103 5.332 5.467 0 12.052 0c3.197 0 6.2 1.238 8.452 3.485A11.764 11.764 0 0 1 24 11.897c0 6.56-5.364 11.892-11.954 11.892h-.006c-2 0-3.966-.502-5.713-1.449L0 24zm6.63-3.81.361.217a9.995 9.995 0 0 0 5.055 1.38h.006c5.472 0 9.93-4.431 9.93-9.884a9.813 9.813 0 0 0-2.905-6.992 9.88 9.88 0 0 0-7.025-2.898C6.579 2.008 2.12 6.44 2.12 11.892c0 1.865.522 3.684 1.519 5.258l.235.377L2.87 21.17l3.76-.98z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.057 17.635.418 23.584l6.115-1.597a11.584 11.584 0 0 0 5.513 1.397h.006c6.355 0 11.535-5.15 11.535-11.481 0-3.068-1.197-5.954-3.375-8.122a11.488 11.488 0 0 0-8.16-3.365C5.69.416.516 5.566.51 11.897c0 2.02.533 3.998 1.547 5.738zm6.339-11.23c.212.006.447.017.67.513.143.32.381.9.58 1.385.168.41.309.752.343.817.074.148.12.32.023.519l-.033.068c-.078.162-.134.277-.265.428-.054.062-.11.13-.166.197-.099.12-.197.238-.281.322-.15.148-.304.308-.132.604.172.297.773 1.272 1.662 2.06.955.85 1.785 1.208 2.205 1.389.081.035.147.063.196.088.298.148.475.125.647-.075.172-.2.745-.867.946-1.163.195-.297.395-.245.67-.148.275.102 1.742.821 2.04.97l.165.08c.208.099.349.166.409.267.074.126.074.719-.172 1.415-.252.695-1.473 1.363-2.017 1.414a5.777 5.777 0 0 0-.158.018c-.504.06-1.144.138-3.424-.76-2.81-1.104-4.664-3.845-5.04-4.4a3.318 3.318 0 0 0-.06-.088l-.007-.008c-.17-.229-1.214-1.625-1.214-3.066 0-1.379.679-2.096.989-2.424l.054-.057c.275-.297.596-.37.796-.37.201 0 .402 0 .574.005z"/>
    </g>
    <defs>
        <clipPath id="w5264qqrma">
            <path fill="#fff" d="M0 0h24v24H0z"/>
        </clipPath>
    </defs>
</svg>

  );
};