import * as React from 'react';
import {Box,TextField,Badge, Chip, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListItemAvatar} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { SearchIcon } from '@components/atoms';
import { ProfileAvatar } from '@components/atoms';

export const MemorySearchAutoComplete = ({
   searchValue,
   setSearchValue,
  onchange,
   options,
   handleAssignParticipant
}:any) => {
  const [isOpen, setIsOpen] = React.useState<any>(false);
  const [reference, setReference] = React.useState<any>("");
  const handleChange = (e:any,val:any) => {
    setSearchValue(val);
  }
  const handleInputChange = (e:any,val:any) => {
    onchange(val)
  }

  return (
    <Box className="search__autoComplete memory_search" >
     <SearchIcon  />
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      value={searchValue}
      open={isOpen}
      onOpen={()=>setIsOpen(true)}
      onClose={()=>setIsOpen(false)}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
      getOptionLabel={(option:any) => option?.name || ""}
      renderOption={(props, option:any) => {
      return (
        <ListItem {...props}>
           <ListItemAvatar>
                    <ProfileAvatar name={option?.name} imageURL={option?.image}/>
                  </ListItemAvatar>
        <ListItemText primary={option?.name} sx={{
          fontSize:"14px",
          color:"#344054",
          fontWeight:"500"
        }}/>
        <ListItemSecondaryAction>
        <Box sx={{
            display:"flex",
            alignItems:"center"
          }}>
             <IconButton
            edge="end"
            aria-label="delete"
          >
          </IconButton>
            <Chip
              label="Assign"
              onClick={() => {handleAssignParticipant(option),setSearchValue(option), setIsOpen(false)}}
               sx={{
              background:"#F62E8E",
              fontSize:"14px",
              color:"#fff",
              fontWeight:"500",
              cursor:"pointer"
              
            }} />

          </Box>
         
        </ListItemSecondaryAction>
      </ListItem>
      )
    }}
    />
    
    </Box>
  );
}

const top100Films = [];
