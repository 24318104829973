import { Adsense } from "@ctrl/react-adsense";

const AdSenseActivityModalExternalWebsite = () => {
  return (
    <div className="text-center adsbygoogle my-3">
      <Adsense
        client="ca-pub-3699749149091198"
        slot="5327850549"
        style={{ display: "block" }}
        layout="in-article"
        format="fluid"
      />
    </div>
  );
};

export default AdSenseActivityModalExternalWebsite;
