import React, { FC } from 'react'
import { FacebookGreyIcon, InstagramGreyIcon, TwitterGreyIcon, TelegramGreyIcon, LinkIcon } from '@components/atoms'
import Box from '@mui/material/Box';
import GoogleIcon from '@next/components/atoms/svgs/google/google';
import Link from 'next/link'
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { ProfileAvatar } from '@components/atoms';
export const TotalUsers = () => {
    return (
        <div style={{ display: "flex", width: '100%', }}>

            <AvatarGroup>
                <ProfileAvatar name="Remy Sharp" imageURL=""  width="24px" height="24px"/>
                <ProfileAvatar name="Travis Howard" imageURL=""  width="24px" height="24px" />
                <ProfileAvatar name="Cindy Baker" imageURL=""  width="24px" height="24px"/>
                <Avatar  sx={{ width: 24, height: 24, backgroundColor:"#feeaf4", color: '#f62e8e'}}>+3</Avatar>
            </AvatarGroup>
        </div>
    )
}
