"use client";

import React from "react";
import { useParams, useRouter } from "next/navigation";
import { useTranslation } from "i18n/client";

import { Grid, Typography, Box, useTheme, Button } from "@mui/material";
import {
  FeaturedIconOne,
  FeaturedIconThree,
  FeaturedIconTwo,
} from "@components/atoms";
import systemConfig from "@next/config/system.config";

const GetStartedSection = () => {
  const { t } = useTranslation("landing-page");
  const theme = useTheme();
  const router = useRouter();
  const params = useParams()
  const steps = [
    {
      title: t("get_started.step_one.title"),
      description: t("get_started.step_one.description"),
      icon: <FeaturedIconOne />,
    },
    {
      title: t("get_started.step_two.title"),
      description: t("get_started.step_two.description"),
      icon: <FeaturedIconTwo />,
    },
    {
      title: t("get_started.step_three.title"),
      description: t("get_started.step_three.description"),
      icon: <FeaturedIconThree />,
    },
  ];
  return (
    <Grid
      container
      sx={{
        padding: { xs: "64px 16px", sm: "96px 80px" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="heading"
          component="h2"
          sx={{
            lineHeight: "44px",
            textAlign: "center",
            [theme.breakpoints.down("sm")]: { fontSize: "32px" },
          }}
        >
          {t("get_started.heading")}
        </Typography>
        <Grid
          container
          columnSpacing="24px"
          rowGap="64px"
          sx={{
            padding: "0px 32px",
            marginTop: { xs: "99px", sm: "120px" },
          }}
        >
          {steps.map(({ title, description, icon }, i) => {
            return (
              <Grid key={i} item xs={12} md={4}>
                <Box
                  sx={{
                    //  minHeight:"217px",
                    background: theme.palette.grey[50],
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: { xs: "67px 24px 32px", sm: "91px 24px 32px" },
                    borderRadius: "12px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "inline-grid",
                      position: "absolute",
                      top: "0px",
                      left: " 50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {icon}
                  </Box>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "24px",
                      lineHeight: "30px",
                      fontWeight: 700,
                      color: theme.palette.grey[900],
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="Text_mdNormal"
                    sx={{
                      textAlign: "center",
                      marginTop: "16px",
                      color: theme.palette.grey[500],
                    }}
                  >
                    {description}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {systemConfig.LANDING_PAGE.GET_STARTED.SHOW_BUTTON && (
        <Grid
          item
          xs={12}
          sx={{
            marginTop: { xs: "32px", sm: "120px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              id="landingPage_getStarted_section_button"
              variant="contained"
              onClick={() => router.push(`/${params.lng}/explore`)}
              sx={{
                width: "100%",
                maxWidth: { xs: "100%", sm: "191px" },
                height: "60px",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                textTransform: "unset",
                padding: "0px",
              }}
            >
              {t("get_started.button")}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
export default GetStartedSection;
