import { CutomCheckbox } from '@components/atoms'
import { Box, CheckboxProps, FormControlLabel } from '@mui/material'
import React, { ChangeEvent } from 'react'
interface CheckboxWithLabelProps extends CheckboxProps {
  
    label: React.ReactNode;
  }
export default function Checkboxwithlabel({
    
    label,
    ...props
  }: CheckboxWithLabelProps) {
  return (
    <FormControlLabel
    
    // checked={value}
    sx={{
        width: 'fit-content',
        "&.MuiFormControlLabel-root":{
              marginLeft:"0px !important"
        }
    }}
    control={
        <Box display="flex" justifyContent="center">
            <CutomCheckbox
                sx={{
                    '& .MuiSvgIcon-root': {
                        fontSize: '32px',
                        borderRadius: '4px',
                        marginTop:"0px !important"
                    },

                    padding: '10px 10px 10px 0px',
                }}
             
                {...props}
            />
        </Box>
    }
    label={
        <>
            {label}
        </>
    }
/>
  )
}
