import  React, {FC} from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { SwitchWidthLabelProps } from './switch-with-label.types';
import {styled} from "@mui/system";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#F62E8E' : '#F62E8E',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    // transition: theme.transitions.create(['width'], {
    //   duration: 200,
    // }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? '#f62e8e80' : '#f62e8e80',
    boxSizing: 'border-box',
  },
}));
export const SwitchWidthLabel : FC<SwitchWidthLabelProps> = ({
    label,
    checked,
    onchange,
    sx
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onchange(event.target.checked);
  };
  return (
    
    <FormGroup className='custom__switchBtn'>
      <FormControlLabel control={<AntSwitch 
       checked={checked}
       onChange={handleChange}
      sx={{
        ...sx
      }}
       />} label={label} />
       {/*  className="customSwitch" */}
    </FormGroup>
  );
}