import React, { FC } from 'react'
import {Typography,Box,useTheme} from "@mui/material"
import { IPrivacyPolicySection } from './privacy-policy-section.types'

export const PrivacyPolicySection: FC<IPrivacyPolicySection> = ({heading,children}) => {
   const theme = useTheme()
    return (
    <Box>
      {heading && <Typography
            component="h2"
            variant="Display_smSemibold"
            color={theme.palette.grey[900]}
            mb="24px"
          >
           { heading}
          </Typography>}
          {children}
    </Box>
  )
}
