import * as React from "react";
import { Grid, Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

export const ExploreDetailRatingBoxCard = ({
  title,
  ratings,
}: {
  title: string;
  ratings: number;
}) => {
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: 14,
          fontWeight: 500,
          color: "#344054",
        }}
      >
        {title}
      </Typography>

      <Stack spacing={0.7} direction="row" sx={{ margin: "8px 0px 0px 0px" }}>
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <Box
            key={index}
              component="span"
              sx={{
                bgcolor: index < ratings ? "#f62e8e" : "#eaecf0",
                width: 16,
                height: 16,
              }}
            />
          ))}
      </Stack>
    </Box>
  );
};
