import React from "react";
import Image from "next/image"
import { Box } from "@mui/material";
import { ButtonContained } from "@components/atoms";
export const CreateGroupBox = ({
    btnText,
    handleClick,
    buttonId
}:{
    btnText?:string;
    handleClick?:any;
    buttonId?: string
}) => {
    return (
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            alignItems:"center"
        }}>
            <Image src="/assets/images/explore/placeholder-memory.png" alt="" height={100} width={100} />
            <Box sx={{
                width:"100%",
                maxWidth:"287px",
                marginTop:"24px"
            }}>
                <ButtonContained id={buttonId || "createGroupBox_btn"} clickHandler={handleClick}>{btnText}</ButtonContained>
            </Box>

        </Box>
    )
}