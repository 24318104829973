"use client";
import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import style from "./tags-input.style.module.css";
import { searchHashtags } from "@ApiReq";
import { Box } from "@mui/material";
const TagsWrapper = styled("div")({
  input: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    ".css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator":
      {
        display: "none",
        background: "red",
      },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  span: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
});
export const TagsInput = ({ handleHashTag, hashTags }: any) => {
  const [tagOptions, setTagOptions] = useState<any[]>(["#memory"]);

  // const [value, setValue] = React.useState<any>([]);
  const onChangeInput = (newInputValue: string) => {
    searchHashtags(newInputValue).then((res: any) => {
      if (res?.data?.result) {
        setTagOptions(["#memory", ...res.data?.result?.hashtags]);
      }
    });
  };

  return (
    <Stack spacing={3} sx={{ width: "100%" }}>
      <TagsWrapper>
        <Autocomplete
          className="tags_input"
          multiple
          id="tags-filled"
          value={hashTags}
          onInputChange={(event, newInputValue) => {
            onChangeInput(newInputValue);
          }}
          onChange={(event, newValue) => {
            handleHashTag(newValue);
          }}
          options={tagOptions.map((option: any) => option)}
          // defaultValue={[tagOptions[0].title]}
          // freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Box key={index}>
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              </Box>
            ))
          }
          renderInput={(params) => (
            <TextField
              className="tags__textfield"
              {...params}
              variant="filled"
              // label=""
              placeholder={!(hashTags.length > 0) ? "# Hashtags" : ""}
            />
          )}
        />
      </TagsWrapper>
    </Stack>
  );
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
