import { Box, Stack, Typography,useTheme } from "@mui/material";
import { useTranslation } from "i18n/client";
import React from "react";
interface IActivitiesAdditionalInfo {
  facts: string;
}
export const ActivitiesAdditionalInfo = ({
  facts,
}: IActivitiesAdditionalInfo) => {
    const theme = useTheme();
    const {t} = useTranslation("experiences") 
  return (
    <Stack rowGap="16px" sx={{
        backgroundColor: theme.palette.grey[50],
        padding:"16px"
    }}>
      <Typography aria-level={1} component="h2" variant="Title_mediumSemibold">{t("activities.additional_information")}</Typography>
      <Stack rowGap="16px">
                  <Box
                    dangerouslySetInnerHTML={{ __html: facts as string }}
                    sx={{
                       
                            color:theme.palette.grey[500],
                            fontSize:"16px",
                            lineHeight:"28px",
                            fontWeight:400,
                    }}
                  ></Box>
      </Stack>
    </Stack>
    
  );
};