import React, {
  useState,
  FC,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "i18n/client";

import { styled } from "@mui/material/styles";
import { Typography, Box, Dialog } from "@mui/material";

import { ButtonContained } from "../../buttons";
import { CustomLink } from "@components/molecules";
import { Trans } from "react-i18next";
import Link from "next/link";
import AdSenseActivityModalExternalWebsite from "@next/components/molecules/ads/adsense-activity-modal-external-website";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    //  background:"rgba(52, 64, 84, 1)", //70%
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    ["@media (max-width:700px)"]: {
      borderRadius: "10px",
      width: "400px !important",
      height: "100%",
      maxHeight: "100%",
      margin: "0px",
    },
    ["@media (min-width:700px)"]: {
      borderRadius: "10px",
      width: "400px",
    },
  },

  scrollbarColor: "#6b6b6b #2b2b2b",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#eaecf0",
    width: 8,
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#eaecf0",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#eaecf0",
  },
}));

export const VisitProviderModal: FC<any> = ({ open, onClose, url,showAds }) => {
  const [countdown, setCountdown] = useState(5);
  const timerRef = useRef<NodeJS.Timer | null>(null);
  const { t } = useTranslation("common");
  const handleClose = () => {
    onClose();
  };
  const externalURL = useMemo(() => {
    // Check if the URL is external (doesn't start with http:// or https://)
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    } else {
      // It's a full URL
      return url;
    }
  }, [url]);
  const openExternalURL = useCallback(() => {
    window.open(externalURL, "_blank");
  }, [externalURL]);

  useEffect(() => {
    if (open) {
      timerRef.current = setInterval(() => {
        countdown > 0 && setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      // Clear the timer when the dialog is closed
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
      }
    }
    if (countdown === 0) {
      openExternalURL();
    }
    return () => {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
      }
    };
  }, [open, countdown]);

  useEffect(() => {
    if (open) {
      setCountdown(5);
    }
  }, [open]);
  return (
    <div>
      <BootstrapDialog
        // fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px",
          }}
        >
          <Typography
            component="h1"
            sx={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            {t("external_website_redirect_modal.heading")}
          </Typography>
          <Typography
            component="p"
            sx={{
              textAlign: "center",
              marginTop: "5px",
            }}
          >
            {countdown > 0 ? (
              t("external_website_redirect_modal.subtitle", { countdown })
            ) : (
              <Trans
                i18nKey="external_website_redirect_modal.subtitle_two"
                values={{}}
                components={[
                  <Link
                    key="0"
                    href={externalURL}
                    target="_blank"
                    style={{ fontWeight: 600, fontSize: "16px" }}
                  ></Link>, // For <0>no</0>
                  <Typography
                    key="1"
                    component="span"
                    sx={{ fontWeight: 600, fontSize: "14px" }}
                  ></Typography>,
                ]}
                t={t}
              />
            )}
          </Typography>
          <Box>
            
          { showAds  && <AdSenseActivityModalExternalWebsite  /> }
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "0px 20px",
              marginTop: "100px",
            }}
          >
            <ButtonContained id="visitProviderModal_close" clickHandler={handleClose}>
              {t("external_website_redirect_modal.close")}
            </ButtonContained>
          </Box>
        </Box>
      </BootstrapDialog>
    </div>
  );
};
