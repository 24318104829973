import React from "react";
import { useTranslation } from "i18n/client";

import { Box, Button, Grid, Typography, styled } from "@mui/material";
import {  ButtonOutlined } from "@components/atoms";
import systemConfig from "@next/config/system.config";
import parse from "html-react-parser";
import { multilineTranslation } from "@next/utils/multiline-translation";

const StyleHeading = {
  fontSize: "60px",
  fontWeight: "600",
  lineHeight: "1.2",
  color: "#101828",
  textalign: "left",
  fontFamily: "Inter",
  ["@media (max-width:600px)"]: {
    fontSize: "36px",
    lineHeight: "1.5",
  },
  ["@media (min-width:900px)"]: {
    fontSize: "36px",
  },
  ["@media (min-width:1024px)"]: {
    fontSize: "44px",
  },
  ["@media (min-width:1350px)"]: {
    fontSize: "60px",
  },
};
const StyleSubHeading = styled(Typography)({
  fontSize: "20px",
  fontWeight: "normal",
  lineHeight: "1.5",
  color: "#667085",
  textalign: "left",
  fontFamily: "Inter",
  ["@media (max-width:600px)"]: {
    fontSize: "18px",
    lineHeight: "1.56",
  },
});
export const ExploreHeroSection = ({ setRatingModalOpen }: any) => {
  const { t } = useTranslation("explore");
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          padding: {
            xs: "0px",
            md: "153px 0px 0px 0px",
          },
        }}
      >
        <Box
          sx={{
            marginBottom: {
              xs: "16px",
              sm: "32px",
            },
          }}
        >
          <Typography component="h1" sx={{ ...StyleHeading }}>
            {parse(t("heading"))}
          </Typography>
        </Box>
        <Box
          sx={{
            marginBottom: {
              xs: "32px",
              sm: "48px",
            },
          }}
        >
          <StyleSubHeading>
            {multilineTranslation(t("subtitle"))}
          </StyleSubHeading>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          {systemConfig.EXPLORE.HERO_SECTION.HAS_LOGIN_BUTTONS && (
            <Box>
              <ButtonOutlined id="explore_heroSection_login" border="1px solid #d0d5dd">
                {t("login")}
              </ButtonOutlined>
            </Box>
          )}
          {systemConfig.EXPLORE.HERO_SECTION.HAS_RATING_BUTTONS && (
            <Box
              sx={{
                marginLeft: {
                  xs: "0px",
                  sm: "12px",
                },
                marginTop: {
                  xs: "12px",
                  sm: "0px",
                },
              }}
            >
              <Button
                   id="explore_heroSection_rate_now"
                    variant="contained"
                    onClick={setRatingModalOpen}
                    sx={{
                      width: { xs: "100%", sm: "157px" },
                      height: "60px",
                      textTransform: "unset",
                      padding: "0px",
                    }}
                  >
                     {t("rate_now")}
                  </Button>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            height: "100%",
            paddingTop: "45px",
          }}
        >
          <Box
            component="img"
            src={t("assets.images.image_one.file")}
            alt={t("assets.images.image_one.alt")}
            title={t("assets.images.image_one.title")}
            sx={{
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
