import React, {FC} from 'react'
import {InputField} from "./controled-input-field.style";
import {ControledInputFieldProps} from "./controled-input-field.types"
export const ControledInputField : FC<ControledInputFieldProps> = ({
    placeholder,
    name,
    readyonly = false,
    type="text",
    value,
    onchange,
    inputFieldProps,
    
}) =>  {
  return (
   
    <InputField readOnly={readyonly} type={type} id={name} value={value} onChange={(e:any) => onchange(e.target.value)}  fullWidth placeholder={placeholder} {...inputFieldProps}  />
  )
}
