"use client";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { Box, TextField } from "@mui/material";
import { LocationIcon } from "@components/atoms";
import {
  GoogleMap,
  useJsApiLoader,
  useLoadScript,
} from "@react-google-maps/api";
import Script from "next/script";
import { useEffect } from "react";
import key from "../../../../config/key";
export const LocationSearchAutoComplete = ({
  handleLocation,
  currentLocation,
}: any) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${key.NEXT_PUBLIC_GOOGLE_PLACES_KEY}`,
    libraries: ["places"],
  });
  const {
    ready,
    value,
    suggestions: {
      status,
      data = [
        {
          structured_formatting: {
            main_text: "a",
            secondary_text: "b",
          },
          place_id: "1",
        },
      ],
    },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  useEffect(() => {
    setValue(currentLocation, false);
  }, []);
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }: any, main_text: any, secondary_text: any) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"

      setValue(description, false);
      clearSuggestions();
      const primaryLocation = main_text ? main_text : "";
      const secondaryLocation = secondary_text ? secondary_text : "";
      const text = primaryLocation + "," + secondaryLocation;

      handleLocation(text, description);
      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion, main_text, secondary_text)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <>
      <Box ref={ref}>
        <Box className="search__autoComplete">
          <LocationIcon />
          <input
            style={{
              border: "0px",
              marginLeft: "10px",
            }}
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Add location"
          />
        </Box>
        {/* We can use the "status" to decide whether we should display the dropdown or not */}
        {status === "OK" && <ul>{renderSuggestions()}</ul>}
      </Box>
    </>
  );
};
