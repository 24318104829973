"use client";
import React, { FC } from "react";
import { useRouter } from "next/navigation";
import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
interface ISettingsMobileHeader {
  title: string;
  showSaveButton?: boolean;
  showBackButton?: boolean;
}
export const SettingsMobileHeader: FC<ISettingsMobileHeader> = ({
  title,
  showSaveButton = true,
  showBackButton = true,
}) => {
  const theme = useTheme();
  const router = useRouter();
  const handleNavigateBack = () => {
    router.back();
  };
  return (
    <Grid
      container
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        background: "#fff",
        zIndex: 2,
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      }}
    >
      <Grid item xs={12}>
        <Stack
          flexDirection="row"
          py="10px"
          px="16px"
          alignItems="center"
          columnGap="8px"
        >
          <IconButton
            onClick={handleNavigateBack}
            sx={{
              padding: "4px",
              visibility: showBackButton ? "visible" : "hidden",
            }}
          >
            <ArrowBack sx={{ color: theme.palette.grey[500] }} />
          </IconButton>
          <Typography
            variant="Text_lgMedium"
            sx={{
              textAlign: "center",
              color: theme.palette.grey[500],
              flex: 1,
            }}
          >
            {title}
          </Typography>
          <Button
            id="settingMobileHeader_save"
            variant="text"
            sx={{
              padding: "0px",
              minWidth: "auto",
              visibility: showSaveButton ? "visible" : "hidden",
              textTransform: "unset",
            }}
          >
            Save
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
