import { Adsense } from "@ctrl/react-adsense";

const ExperienceDescriptionInArticle = () => {
  return (
    <div className="text-center adsbygoogle my-3">
      <Adsense
        client="ca-pub-3699749149091198"
        slot="8861427527"
        style={{ display: "block", width:"300px" }}
        layout="in-article"
        format="fluid"
      />
    </div>
  );
};

export default ExperienceDescriptionInArticle;
