import React, { useMemo } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
// import {  useJsApiLoader } from "@react-google-maps/api";
// import key from "../../../../config/key"
import { useGooglePlaceApi } from "@hooks";
// import { useGooglePlaceApi } from '@hooks';
const containerStyle = {
  width: "calc(100% - 10px)",
  height: "318px",
};

export const SimpleMap = ({
  lat = 27.672932021393862,
  lng = 85.31184012689732,
}: {
  lat: number;
  lng: number;
}) => {
  const {} = useGooglePlaceApi();
  const isLoaded =
    typeof window !== "undefined" && window.google && window.google.maps;
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: key.NEXT_PUBLIC_GOOGLE_PLACES_KEY as string
  // })

  const mapCenter = useMemo(() => ({ lat: lat, lng: lng }), [lat, lng]);
  if (!isLoaded) {
    return <p>Loading...</p>;
  }
  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={mapCenter} zoom={14}>
      <MarkerF position={mapCenter} />
    </GoogleMap>
  ) : (
    <></>
  );
};
