import React, { FC } from 'react'
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails, { AccordionDetailsProps } from "@mui/material/AccordionDetails";
import { ArrowDownIcon } from "@components/atoms";
import { SxProps, Typography, styled, useTheme } from '@mui/material';
interface IAccordion {
    id:string | number;
    selectedId : string;
    handleChange : (id:string) => void;
    heading:string;
    children: any;
    summaryStyle?: SxProps;
    contentStyle?: SxProps
}
const StyledAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border:"none",
   
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    
    "&:before": {
      display: "none",
    },
  }));
  
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowDownIcon  />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:"#fff",
    // flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(180deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
  }));
export const Accordion : FC<IAccordion> = ({
    id,
    selectedId,
    handleChange,
    heading,
    children,
    summaryStyle,
    contentStyle
}) => {
    const theme = useTheme()
  return (
    <StyledAccordion
    expanded={selectedId === `${id}`}
    onChange={() => handleChange(`${id}`)}
  >
    <AccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      sx={{
        padding:"5px 0px",
        minHeight:"auto",
        "& .MuiAccordionSummary-content": {
            margin: "0px",
          },
          ...summaryStyle
     }}
    >
      <Typography component="h3" variant="Body_largeSemibold" sx={{
        color:theme.palette.grey[900]
      }}>{heading}</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{
      padding:"8px 0px 20px",
      ...contentStyle
    }}>
      {children}
    </AccordionDetails>
  </StyledAccordion>
  )
}
