import React from "react";
import MUILinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
export const LinearProgress = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1301,
        width: "100%",
      }}
    >
      <MUILinearProgress color="primary" />
    </Box>
  );
};
