import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Box } from '@mui/material'
import { CopyIcon, GridViewIcon, WhiteCrossIcon } from '@components/atoms';
import { styled } from '@mui/system';
const DeleteImage = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 10px;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.7);
`;
const GridViewComponent = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 12px;
  right: 10px;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.7);
  @media (max-width: 768px) {
    bottom: 12px;
  }
`;
export const CarouselGridVersionTwo = ({ images, removeImage,gridView }: any) =>
{
    const imgData = [
        {
          id:"",
          file: "/assets/images/explore/placeholder-memory.png",
          fileName: "Breakfast",
        },
        {
          id:"",
          file: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          fileName: "Burger",
        },
        {
          id:"",
          file: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          fileName: "Camera",
        },
        {
          id:"",
          file: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
          fileName: "Coffee",
        },
      ];

    return (
        <Carousel
        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
                backgroundColor: 'cornflowerblue',
                borderRadius: 0
            }
        }} 
        navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
                bottom: '0',
                top: 'unset'
            }
        }} 
        NextIcon={<Box sx={{height:"20px",width:"20px", background:"rgba(0,0,0,0.7)"}}><CopyIcon/></Box>}
        PrevIcon={<CopyIcon/>}
         className='memoryCarousel__container'>
            {
                imgData.map( (image:any, i:any) => {
                  return (
                      <Paper key={i} >
                         <img src={image.file} height="500px" width="100%" style={{objectFit:"cover"}}/>
                         <DeleteImage>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => removeImage(image.id)}
                              >
                                <WhiteCrossIcon />
                              </Box>
                            </DeleteImage>
                            <GridViewComponent>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => gridView()}
                              >
                                <GridViewIcon />
                              </Box>
                            </GridViewComponent>
                      </Paper>
                  )
              } )
            }
        </Carousel>
    )
}

