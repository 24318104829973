"use client";

// Npm Imports
import React, { FC, useState } from "react";
import Link from "next/link";

// MUI Imports
import {
  Box,
  CardHeader,
  Avatar,
  IconButton,
  Typography,
  Grid,
  CardContent,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Component Imports
import {
  WatchIcon,
  HeartIcon,
  HandsIcon,
  ModalMoreOptionsComponent,
} from "@components/atoms";
import { CustomLink, ImgGallery, TotalUsers } from "@components/molecules";

// TS Import
import { bigCardProps } from "./big-card.types";
import { useParams } from "next/navigation";

export const BigCard: FC<bigCardProps> = ({
  memoryTitle,
  memoryPlace,
  postedBy,
  postedUsername,
  postDescription,
  memoryImg,
  friendStatus,
  userImg,
  handleEditMemory,
}): JSX.Element => {
  const [optionsmodalopen, setOptionsModalOpen] = useState(false);
  const params = useParams<{lng: string}>();
  const handleClickMoreOptions = () => {
    setOptionsModalOpen(true);
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* Top Header */}
            <CardHeader
              sx={{
                fontSize: "20px",
              }}
              action={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="totalUsers">
                    <TotalUsers />
                  </span>
                  <IconButton onClick={() => handleClickMoreOptions()}>
                    <MoreVertIcon />
                  </IconButton>
                  <ModalMoreOptionsComponent
                    handleEditMemory={handleEditMemory}
                    open={optionsmodalopen}
                    onclose={() => setOptionsModalOpen(false)}
                    title="Pending Moments"
                    btnStatus="Review"
                  />
                </div>
              }
              titleTypographyProps="h4"
              title={
                <Typography
                  sx={{ fontSize: "14px", color: "#344054", fontWeight: "500" }}
                >
                  <CustomLink href="/m" locale={params?.lng}>
                    {memoryTitle}
                  </CustomLink>
                </Typography>
              }
              subheaderTypographyProps="body1"
              subheader={
                <Typography sx={{ fontSize: "14px", color: "#667085" }}>
                  {memoryPlace}
                </Typography>
              }
            />
          </Grid>
          {/* Img Gallery */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ImgGallery />
          </Grid>
          {/* Bottom Header */}
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <Box sx={{ display: "flex", padding: "0px", margin: "0px" }}>
              <CardHeader
                avatar={
                  <div style={{ marginRight: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={userImg}
                      sx={{
                        width: 32,
                        height: 32,
                        paddingRight: 0,
                        marginRight: 0,
                      }}
                    />
                  </div>
                }
                titleTypographyProps="h4"
                title={
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#101828",
                      fontWeight: "500",
                    }}
                  >
                    {postedBy}
                  </Typography>
                }
                subheaderTypographyProps="body1"
                subheader={
                  <Typography sx={{ fontSize: "12px", color: "#667085" }}>
                    {postedUsername}
                  </Typography>
                }
              />
              <Typography
                variant="body2"
                sx={{
                  color: "#e02a81",
                  fontSize: "14px",
                  fontWeight: 500,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: "15px 0",
                  margin: "3px 0",
                }}
              >
                {friendStatus}
              </Typography>
              {/* <h5 >{friendStatus}</h5> */}
            </Box>
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {/* Post Status icons */}
            <div style={{ width: "100%" }}>
              <Box
                sx={
                  {
                    // display: 'flex',
                    // alignContent: 'space-between',
                  }
                }
              >
                <ul className="postStatusicons">
                  <li>
                    <WatchIcon />{" "}
                  </li>
                  <li>
                    <HeartIcon />
                  </li>
                  <li>
                    <HandsIcon />
                  </li>
                </ul>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* Post Status icons */}
            <CardContent sx={{ marginTop: 0, paddingTop: 0 }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#667085", fontSize: "14px" }}
              >
                {postDescription}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
