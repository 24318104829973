import { Box } from '@mui/material'
import React from 'react'
import {CustomTypography} from "@components/atoms";

export const AuthDivider = () : JSX.Element => {
  return (
    <Box
    sx={{
      marginBottom: "24px",
      width: "100%",
      display: "flex",
      alignItems: "center",
    }}
  >
    <Box
      sx={{
        border: "1px solid #eaecf0",
        flex: 1,
      }}
    ></Box>
    <CustomTypography
        fontsize= "14px"
        fontweight= "500"
        lineheight= "20px"
        color= "#667085"
    >
      Spacer
    </CustomTypography>
    <Box
      sx={{
        border: "1px solid #eaecf0",
        flex: 1,
      }}
    ></Box>
  </Box>
  )
}
