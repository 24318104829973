import * as React from 'react';
import TextField from '@mui/material/TextField';
 import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box,IconButton } from '@mui/material';
import { CrossIcon } from '../svgs';
import InputAdornment from '@mui/material/InputAdornment';
import { CalenderIcon } from '../svgs/calender-icon';
export const CustomDatePicker = ({
  date,
  handleDate
}:any) => {
  // const [value, setValue] = React.useState<Date | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  return (
    <Box className='custom__datepicker'>

   
    <LocalizationProvider   sx={{width:"100%"}} dateAdapter={AdapterDateFns}>
      <DatePicker
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
        label="Date"
        value={date}
        onChange={(newValue) => {
          handleDate(newValue);
        }}
        renderInput={(params) => <TextField  placeholder="Date" {...params} InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleOpen}>
                <CalenderIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
/>}
      />
    </LocalizationProvider>
    </Box>
  );
}
