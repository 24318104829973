import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider, useTheme } from "@mui/material";
import { CheckBoxStaticIcon, CustomTypography } from "@components/atoms";
import { ListWithIcon } from "../list-with-icon";
import AdSenseActivityModalExternalWebsite from "../ads/adsense-activity-modal-external-website";
export const ExploreDetailInfoHeader = ({
  catagory,
  heading,
  description,
  highlights,
}: {
  catagory: string;
  heading: string;
  description: string;
  highlights: string;
}) => {
  const theme = useTheme();
  const list = [
    {
      item: "Interpretive hike with information on Visit with Respect, Leave No Trace, Ancestral Pueblo culture, botany, biology, archaeology.",
      Component: CheckBoxStaticIcon,
    },
    {
      item: "Guide will meet participants at Lowry Pueblo",
      Component: CheckBoxStaticIcon,
    },
    {
      item: "Suggested Time: 4 hours; participants responsible for lunch, water, snacks",
      Component: CheckBoxStaticIcon,
    },
  ];
  const summary = description ?? "";
  const summarySubstr = summary.substring(0, 200);
  return (
    <Box sx={{ margin: "0px", padding: "0px" }}>
      <Typography aria-level={2} variant="Text_mdSemibold" color="#D12779">
        {catagory}
      </Typography>
      <Typography
        component="h1"
        variant="Display_lgSemibold"
        color={theme.palette.grey[900]}
        mt="12px"
      >
        {heading}
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{
          __html: summary as string,
        }}
        component="div"
        variant="Text_smNormal"
        color={theme.palette.grey[500]}
        sx={{
          marginTop: "12px",
          fontSize: "16px",
          textAlign: "justify",
          textJustify: "inter-word",
          textSizeAdjust: "auto",
          overFlow: "hidden",
        }}
      >
        {/* {description} */}
      </Typography>
      <Box
        dangerouslySetInnerHTML={{ __html: highlights as string }}
        sx={{
          ul: {
            paddingLeft: "16px",
            marginTop: "30px",
          },
          li: {
            color: theme.palette.grey[500],
            fontSize: "16px",
            lineHeight: 1.43,
            fontWeight: "normal",
            mb: "20px",
            "&:last-child": {
              mb: "0px",
            },
            listStyle: "none",
            display: "flex",
            "&::before": {
              //This icon will come assets file, Its not possible to added it in central svg as a component
              content: `url("/assets/icons/common/list-icon.svg")`,
              marginRight: "12px",
            },
          },
        }}
      />
     
     <AdSenseActivityModalExternalWebsite />
    </Box>
  );
};
