import * as React from "react";
import { useTranslation } from "i18n/client";
import { useTheme } from "@mui/material";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Button, Stack } from "@mui/material";
import { ArrowDownIcon } from "@components/atoms";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border:"none",
 
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDownIcon  />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:"#fff",
  // flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function ExploreDetailFaqs({
  faqs,
  handleOpenAskQuestion
}: {
  faqs: {
    question: string;
    answer: string;
  }[];
  handleOpenAskQuestion: () => void
}) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const theme = useTheme();
  const {t} = useTranslation("experiences")
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Stack rowGap="50px">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h3"
          variant="Text_mdSemibold"
          sx={{
            color: "#E02A81",
            textAlign:"center"
          }}
        >
          {t("experiences.faqs.heading")}
        </Typography>
        <Typography
          component="h2"
          variant="Display_lgSemibold"
          sx={{
            color: theme.palette.grey[900],
            marginTop:"8px",
            textAlign:"center"
          }}
        >
            {t("experiences.faqs.title")}
        </Typography>
        <Typography
          component="p"
          variant="Text_xlNormal"
          sx={{
            color: theme.palette.grey[600],
            marginTop:"16px",
            textAlign:"center"
          }}
        >
         {t("experiences.faqs.subtitle")}
        </Typography>
        <Button 
            id="experience_faq_open_askQuestion_modal_btn"
            variant="contained"
            onClick={handleOpenAskQuestion}
            sx={{
              marginTop: "15px",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              textTransform:"unset"
            }}
          >
           {t("experiences.faqs.btn")}
          </Button>
      </Box>
      <Box sx={{
        padding:{xs:"0px",sm:"64px 0px 0px"}
      }}>
      {faqs?.map((faq, i) => {
        return (
          <Accordion
            key={i}
            expanded={expanded === `${i}`}
            onChange={handleChange(`${i}`)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{px: '0'}}
            >
              <Typography component="h3" variant="Body_largeSemibold" sx={{
                color:theme.palette.grey[900],
                mr: '15px'
              }}>{faq?.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                dangerouslySetInnerHTML={{ __html: faq?.answer as string }}
                sx={{
                  p:{
                    color:theme.palette.grey[500],
                    fontSize:"16px",
                    fontWeight:400,
                    lineHeight:"27px"
                  }
                }}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
      </Box>
    </Stack>
  );
}
