import React from "react";
import { useTranslation } from "i18n/client";

import { Typography, useTheme, CheckboxProps } from "@mui/material";
import Checkboxwithlabel from "../checkbox-with-label/checkbox-with-label";
import { useParams } from "next/navigation";
import { CustomLink } from "../custom-link";

export const SignupTermsVerify = (props: CheckboxProps) => {
  const { t } = useTranslation("common");
  const params = useParams<{lng: string}>()
  const theme = useTheme();
  return (
    <Checkboxwithlabel
      label={
        <Typography
          component="span"
          sx={{
            textAlign: "left",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            color: theme.palette.grey[500],
          }}
        >
          {t("accept_terms_part1")}
          <CustomLink href={"/terms-of-use"} locale={params.lng}>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "20px",
              color: "#E02A81",
            }}
            component="span"
          >
            {" "}
            {t("accept_terms_part2")}
          </Typography>
          </CustomLink>
        </Typography>
      }
      {...props}
    />
    //   <Box
    //   sx={{
    //     display: "flex",
    //     alignItems:"flex-start",
    //     marginBottom:"16px"
    //   }}
    // >
    //   <CutomCheckbox {...props}/>
    //   <Typography
    //     sx={{
    //       textAlign: "left",
    //       fontFamily: "Inter",
    //       fontStyle: "normal",
    //       fontSize: "14px",
    //       fontWeight: "500",
    //       lineHeight: "20px",
    //       color:theme.palette.grey[500],

    //     }}
    //   >
    //     {t('accept_terms_part1')}
    //     <Typography
    //       sx={{
    //         textAlign: "center",
    //         fontFamily: "Inter",
    //         fontStyle: "normal",
    //         fontSize: "14px",
    //         fontWeight: "500",
    //         lineHeight: "20px",
    //         color: "#E02A81",
    //       }}
    //       component="span"
    //     >
    //       {" "}
    //       {t('accept_terms_part2')}
    //     </Typography>
    //   </Typography>
    // </Box>
  );
};
