import React, { FC } from "react";
import { useTranslation } from "i18n/client";
import useExperienceAskQuestionModal from "./use-experience-ask-question-modal";

import Grid from "@mui/material/Grid";
import { Typography, useTheme } from "@mui/material";
import { ButtonContained, Label, Textarea } from "@components/atoms";
import { Dialog } from "@next/components/molecules/dialog";
import { InputFieldWithLabel, SignupTermsVerify } from "@components/molecules";
import { Controller } from "react-hook-form";
import { ErrorField } from "@next/components/molecules/error-field";
interface IExperienceAskQuestionModal {
  open: boolean;
  onClose: () => void;
  id: string;
}
export const ExperienceAskQuestionModal: FC<IExperienceAskQuestionModal> = ({
  open,
  onClose,
  id,
}) => {
  const { control, errors, handleSendProposal } = useExperienceAskQuestionModal(
    { id, onClose }
  );
  const theme = useTheme();
  const { t } = useTranslation("experiences");
  return (
    <Dialog open={open} onClose={onClose}>
      <Grid
        container
        sx={{
          padding: "10px 16px",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="Display_xsMedium"
            component="h2"
            sx={{
              color: theme.palette.grey[900],
            }}
          >
            {t("experiences.faqs.modal.heading")}
          </Typography>
          <Typography
            variant="Text_mdNormal"
            component="p"
            sx={{
              color: theme.palette.grey[500],
              marginTop: "8px",
            }}
          >
            {t("experiences.faqs.modal.subtitle")}
          </Typography>
        </Grid>
        <Grid item container xs={12} mt="32px" rowGap="24px">
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value } }) => (
                <InputFieldWithLabel
                  value={value}
                  onChange={onChange}
                  type="text"
                  name="name"
                  label={t("experiences.faqs.modal.name")}
                  placeholder={t("experiences.faqs.modal.name_placeholder")}
                />
              )}
            />
            <ErrorField error={errors?.name?.message ?? ""} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <InputFieldWithLabel
                  value={value}
                  onChange={onChange}
                  type="email"
                  name="email"
                  label={t("experiences.faqs.modal.email")}
                  placeholder={t("experiences.faqs.modal.email_placeholder")}
                />
              )}
            />
            <ErrorField error={errors?.email?.message ?? ""} />
          </Grid>
          <Grid item xs={12}>
            {/* <InputFieldWithLabel type="text" name="question" label="Question" placeholder="Enter details about your question"/> */}
            <Label
              name="question"
              label={t("experiences.faqs.modal.question")}
            />
            <Controller
              control={control}
              name="question"
              render={({ field: { onChange, value } }) => (
                <Textarea
                  value={value}
                  onChange={onChange}
                  placeholder={t("experiences.faqs.modal.question_placeholder")}
                />
              )}
            />
            <ErrorField error={errors?.question?.message ?? ""} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="termOfServices"
              render={({ field: { onChange, value } }) => (
                <SignupTermsVerify checked={value} onChange={onChange} />
              )}
            />
            <ErrorField error={errors?.termOfServices?.message ?? ""} />
          </Grid>
          <Grid item xs={12}>
            <ButtonContained id="experiences_faqs_askQuestion_modal_btn" clickHandler={handleSendProposal}>
              {t("experiences.faqs.modal.btn")}
            </ButtonContained>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
