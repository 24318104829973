import React from "react";
import { useTranslation } from "i18n/client";

import { ButtonContained } from "@components/atoms";
import { emojis } from "@constants";
import { Box, Typography } from "@mui/material";
import useExperienceRateInterest from "@next/hooks/use-experience-rate-interest";

export const ExploreDetailFeedbackBox = ({
  experienceId,
}: {
  experienceId: string;
}) => {
  const { userInterest, handleAddRating, handleUpdateInterest,currentGif,setCurrentGif } =
    useExperienceRateInterest({ experienceId });
  const { t } = useTranslation("experiences");
  const ratingEmojis = [
    {
      value: 1,
      default: "/assets/images/rating/smiley-hate-inactive.png",
      active: "/assets/images/rating/smiley-hate.png",
      gif: "/assets/images/rating/smiley-hate.gif",
    },
    {
      value: 2,
      default: "/assets/images/rating/smiley-disappointed-inactive.png",
      active: "/assets/images/rating/smiley-disappointed.png",
      gif: "/assets/images/rating/smiley-disappointed.gif",
    },
    {
      value: 3,
      default: "/assets/images/rating/smiley-neutral-inactive.png",
      active: "/assets/images/rating/smiley-neutral.png",
      gif: "/assets/images/rating/smiley-neutral.gif",
    },
    {
      value: 4,
      default: "/assets/images/rating/smiley-good-inactive.png",
      active: "/assets/images/rating/smiley-good.png",
      gif: "/assets/images/rating/smiley-good.gif",
    },
    {
      value: 5,
      default: "/assets/images/rating/smiley-excellent-inactive.png",
      active: "/assets/images/rating/smiley-excellent.png",
      gif: "/assets/images/rating/smiley-excellent.gif",
    },
  ];
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        border: "1px solid #eaecf0",
        padding: "16px 26px",
        margin: "32px 0px 22px 0px",

        display: "flex",
        boxShadow:
          "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
        borderRadius: "16px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: 20,
          fontWeight: 600,
          color: "#101828",
          marginTop: "12px",
        }}
      >
        {t("experiences.feedback_card.heading")}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: 16,
          fontWeight: 400,
          color: "#667085",
          marginTop: "12px",
          textAlign: "justify",
          textJustify: "initial",
          textSizeAdjust: "auto",
        }}
      >
        {t("experiences.feedback_card.subtitle")}
      </Typography>
      <Box sx={{ flexDirection: "row", display: "flex", gap: "12px" }}>
        {ratingEmojis.map((item, i) => {
          const isActive =
            userInterest && userInterest?.interestLevel === item?.value;
          return (
            <Box key={i} 
            onMouseOver={() => setCurrentGif(item.value)}
            onMouseLeave={() => setCurrentGif(0)}
            sx={{
              cursor:"pointer"
            }}
            >
              <picture>
                <img
                  width="48px"
                  height="48px"
                  src={
                    item.value === currentGif ? item.gif :
                    userInterest
                      ? userInterest?.interestLevel === item?.value
                        ? item?.active
                        : item?.default
                      : item?.default
                  }
                  onClick={() => {
                    userInterest
                      ? handleUpdateInterest({ interestLevel: item.value })
                      : handleAddRating(item.value);
                  }}
                  alt="emojis"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </picture>
            </Box>
          );
        })}
      </Box>
      {/* Enable it when authentication available */}
      {/* <Box sx={{ width: "100%" }}>
        <ButtonContained id="experiences_feedback_card_login">
          {t("experiences.feedback_card.login")}
        </ButtonContained>
      </Box> */}
    </Box>
  );
};
