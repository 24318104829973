"use client";

import React from "react";
import { useRouter } from "next/navigation";

import { Typography, Box } from "@mui/material";

export const SignupFormFooterText = () => {
  const router = useRouter();
  const loginRedirect = () => {
    router.push("/login");
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        marginBottom: "16px",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
          color: "#344054",
          // marginBottom:"24px"
        }}
      >
        Already have an account?
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            color: "#E02A81",
            cursor: "pointer",
            // marginBottom:"24px"
          }}
          component="span"
          onClick={loginRedirect}
        >
          {" "}
          Log in
        </Typography>
      </Typography>
    </Box>
  );
};
