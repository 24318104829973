import React from "react";
import { useTranslation } from "i18n/client";

import { ButtonContained } from "@components/atoms";
import { Box, Typography } from "@mui/material";
interface IBookEventCard {
  onClick: () => void;
}
export const BookEventCard = ({ onClick }: IBookEventCard) => {
  const { t } = useTranslation("experiences");
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        border: "1px solid #eaecf0",
        padding: "16px 26px",
        margin: "32px 0px 22px 0px",

        display: "flex",
        boxShadow:
          "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
        borderRadius: "16px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: 20,
          fontWeight: 600,
          color: "#101828",
          marginTop: "12px",
        }}
      >
        {t("activities.book_event_card.heading")}
      </Typography>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: 18,
          fontWeight: 400,
          color: "#667085",
          marginTop: "12px",
          textAlign: {xs:"center",sm:"justify"},
          textJustify: "initial",
          textSizeAdjust: "auto",
        }}
      >
        {t("activities.book_event_card.subtitle")}
      </Typography>

      <Box
        sx={{
          width: "100%",
          marginTop: {
            md: "16px",
          },
        }}
      >
        <ButtonContained id="activities_book_event_card_btn" clickHandler={onClick}>
          {" "}
          {t("activities.book_event_card.btn")}
        </ButtonContained>
      </Box>
    </Box>
  );
};
