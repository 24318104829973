"use client";
import React, { FC } from "react";
// import { useTranslation } from "i18n/client";
import { CustomTypographyProps } from "./custom-typography.types";
import { Typography } from "@mui/material";

export const CustomTypography: FC<CustomTypographyProps> = ({
  fontsize,
  fontweight,
  fontstyle,
  lineheight,
  color,
  bg,
  border,
  padding,
  textalign,
  children,
}) => {
  // const { t } = useTranslation("common");
  return (
    <Typography
      component="h2"
      sx={{
        textAlign: textalign || "center",
        fontFamily: "Inter",
        fontStyle: fontstyle || "normal",
        fontSize: fontsize || "30px",
        fontWeight: fontweight || "600",
        lineHeight: lineheight || "38px",
        color: color || "#101828",
        padding: padding || "auto",
      }}
    >
      {/* {t(children)} */}
      {children}
    </Typography>
  );
};
