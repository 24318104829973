"use client";
// npm imports
import React, { useState, FC, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";
// import required modules
import { Pagination } from "swiper";

// MUI imports
import { Box, Container, Avatar, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

// Component imports
import { ModalSeeAllComponent } from "@components/atoms";

export const AvatarStack: FC = ({ textAlign }: any) => {
  const [optionsmodalopen, setOptionsModalOpen] = useState(false);

  const handleClickMoreOptions = () => {
    setOptionsModalOpen(true);
  };
  return (
    <>
      <Box
        className="right-layout-timer"
        style={{
          cursor: "pointer",
          textAlign: textAlign || "start",
          overflow: "auto",
          border: "1px solid #eaecf0",
        }}
      >
        <Container
          sx={{
            padding: "10px 1px",
            zIndex: 10,
            width: "100vw",
          }}
        >
          <Swiper
            slidesPerView={5}
            spaceBetween={5}
            pagination={false}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide style={{ borderRadius: "50px" }}>
              {" "}
              <Avatar
                alt="Remy Sharp"
                src="public/assets/images/common/user-active.png"
                sx={{ width: 58, height: 58, border: "2.3px solid #f62e8e" }}
                onClick={() => handleClickMoreOptions()}
              />
            </SwiperSlide>
            <SwiperSlide style={{ borderRadius: "50px" }}>
              {" "}
              <Avatar
                alt="Remy Sharp"
                src="public/assets/images/common/user-active.png"
                sx={{ width: 58, height: 58, border: "2.3px solid #f62e8e" }}
                onClick={() => handleClickMoreOptions()}
              />
            </SwiperSlide>
            <SwiperSlide style={{ borderRadius: "50px" }}>
              {" "}
              <Avatar
                alt="Remy Sharp"
                src="public/assets/images/common/user-active.png"
                sx={{ width: 58, height: 58, border: "2.3px solid #f62e8e" }}
                onClick={() => handleClickMoreOptions()}
              />
            </SwiperSlide>
            <SwiperSlide style={{ borderRadius: "50px" }}>
              {" "}
              <Avatar
                alt="Remy Sharp"
                src="public/assets/images/common/user-active.png"
                sx={{ width: 58, height: 58, border: "2.3px solid #f62e8e" }}
                onClick={() => handleClickMoreOptions()}
              />
            </SwiperSlide>
            <SwiperSlide style={{ borderRadius: "50px" }}>
              {" "}
              <Avatar
                alt="Remy Sharp"
                src="public/assets/images/common/user-active.png"
                sx={{ width: 58, height: 58, border: "2.3px solid #f62e8e" }}
                onClick={() => handleClickMoreOptions()}
              />
            </SwiperSlide>
            <SwiperSlide style={{ borderRadius: "50px" }}>
              {" "}
              <Avatar
                alt="Remy Sharp"
                src="public/assets/images/common/user-active.png"
                sx={{ width: 58, height: 58, border: "2.3px solid #f62e8e" }}
                onClick={() => handleClickMoreOptions()}
              />
            </SwiperSlide>
            <SwiperSlide style={{ borderRadius: "50px" }}>
              {" "}
              <Avatar
                alt="Remy Sharp"
                src="public/assets/images/common/user-active.png"
                sx={{ width: 58, height: 58, border: "2.3px solid #f62e8e" }}
                onClick={() => handleClickMoreOptions()}
              />
            </SwiperSlide>
            <SwiperSlide style={{ borderRadius: "50px" }}>
              {" "}
              <Avatar
                alt="Remy Sharp"
                src="public/assets/images/common/user-active.png"
                sx={{ width: 58, height: 58, border: "2.3px solid #f62e8e" }}
                onClick={() => handleClickMoreOptions()}
              />
            </SwiperSlide>
            <SwiperSlide style={{ borderRadius: "50px" }}>
              {" "}
              <Avatar
                alt="Remy Sharp"
                src="public/assets/images/common/user-active.png"
                sx={{ width: 58, height: 58, border: "2.3px solid #f62e8e" }}
                onClick={() => handleClickMoreOptions()}
              />
            </SwiperSlide>
          </Swiper>
        </Container>

        {/*Modal Definition  */}
        <ModalSeeAllComponent
          open={optionsmodalopen}
          onclose={() => setOptionsModalOpen(false)}
          title="Pending Moments"
          btnStatus="Review"
        />
      </Box>
    </>
  );
};
