"use client";

import React from "react";
import { Typography, Box } from "@mui/material";
import { useParams, useRouter } from "next/navigation";
import { useTranslation } from "i18n/client";

export const LoginFormFooterText = () => {
  const router = useRouter();
  const params = useParams()
  const signupRedirect = () => {
    router.push(`/${params.lng}/signup`);
  };
  const { t } = useTranslation("auth");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        marginBottom: "16px",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
          color: "#344054",
        }}
      >
        {t("Login.no_account")}
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            color: "#E02A81",
            cursor: "pointer",
          }}
          component="span"
          onClick={signupRedirect}
        >
          {t("Register.Name")}
        </Typography>
      </Typography>
    </Box>
  );
};
