import React, { FC } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { RecomendationCardProps } from "./recomendation-card.types";
import { CarouselDefault } from "../carousel-default";
import { useTheme } from "@mui/material";
import Link from "next/link";
import getURL from "@next/utils/getURL";
import { useResponsiveLayout } from "@hooks";

export const RecomendationCard: FC<RecomendationCardProps> = ({
  activityId,
  heading,
  subtitle,
  url,
  images,
}) => {
  const theme = useTheme();
  const { isMobile } = useResponsiveLayout();

  return (
    <Card sx={{ maxWidth: "100%" }}>
      {/* Url coming from api and it's alredy inclues locale that no need customLink component here */}
      <Link href={getURL(url)} style={{
         WebkitTapHighlightColor: 'transparent',
         touchAction: 'manipulation',
         backgroundColor: 'transparent !important',
         WebkitUserSelect: 'none',
      }}>
        <CarouselDefault
          images={
            images?.map((image) => ({
              file: image?.url ?? "",
              alt: image?.alt ?? "",
              title: image?.title ?? "",
            })) ?? []
          }
          pagination={false}
          navigation={false}
          height="280px"
          borderRadius="10px"
          disableSwiping={!isMobile}
        />
      </Link>

      <CardContent
        sx={{
          padding: "0px",
        }}
      >
        <Box
          sx={{
            marginTop: "24px",
            marginBottom: "4px",
            padding: "0px",
          }}
        >
          <Typography
            component="h3"
            variant="Text_xlNormal_underlined"
            color={theme.palette.grey[900]}
            sx={{
              cursor: "pointer",
            }}
          >
             {/* Url coming from api and it's alredy inclues locale that no need customLink component here */}
            <Link href={getURL(url)}>{heading}</Link>
          </Typography>
        </Box>
      </CardContent>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            flex: "0 0 auto",
            flexWrap: "wrap",
          }}
        >
          <Typography variant="Text_smNormal" color="#d12779">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
