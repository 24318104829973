import React from "react";

export const AccessDeniedIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 8.5v4m0 4h.01M10.29 3.36 1.82 17.5a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.36a2 2 0 0 0-3.42 0z" stroke="#DC6803" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};
