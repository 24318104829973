import React, {FC} from 'react'

export const LinkIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12z" fill="#98A2B3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.041 7.274c-.308-1.216-1.334-2.168-2.549-2.365a3.243 3.243 0 0 0-2.057.306c-.378.191-.53.328-2.169 1.968l-1.603 1.605.166-.06a2.587 2.587 0 0 1 1.747.017l.216.078 1.01-1.005a41.746 41.746 0 0 1 1.116-1.086c.365-.28.905-.397 1.367-.296.628.139 1.14.648 1.28 1.274.056.251.04.64-.036.877-.12.37-.124.376-1.99 2.25-1.232 1.237-1.797 1.787-1.921 1.87a2.121 2.121 0 0 1-.395.194c-.198.07-.25.077-.525.077-.268 0-.332-.01-.518-.073-.27-.092-.453-.21-.743-.481-.261-.245-.392-.306-.651-.304-.491.004-.855.5-.716.978a.908.908 0 0 0 .162.289c.16.197.557.53.82.686a3.246 3.246 0 0 0 3.516-.15c.13-.092.827-.774 2.066-2.018 1.678-1.685 1.886-1.902 2.008-2.101.48-.783.62-1.662.4-2.53zm-5.455 3.155a3.224 3.224 0 0 0-1.792-.911c-.797-.129-1.58.047-2.306.515-.269.174-3.854 3.755-4.06 4.056a3.072 3.072 0 0 0-.57 1.822c-.002.54.087.939.312 1.407.218.452.464.77.831 1.075 1.087.9 2.583.999 3.8.252.198-.121.402-.316 1.878-1.785l1.656-1.649-.187.065a2.565 2.565 0 0 1-1.73-.02l-.215-.078-1.01 1.005a41.212 41.212 0 0 1-1.116 1.086 1.99 1.99 0 0 1-.66.297c-1.214.257-2.281-.858-1.973-2.062.02-.076.034-.137.054-.196.1-.283.339-.518 1.945-2.131 1.249-1.254 1.81-1.8 1.937-1.886.1-.067.275-.153.395-.194.19-.065.252-.074.522-.074.269 0 .331.009.518.073.27.092.453.21.743.481.26.245.392.306.651.304a.775.775 0 0 0 .669-.427c.056-.108.067-.162.068-.333 0-.28-.059-.394-.36-.692z" fill="#fff"/>
</svg>

  )
}
